import { adminInstance, instance } from "../api";

export interface ICalendarDataInfo {
  tour: string;
  communityNum: string | null;
  communityDistrict: string;
  place: string;
  streetNum: string;
  streetDistrict: string;
  homeNum1?: string;
  homeNum2?: string;
  color: string;
  containerType: string | null;
  rhythm?: string | null;
  date: string;
}

export interface ICalendarData extends ICalendarDataInfo {
  id: string;
}

const getStreets = () => {
  return instance
    .get<{ streets: string[] }>("/calendar/streets")
    .then((res) => res.data);
};

const getTownAreaStreets = () => {
  return instance
    .get<{ strasseBezeichnung: string; strasseNummer: number }[]>(
      "/calendar/townarea-streets"
    )
    .then((res) => res.data);
};

const calendarData = (
  date: string,
  streetNum: number,
  isTreeMonthPeriod?: boolean,
  isYear?: boolean,
  homeNumber?: string
) => {
  return instance
    .get(
      `/calendar`,
      {
        params: {
          startMonth: date,
          streetNum,
          isTreeMonthRange: isTreeMonthPeriod,
          isYear,
          homeNumber
        },
      }
    )
    .then((res) => res.data);
};

const getCalendarData = (page: number) => {
  return adminInstance
    .get<ICalendarData[]>(`/calendar-data?page=${page}`)
    .then((res) => res.data);
};

const updateCalendarDataById = (id: string, data: ICalendarDataInfo) => {
  return adminInstance
    .put<ICalendarData>(`/calendar-data/${id}`, data)
    .then((res) => res.data);
};

const getCalendarDataById = (id: string) => {
  return adminInstance
    .get<ICalendarData>(`/calendar-data/${id}`)
    .then((res) => res.data);
};

const Calendar = {
  get: {
    getStreets,
    calendarData,
    getTownAreaStreets,
    getCalendarData,
    getCalendarDataById,
  },
  put: {
    updateCalendarDataById,
  },
};

export default Calendar;
