import { Form, Formik } from "formik";
import React, { FC } from "react";
import {
  ICalendarData,
  ICalendarDataInfo,
} from "../../../../../../api/calendar/calendar.api";
import { Button, CustomInput } from "../../../../../../components";
import { useAppDispatch } from "../../../../../../store";
import { updateCalendarDataById } from "../../../../../../store/slices/calendar";
import appConfig from "../../../../../../util/appConfig";
import style from "./style.module.scss";

interface IUpdateItemForm {
  item: ICalendarData;
  closeModalHandler: () => void;
}

const UpdateItemForm: FC<IUpdateItemForm> = ({ item, closeModalHandler }) => {
  const dispatch = useAppDispatch();

  const initialValues: ICalendarDataInfo = {
    color: item.color,
    communityDistrict: item.communityDistrict,
    communityNum: item.communityNum,
    containerType: item.containerType,
    date: item.date,
    place: item.place,
    streetDistrict: item.streetDistrict,
    streetNum: item.streetNum,
    tour: item.tour,
    homeNum1: item.homeNum1,
    homeNum2: item.homeNum2,
    rhythm: item.rhythm,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        dispatch(
          updateCalendarDataById({
            id: item.id,
            data: values,
          })
        );
        resetForm();
        closeModalHandler();
      }}
    >
      <Form>
        <div className={style.formInputWrapper}>
          <CustomInput
            hasFullWidth={true}
            label="Color"
            name="color"
            type="text"
            placeholder="Color"
          />
          <CustomInput
            hasFullWidth={true}
            label="Community district"
            name="communityDistrict"
            type="text"
            placeholder="Community district"
          />
          <CustomInput
            hasFullWidth={true}
            label="Community number"
            name="communityNum"
            type="text"
            placeholder="Community number"
          />
          <CustomInput
            hasFullWidth={true}
            label="Container type"
            name="containerType"
            type="text"
            placeholder="Container type"
          />
          <CustomInput
            hasFullWidth={true}
            label="Date"
            name="date"
            type="text"
            placeholder="Date"
          />
          <CustomInput
            hasFullWidth={true}
            label="Place"
            name="place"
            type="text"
            placeholder="Place"
          />
          <CustomInput
            hasFullWidth={true}
            label="Street district"
            name="streetDistrict"
            type="text"
            placeholder="Street district"
          />
          <CustomInput
            hasFullWidth={true}
            label="Street number"
            name="streetNum"
            type="text"
            placeholder="Street number"
          />
          <CustomInput
            hasFullWidth={true}
            label="Tour"
            name="tour"
            type="text"
            placeholder="Tour"
          />
          <CustomInput
            hasFullWidth={true}
            label="Home number 1"
            name="homeNum1"
            type="text"
            placeholder="Home number 1"
          />
          <CustomInput
            hasFullWidth={true}
            label="Home number 2"
            name="homeNum2"
            type="text"
            placeholder="Home number 2"
          />
          <CustomInput
            hasFullWidth={true}
            label="Rhythm"
            name="rhythm"
            type="text"
            placeholder="Rhythm"
            marginBottom="20"
          />
        </div>
        <Button
          backgroundColor={appConfig.mainColor}
          text="Submit"
          width="250"
          type="submit"
        />
      </Form>
    </Formik>
  );
};

export default UpdateItemForm;
