import { ISepaBody } from "../../store/slices/sepa";
import { instance } from "../api";

const create = (data: ISepaBody) => {
  return instance.post("/sepa", data).then((res) => res.data);
};

const Sepa = {
  post: {
    create,
  },
};

export default Sepa;
