import React, { FC, useLayoutEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import HeaderTitle from "./components/HeaderTitle";
import Item from "./components/Item";
import SearchInput from "./components/SearchInput";

interface IContentComponent {
  areAllItemsSelected: boolean;
  selectAllItemsHandler: () => void;
  selectedItems: number[];
  selectItemHandler: (id: number) => void;
}

const ContentComponent: FC<IContentComponent> = ({
  areAllItemsSelected,
  selectAllItemsHandler,
  selectedItems,
  selectItemHandler,
}) => {
  const [value, setValue] = useState("");
  const ignoredStreets = useAppSelector((state) => state.admin.ignoredStreets);
  const [streetsToShow, setStreetsToShow] = useState(ignoredStreets);

  const changeHandler = (val: string) => {
    setValue(val);
  };

  useLayoutEffect(() => {
    if (value.length) {
      setStreetsToShow(
        ignoredStreets.filter((i) => i.strasseBezeichnung.toLowerCase().includes(value))
      );
    }
    if (!value.length) {
      setStreetsToShow(ignoredStreets);
    }
  }, [value, ignoredStreets]);

  return (
    <div>
      <SearchInput value={value} changeHandler={changeHandler} />
      <div>
        <HeaderTitle
          changeHandler={selectAllItemsHandler}
          checked={areAllItemsSelected}
        />
        {streetsToShow.map((street) => {
          return (
            <Item
              item={street}
              key={street.strasseNummer}
              checked={selectedItems.includes(street.strasseNummer)}
              changeHandler={selectItemHandler}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ContentComponent;
