import React, { FC } from "react";
import { AdminCheckbox } from "../../../../../../components";
import style from "./style.module.scss";

interface IHeaderTitle {
  checked: boolean;
  changeHandler: () => void;
}

const HeaderTitle: FC<IHeaderTitle> = ({ changeHandler, checked }) => {
  return (
    <div className={style.wrapper}>
      <AdminCheckbox changeHandler={changeHandler} checked={checked} />
      <div
        style={{
          display: "flex",
        }}
      >
        <div className={style.nameTextWrapper}>Name</div>
      </div>
    </div>
  );
};

export default HeaderTitle;
