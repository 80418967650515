import React, { FC } from "react";
import { Link } from "react-router-dom";
import blueArrow from "../../../../../../assets/images/blueArrow.svg";
import style from "./style.module.scss";

interface INews {
  isLastElement: boolean;
  src: string;
  title: string;
  date: string;
  description: string;
  id: string;
}

const News: FC<INews> = ({
  date,
  description,
  id,
  isLastElement,
  src,
  title,
}) => {
  return (
    <div>
      <div className={style.wrapper}>
        <div className={style.img} style={{
          backgroundImage: `url(${src})`,
        }}>
          {/* <img src={src} alt={`img-${id}`} className={style.img} /> */}
        </div>
        <div>
          <div className={style.title}>{title}</div>
          <div className={style.dateText}>{date}</div>
          <div className={style.description}>{description}</div>
          <Link to={id} className={style.readMoreWrapper}>
            <div className={style.readMoreText}>Mehr lessen</div>
            <img src={blueArrow} alt="read" />
          </Link>
        </div>
      </div>
      {!isLastElement ? <div className={style.divider}></div> : null}
    </div>
  );
};

export default News;
