const toRadiant = (degree: number) => {
  return (degree * Math.PI) / 180;
};

const calculateDistance = (origin: number[], destination: number[]) => {
  const lon1 = toRadiant(origin[1]);
  const lat1 = toRadiant(origin[0]);
  const lon2 = toRadiant(destination[1]);
  const lat2 = toRadiant(destination[0]);

  const deltaLat = lat2 - lat1;
  const deltaLon = lon2 - lon1;

  const a =
    Math.pow(Math.sin(deltaLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
  const c = 2 * Math.asin(Math.sqrt(a));
  const EARTH_RADIUS = 6371;
  return c * EARTH_RADIUS;
};

export default calculateDistance;
