import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, CustomInput } from "../../components";
import { useAppDispatch } from "../../store";
import { loginAdmin } from "../../store/slices/admin";
import { setIsToasterError } from "../../store/slices/app";
import appConfig from "../../util/appConfig";
import sensisLogo from "../../assets/images/logos/sensisLogo.svg";
import style from "./style.module.scss";

interface IAdminLoginForm {
  email: string;
  password: string;
}

const AdminLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formikInitialValues: IAdminLoginForm = {
    email: "",
    password: "",
  };

  return (
    <div className={style.wrapper}>
      <div className={style.formWrapper}>
        <img src={sensisLogo} alt="logo" className={style.logo} />
        <Formik
          initialValues={formikInitialValues}
          onSubmit={async (values, { resetForm }) => {
            try {
              dispatch(
                loginAdmin({
                  email: values.email,
                  password: values.password,
                  navigate,
                })
              );
              resetForm();
            } catch (error) {
              dispatch(setIsToasterError(true));
            }
          }}
        >
          {(props) => {
            return (
              <Form className={style.form}>
                <CustomInput
                  label="Email"
                  name="email"
                  type="text"
                  hasFullWidth={true}
                  placeholder="Max.Musterman@muster.de"
                />
                <CustomInput
                  label="Password"
                  name="password"
                  type="password"
                  hasFullWidth={true}
                  placeholder="Enter password"
                  marginBottom="30"
                />
                <Button
                  backgroundColor={appConfig.mainColor}
                  text="Log in"
                  type="submit"
                  width=""
                  isWidthInPercentage={true}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogin;
