import { adminInstance } from "../api";

const createToken = (tokenName: string) => {
  return adminInstance
    .post<{ token: string; item: { id: string; name: string; email: string } }>(
      "admin/token",
      { tokenName }
    )
    .then((res) => res.data);
};

const deleteToken = (ids: string[]) => {
  return adminInstance
    .delete(`/admin/token?ids=${ids}`)
    .then((res) => res.data);
};

const getTokens = (
  sort?: string,
  direction?: "asc" | "desc",
  searchValue?: string | undefined
) => {
  let query = "?";
  if (direction) {
    query = `${query}sort=${sort}&direction=${direction}`;
  }
  if (searchValue?.length) {
    query = `${query}&searchValue=${searchValue}`;
  }
  return adminInstance
    .get<{ id: string; name: string; email: string }[]>(`admin/token${query}`)
    .then((res) => res.data);
};

const Token = {
  get: {
    getTokens,
  },
  post: {
    createToken,
  },
  delete: {
    deleteToken,
  },
};

export default Token;
