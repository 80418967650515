import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import { setAdminLoader } from "./admin";
import { setIsToasterError } from "./app";

interface ITokenState {
  admin: {
    adminToken: string;
    tokens: { id: string; name: string; email: string }[];
    isTokenDataLoading: boolean;
  };
}

const initialState: ITokenState = {
  admin: {
    adminToken: "",
    tokens: [],
    isTokenDataLoading: false,
  },
};

export const getAdminTokens = createAsyncThunk(
  "token/admin/getTokens",
  async (
    obj: { sort?: string; direction?: "asc" | "desc" | undefined, searchValue?: string | undefined },
    thunkApi
  ) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));

      const tokens = await API.token.get.getTokens(obj.sort, obj.direction, obj.searchValue);
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      return tokens;
    } catch (error) {
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);
export const getAdminTokensSearch = createAsyncThunk(
  "token/admin/getAdminTokensSearch",
  async (
    obj: { sort?: string; direction?: "asc" | "desc" | undefined, searchValue?: string | undefined },
    thunkApi
  ) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));

      const tokens = await API.token.get.getTokens(obj.sort, obj.direction, obj.searchValue);
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      return tokens;
    } catch (error) {
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const deleteAdminToken = createAsyncThunk(
  "token/admin/deleteToken",
  async (ids: string[], thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      await API.token.delete.deleteToken(ids);
      thunkApi.dispatch(setAdminLoader(false));
      return ids;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const createToken = createAsyncThunk(
  "token/admin/createToken",
  async (name: string, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const item = await API.token.post.createToken(name);
      thunkApi.dispatch(setAdminLoader(false));
      return item;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    clearTokens: (state) => {
      state.admin.tokens = [];
    },
    clearAdminToken: (state) => {
      state.admin.adminToken = "";
    },
    setIsTokenDataLoading: (state, action: PayloadAction<boolean>) => {
      state.admin.isTokenDataLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminTokens.pending, (state) => {
      state.admin.isTokenDataLoading = true;
    })
    builder.addCase(getAdminTokens.fulfilled, (state, actions) => {
      if (actions.payload) {
        state.admin.tokens = [
          ...actions.payload.map((i) => {
            if (!i.hasOwnProperty("email")) {
              i.email = "";
            }
            return { id: i.id, name: i.name, email: i.email ?? "" };
          }),
        ];
      }
      state.admin.isTokenDataLoading = false;
    });
    // builder.addCase(getAdminTokens.pending, (state) => {
    //   state.admin.isTokenDataLoading = true;
    // })
    builder.addCase(getAdminTokensSearch.fulfilled, (state, actions) => {
      if (actions.payload) {
        state.admin.tokens = [
          ...actions.payload.map((i) => {
            if (!i.hasOwnProperty("email")) {
              i.email = "";
            }
            return { id: i.id, name: i.name, email: i.email ?? "" };
          }),
        ];
      }
      // state.admin.isTokenDataLoading = false;
    });
    // builder.addCase(getAdminTokens.rejected, (state) => {
    //   state.admin.isTokenDataLoading = false;
    // })
    builder.addCase(deleteAdminToken.fulfilled, (state, action) => {
      state.admin.tokens = [
        ...state.admin.tokens.filter(
          (item) => !action.payload?.includes(item.id) && item
        ),
      ];
    });

    builder.addCase(createToken.fulfilled, (state, action) => {
      state.admin.adminToken = action.payload?.token!;
      state.admin.tokens = [...state.admin.tokens, action.payload?.item!];
    });
  },
});

export const { clearAdminToken, clearTokens } = tokenSlice.actions;

export const tokenReducer = tokenSlice.reducer;
