import React, { FC } from "react";
import redRemoveIcon from "../../../../../../../../assets/images/redRemoveIcon.svg";
import adminSettingsNext from "../../../../../../../../assets/images/adminSettingsNext.svg";
import adminSettingsPrev from "../../../../../../../../assets/images/adminSettingsPrev.svg";
import style from "./style.module.scss";
import { format, getDate, getMonth, getYear } from "date-fns";
import { de } from "date-fns/locale";
import getCurrentCalendarMonthDaysToShow from "../../../../../../../../util/getCurrentCalendarMonthDaysToShow";
import { IDisplayedMonth } from "../../../../../../../Calendar/components/Calendar/components/CalendarComponent";

interface ICalendar {
  date: string;
  prevMonthHandler: () => void;
  nextMonthHandler: () => void;
  setFieldValue: (field: string, value: any) => void;
  holidays: string[];
}

const daysOfWeek = [
  { title: "MO" },
  { title: "DI" },
  { title: "MI" },
  { title: "DO" },
  { title: "FR" },
  { title: "SA" },
  { title: "SO" },
];

const Calendar: FC<ICalendar> = ({
  date,
  holidays,
  nextMonthHandler,
  prevMonthHandler,
  setFieldValue,
}) => {
  const currentDate = getDate(new Date());
  const currentMont = new Date().getMonth();
  const selectedMonth = new Date(date).getMonth();

  let calendarData: IDisplayedMonth[][] = [];

  for (let i = 0; i < getCurrentCalendarMonthDaysToShow(date).length / 7; i++) {
    calendarData.push([
      ...getCurrentCalendarMonthDaysToShow(date).slice(i * 7, (i + 1) * 7),
    ]);
  }

  return (
    <div className={style.wrapper}>
      <label className={style.label}>Datum auswählen</label>
      <div className={style.calendarItemWrapper}>
        <div className={style.calendarWrapper}>
          <div className={style.monthNameWrapper}>
            <img
              src={adminSettingsPrev}
              alt="prev"
              className={style.arrowImg}
              onClick={prevMonthHandler}
            />
            <div className={style.monthNameText}>
              {format(new Date(date), "MMMM yyyy", { locale: de })}
            </div>
            <img
              src={adminSettingsNext}
              alt="next"
              className={style.arrowImg}
              onClick={nextMonthHandler}
            />
          </div>
          <div>
            <div className={style.dayOfWeeksWrapper}>
              {daysOfWeek.map((i) => {
                return (
                  <div key={i.title} className={style.dayOfWeekText}>
                    {i.title}
                  </div>
                );
              })}
            </div>
            <div>
              {calendarData.map((week, id) => {
                return (
                  <div
                    key={id * Math.floor(Math.random() * 3334112)}
                    className={style.calendarWeekWrapper}
                  >
                    {week.map((day, idx) => {
                      return (
                        <div
                          key={idx}
                          className={`${style.day} ${
                            week.length === id + 1 && idx === 0
                              ? style.cornerDay
                              : ""
                          } ${
                            week.length === idx + 1 ? style.lastWeekDay : ""
                          } ${
                            calendarData.length === id + 1 ? style.lastWeekDays : ""
                          } 
                          ${
                            day.month === "prev" || day.month === "next"
                              ? style.inActiveDay
                              : ""
                          } ${
                            day.month === "current" &&
                            currentDate === +day.day &&
                            currentMont === selectedMonth
                              ? style.currentDay
                              : ""
                          } ${
                            ((idx + 1) % 6 === 0 || (idx + 1) % 7 === 0) &&
                            day.month === "current"
                              ? style.isWeekend
                              : ""
                          } ${
                            day.month === "current" &&
                            holidays.filter(
                              (i) =>
                                format(new Date(i), "dd. MMMM yyyy", {
                                  locale: de,
                                }) ===
                                format(
                                  new Date(
                                    getYear(new Date(date)),
                                    getMonth(new Date(date)),
                                    +day.day
                                  ),
                                  "dd. MMMM yyyy",
                                  {
                                    locale: de,
                                  }
                                )
                            ).length
                              ? style.selectedDate
                              : ""
                          } ${id === 0 ? style.firstWeekDays : ''} ${calendarData.length === id+1 && idx === 0 ? style.firstDayOfLastWeek : ''}`}
                          onClick={() => {
                            if (
                              day.month === "prev" ||
                              day.month === "next" ||
                              (idx + 1) % 6 === 0 ||
                              (idx + 1) % 7 === 0
                            ) {
                              return;
                            }
                            if (
                              day.month === "current" &&
                              holidays.filter(
                                (i) =>
                                  format(new Date(i), "dd. MMMM yyyy", {
                                    locale: de,
                                  }) ===
                                  format(
                                    new Date(
                                      getYear(new Date(date)),
                                      getMonth(new Date(date)),
                                      +day.day
                                    ),
                                    "dd. MMMM yyyy",
                                    {
                                      locale: de,
                                    }
                                  )
                              ).length
                            ) {
                              const idx = holidays
                                .map((i) =>
                                  format(new Date(i), "dd. MMMM yyyy", {
                                    locale: de,
                                  })
                                )
                                .indexOf(
                                  format(
                                    new Date(
                                      getYear(new Date(date)),
                                      getMonth(new Date(date)),
                                      +day.day
                                    ),
                                    "dd. MMMM yyyy",
                                    {
                                      locale: de,
                                    }
                                  )
                                );
                              let cp = [...holidays];
                              cp.splice(idx, 1);
                              setFieldValue("holidays", cp);
                              return;
                            }
                            let copy = [...holidays];
                            copy = [
                              ...copy,
                              new Date(
                                getYear(new Date(date)),
                                getMonth(new Date(date)),
                                +day.day,
                                13,
                                0,
                                0,
                                0
                              ).toISOString(),
                            ].sort((a, b) => {
                              return (
                                (new Date(a) as any) - (new Date(b) as any)
                              );
                            });
                            setFieldValue("holidays", copy);
                          }}
                          style={{
                            cursor:
                              day.month === "prev" ||
                              day.month === "next" ||
                              (idx + 1) % 6 === 0 ||
                              (idx + 1) % 7 === 0
                                ? ""
                                : "pointer",
                          }}
                        >
                          {day.day}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={style.selectedDatesWrapper}>
          <div className={style.selectDateText}>Ausgewählte Tage</div>
          <div className={style.datesWrapper}>
            {holidays.map((i, idx) => {
              return (
                <div
                  className={style.selectedDateWrapper}
                  key={idx}
                  onClick={() => {
                    let copy = [...holidays];
                    copy = copy.filter((el) => el !== i);
                    setFieldValue("holidays", [...copy]);
                  }}
                >
                  {format(new Date(i), "dd. MMMM yyyy", {
                    locale: de,
                  })}{" "}
                  <img
                    src={redRemoveIcon}
                    alt="remove"
                    className={style.removeIcon}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
