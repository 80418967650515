import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { SortDescriptor } from "@progress/kendo-data-query";
import { getMails } from "../../store/slices/mailTracking";
import { format } from "date-fns";
import { de } from "date-fns/locale";

const AdminMailTracking = () => {
  const dispatch = useAppDispatch();
  const abortControllerLine: any = useRef();
  const { mails, totalCount } = useAppSelector(
    (state) => state.mailTracking.admin
  );
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  useEffect(() => {
    if (abortControllerLine.current) {
      abortControllerLine.current.abort();
    }
    abortControllerLine.current = new AbortController();
    const signal = abortControllerLine.current.signal;
    dispatch(
      getMails({
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        direction: sort[0]?.dir,
        signal,
      })
    );
  }, [page, pageSize, value, sort]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader pageTitle={"E-Mail Tracking"} hasBtnGroup={false} />
      <AdminContentWrapper>
        {false ? null : (
          <TableGrid
            data={mails.map((el) => {
              return {
                id: el.id,
                email: el.email,
                type: el.type,
                date: format(new Date(el.date), "dd MMM yyyy HH:mm:ss", { locale: de }),
              };
            })}
            sort={sort}
            sortable={true}
            onSortChange={setSort}
            items={[
              {
                field: "email",
                title: "EMail",
              },
              {
                field: "type",
                title: "Type",
              },
              {
                field: "date",
                title: "Datum",
              },
            ]}
            searchInputValue={value}
            onSearchInputValueChange={changeHandler}
            withPager={true}
            page={page}
            pageSize={pageSize}
            pageChangeHandler={pageChangeHandler}
            pageSizeHandler={pageSizeHandler}
            itemsCount={totalCount}
          />
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminMailTracking;
