import { format } from "date-fns";
import React from "react";
import News from "./components/News";
import { useAppSelector } from "../../../../store";
import style from "./style.module.scss";

const AllNews = () => {
  const news = useAppSelector((state) => state.news.client.news);
  return (
    <div className={style.wrapper}>
      {news.map((i, idx) => {
        return (
          <News
            key={i._id}
            date={format(new Date(i.date), "dd.MM.yyyy")}
            description={i.shortDescription}
            id={i._id}
            isLastElement={idx + 1 === news.length}
            src={i.imgSrc}
            title={i.title}
          />
        );
      })}
    </div>
  );
};

export default AllNews;
