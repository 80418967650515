import React, { FC } from "react";
import style from "./style.module.scss";

interface IAdminDetailsPageItemName {
  text: string;
}

export const AdminDetailsPageItemName: FC<IAdminDetailsPageItemName> = ({
  text,
}) => {
  return <div className={style.detailName}>{text}</div>;
};
