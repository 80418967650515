import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Button, InputWithSelect } from "../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { setWhiteListOfStreets } from "../../../../../../store/slices/admin";
import appConfig from "../../../../../../util/appConfig";
import transformHexToRgba from "../../../../../../util/transformHexToRgba";
import Row from "./components/Row";
import style from "./style.module.scss";

interface IBlockLisForm {
  closeModalHandler: () => void;
}

export interface IInitValues {
  strasseBezeichnung: string;
  homeNumbers: { from: string; to: string; id: string }[];
}

const BlockLisForm: FC<IBlockLisForm> = ({ closeModalHandler }) => {
  const dispatch = useAppDispatch();

  const { activeWhiteListOfStreets, ignoredWhiteListOfStreets } =
    useAppSelector((state) => state.admin);
  const [homeNumbers, setHomeNumbers] = useState<
    { from: string; to: string; id: string }[]
  >([]);
  const [selectedStreet, setSelectedStreet] = useState("");

  const initialValues: IInitValues = {
    strasseBezeichnung: selectedStreet,
    homeNumbers: homeNumbers,
  };

  const addRow = () => {
    const arr = [
      ...homeNumbers,
      { from: "", to: "", id: Math.floor(Math.random() * 10000000).toString() },
    ];
    setHomeNumbers(arr);
  };

  const deleteRowHandler = (id: string) => {
    const arr = homeNumbers.filter((i) => i.id !== id);

    setHomeNumbers(arr);
  };

  useEffect(() => {
    const d = ignoredWhiteListOfStreets.find(
      (str) => str.strasseBezeichnung === selectedStreet
    );
    if (d) {
      setHomeNumbers(
        d.whiteListHomeNumbers.map((el) => {
          return {
            from: el.from,
            to: el.to,
            id: el._id,
          };
        })
      );
    }
  }, [selectedStreet]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        const id = activeWhiteListOfStreets
          .find((i) => i.strasseBezeichnung === values.strasseBezeichnung)
          ?.strasseNummer.toString()!;
        dispatch(
          setWhiteListOfStreets({
            id,
            ignore: values.homeNumbers.length ? "partially" : "ignore",
            homeNumbers: [
              ...values.homeNumbers.map((i) => {
                return { from: i.from, to: i.to };
              }),
            ],
          })
        );
        resetForm();
        closeModalHandler();
      }}
    >
      {(props) => {
        return (
          <Form>
            <InputWithSelect
              type="string"
              name="strasseBezeichnung"
              options={activeWhiteListOfStreets.map((i) => {
                return {
                  value: i.strasseBezeichnung,
                  label: i.strasseBezeichnung,
                };
              })}
              hasFullWidth
              arrowTopLocation={"50%"}
              changeHandler={(str: string) => {
                setSelectedStreet(str);
              }}
            />
            {homeNumbers.length ? (
              <div>
                {homeNumbers.map((i, idx) => {
                  return (
                    <Row
                      deleteHandler={() => {
                        deleteRowHandler(i.id.toString());
                        props.values.homeNumbers.splice(idx, 1);
                        props.setFieldValue(
                          "homeNumbers",
                          props.values.homeNumbers
                        );
                      }}
                      key={i.id}
                      idx={idx}
                      setter={(data: any) => {

                      }}
                    />
                  );
                })}
              </div>
            ) : null}
            {props.values.strasseBezeichnung ? (
              <div className={style.addHouseNumber} onClick={addRow}>
                + Add house numbers to white list
              </div>
            ) : null}
            <div className={style.btnWrapper}>
              <Button
                text="Schließen"
                width="298"
                onClick={closeModalHandler}
                isOutlined={true}
                outlineColor={appConfig.mainColor}
                textColor={appConfig.mainColor}
              />
              <Button
                width="298"
                text="Speichern"
                backgroundColor={
                  props.values.strasseBezeichnung.length
                    ? appConfig.mainColor
                    : transformHexToRgba(appConfig.mainColor!, "0.4")
                }
                type="submit"
                disabled={!props.values.strasseBezeichnung.length}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BlockLisForm;
