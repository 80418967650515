import React, { FC } from "react";
import {
  AdminDetailsPageItemName,
  CustomDatePicker,
  CustomInput,
  InputWithSelect,
} from "../../../../components";
import { ICalendarDataInfo } from "../../../../api/calendar/calendar.api";
import { Form } from "formik";
import appConfig from "../../../../util/appConfig";
import style from "./style.module.scss";

interface IContentComponent {
  values: ICalendarDataInfo;
}

const ContentComponent: FC<IContentComponent> = ({ values }) => {
  return (
    <div>
      <AdminDetailsPageItemName text="Detail Name" />
      <Form>
        <div>
          <div className={style.wrapper}>
            <div className={style.title}>Main Information</div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <InputWithSelect
                name="color"
                options={
                  appConfig.logo === "awl"
                    ? [
                        {
                          value: "Braun",
                          label: "Braun",
                        },
                        {
                          value: "Gelb",
                          label: "Gelb",
                        },
                        {
                          value: "Blau",
                          label: "Blau",
                        },
                        {
                          value: "Grau",
                          label: "Grau",
                        },
                        {
                          value: "Pink",
                          label: "Pink",
                        },
                      ]
                    : [
                        {
                          value: "Braun",
                          label: "Braun",
                        },
                        {
                          value: "Gelb",
                          label: "Gelb",
                        },
                        {
                          value: "Blau",
                          label: "Blau",
                        },
                        {
                          value: "Grau",
                          label: "Grau",
                        },
                      ]
                }
                type="string"
                label="Color"
                width="47%"
              />
              <InputWithSelect
                name="containerType"
                options={[]}
                type="string"
                label="Container type"
                width="47%"
              />
              <CustomDatePicker label="Date" name="date" type="Date" width="47%"/>
              {/* <InputWithSelect
                name="containerType"
                options={[]}
                type="string"
                label="Container type"
                width="47%"
              /> */}
            </div>
          </div>
          <div>
            <div className={style.title}>Address details</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <CustomInput
                label="Community District"
                name="communityDistrict"
                type="string"
                width="47%"
              />
              <CustomInput
                label="Community Number"
                name="communityNum"
                type="string"
                width="47%"
              />
              <CustomInput
                label="Place"
                name="place"
                type="string"
                width="47%"
              />
              <CustomInput
                label="Street District"
                name="streetDistrict"
                type="string"
                width="47%"
              />
              <CustomInput
                label="Street Number"
                name="streetNum"
                type="string"
                width="47%"
              />
              <CustomInput label="Tour" name="tour" type="string" width="47%" />
              <CustomInput
                label="Home Number 1"
                name="homeNum1"
                type="string"
                width="47%"
              />
              <CustomInput
                label="Home Number 2"
                name="homeNum2"
                type="string"
                width="47%"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ContentComponent;
