import React, { FC } from "react";
import addIcon from "../../assets/images/addIcon.svg";
import noAvailableTokenIcon from "../../assets/images/noAvailableTokenIcon.svg";
import { useAppDispatch } from "../../store";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import style from "./style.module.scss";

interface IAdminPlaceholder {
  title: string;
  subTitle: string;
  hasBtn?: boolean;
  btnText?: string;
  btnHandler?: () => void;
}

export const AdminPlaceholder: FC<IAdminPlaceholder> = ({
  title,
  subTitle,
  hasBtn = false,
  btnText,
  btnHandler,
}) => {
  const dispatch = useAppDispatch();

  const openModalHandler = () => {
    if (btnHandler) {
      return btnHandler();
    }
    dispatch(setIsConfirmationPopupActive(true));
  };

  return (
    <div className={style.noTokenContentWrapper}>
      <img
        src={noAvailableTokenIcon}
        alt="noAvailableTokenIcon"
        className={style.imgWrapper}
      />
      <div className={style.noTokenTitle}>{title}</div>
      <div className={style.noTokenSubtitle}>{subTitle}</div>
      {hasBtn ? (
        <button
          className={`${style.addApiKeyBtn} ${style.noTokenBtn}`}
          onClick={openModalHandler}
        >
          <img src={addIcon} alt="addIcon" className={style.icon} /> {btnText}
        </button>
      ) : null}
    </div>
  );
};
