import { IArticleBody, ICreateArticleBody } from "./../../api/news/news.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

import { setIsLoading, setIsToasterError } from "./app";
import { setAdminLoader } from "./admin";

export interface INewsState {
  client: {
    count: number;
    freshNews: IArticleBody[];
    news: IArticleBody[];
    page: number;
    article: IArticleBody | null;
  };
  admin: {
    articles: IArticleBody[];
  };
}

const initialState: INewsState = {
  client: { count: 0, freshNews: [], news: [], page: 0, article: null },
  admin: {
    articles: [],
  },
};

export const getNewsData = createAsyncThunk(
  "news/client/getNewsData",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const data = await API.news.get.getNewsData();

      thunkAPI.dispatch(setIsLoading(false));
      return data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const getNews = createAsyncThunk(
  "news/client/getNews",
  async (page: number, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const data = await API.news.get.getNews(page);

      thunkAPI.dispatch(setIsLoading(false));
      return data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const getArticleById = createAsyncThunk(
  "news/client/getArticleById",
  async (id: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const article = await API.news.get.getArticleById(id);
      thunkAPI.dispatch(setIsLoading(false));
      return article;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(true));
    }
  }
);

export const getArticles = createAsyncThunk(
  "news/admin/getArticles",
  async (page: number, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const articles = await API.news.get.getNews(page);
      thunkApi.dispatch(setAdminLoader(false));
      return articles;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const deleteArticles = createAsyncThunk(
  "news/admin/deleteArticles",
  async (ids: string[], thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      await API.news.delete.deleteArticles(ids);
      thunkApi.dispatch(setAdminLoader(false));
      return ids;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const createArticle = createAsyncThunk(
  "news/admin/createArticle",
  async (body: ICreateArticleBody, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const article = await API.news.post.createArticle(body);
      thunkApi.dispatch(setAdminLoader(false));
      return article;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const updateArticle = createAsyncThunk(
  "news/admin/updateArticle",
  async (obj: { id: string; body: ICreateArticleBody }, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const article = await API.news.put.updateArticle(obj.id, obj.body);
      thunkApi.dispatch(setAdminLoader(false));
      return article;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    clearArticle: (state) => {
      state.client.article = null;
    },
    clearNewsData: (state) => {
      state.client.count = 0;
      state.client.freshNews = [];
      state.client.news = [];
      state.client.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsData.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.count = action.payload.count;
        const freshNews = action.payload.news.slice(0, 3).filter((i) => i);
        const rest = action.payload.news.slice(3).filter((i) => i);
        state.client.freshNews = [...freshNews];
        state.client.news = [...rest];
        state.client.page = state.client.page + 1;
      }
    });
    builder.addCase(getNews.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.news = [...state.client.news, ...action.payload.news];
        state.client.page = state.client.page + 1;
      }
    });
    builder.addCase(getArticleById.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.article = action.payload;
      }
    });
    builder.addCase(getArticles.fulfilled, (state, action) => {
      if (action.payload?.news.length) {
        state.admin.articles = [...action.payload.news];
      }
    });
    builder.addCase(deleteArticles.fulfilled, (state, action) => {
      state.admin.articles = [
        ...state.admin.articles.filter(
          (item) => !action.payload?.includes(item._id) && item
        ),
      ];
    });
    builder.addCase(createArticle.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.articles = [action.payload, ...state.admin.articles];
      }
    });
    builder.addCase(updateArticle.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.articles = [
          ...state.admin.articles.map((i) =>
            i._id === action.payload?._id ? action.payload : i
          ),
        ];
      }
    });
  },
});

export const { clearArticle, clearNewsData } = newsSlice.actions;

export const newsReducer = newsSlice.reducer;
