import React from "react";
import style from "./style.module.scss";
import registerBtn from "../../assets/images/registerBtn.svg";

export const Register = () => {
  return (
    <div className={style.reg_wrap}>
      <img className={style.img} src={registerBtn} alt='registerBtn'/>
    </div>
  );
};
