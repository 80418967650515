import { Form, Formik } from "formik";
import React, { FC } from "react";
import {
  Button,
  CustomInput,
  RichTextEditor,
} from "../../../../../../components";
import { useAppDispatch } from "../../../../../../store";
import { createArticle } from "../../../../../../store/slices/news";
import appConfig from "../../../../../../util/appConfig";

interface ICreateArticleForm {
  closeModalHandler: () => void;
}

const CreateArticleForm: FC<ICreateArticleForm> = ({ closeModalHandler }) => {
  const dispatch = useAppDispatch();
  const initialValues = {
    title: "",
    articleText: "",
    imgSrc: "",
    shortDescription: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        dispatch(
          createArticle({
            imgSrc: values.imgSrc,
            shortDescription: values.shortDescription,
            title: values.title,
            text: values.articleText,
          })
        );
        resetForm();
        closeModalHandler();
      }}
    >
      {(props) => {
        return (
          <Form>
            <div>
              <CustomInput
                name={"title"}
                type={"text"}
                label={"Titel"}
                hasFullWidth={true}
              />
              <CustomInput
                name={"imgSrc"}
                type={"text"}
                label={"Image link"}
                hasFullWidth={true}
              />
              <CustomInput
                name={"shortDescription"}
                type={"text"}
                label={"Short description"}
                hasFullWidth={true}
              />
              <RichTextEditor
                name={"articleText"}
                type={"text"}
                label={"Article text"}
              />
            </div>
            <Button
              backgroundColor={appConfig.mainColor}
              text="Submit"
              width="250"
              type="submit"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateArticleForm;
