import { instance } from "../api";

interface IPostAppointment {
  place: string;
  date: string;
  time: string;
  firstName: string;
  lastName: string;
  title?: string;
  postcode: string;
  street: string;
  phoneNumber: string;
  email: string;
  message?: string;
  rememberPerMail: boolean | null;
}

const postAppointment = (data: IPostAppointment) => {
  return instance
    .post<{ statusCode: number; message: string }>("/appointment", data)
    .then((res) => {
      return { statusCode: res.status, message: res.data.message };
    })
    .catch((err) => {
      return { statusCode: err.response.status, message: err.data.message };
    });
};

const getAppointmentTimes = (date: string) => {
  return instance.get<string[]>(`/appointment/time?date=${date}`)
}

const Appointment = {
  get: {
    getAppointmentTimes
  },
  post: {
    postAppointment,
  },
};

export default Appointment;
