import React, { useState } from "react";
import { useAppSelector } from "../../../../../../store";
import copyIcon from "../../../../../../assets/images/copyIcon.svg";
import lamp from "../../../../../../assets/images/lamp.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import style from "./style.module.scss";

const ShowToken = () => {
  const token = useAppSelector((state) => state.token.admin.adminToken);
  const [copied, setCopied] = useState(false);

  const copyHandler = () => {
    setCopied(true);
  };
  return (
    <div>
      <div className={style.topWrapper}>
        <div className={style.title}>Your access token:</div>
        <CopyToClipboard onCopy={copyHandler} text={token}>
          <div className={style.copyText}>
            <img src={copyIcon} alt="copy" />
            Copy
          </div>
        </CopyToClipboard>
      </div>
      <div className={`${style.tokenText} ${copied && style.copied}`}>
        {token}
      </div>
      {copied ? (
        <div
          className={style.title}
          style={{
            marginBottom: "3px",
          }}
        >
          Copied!
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <img src={lamp} alt="lamp" className={style.lamp} />
        <div className={style.bottomText}>
          Please, save the token to a secure place. After close this modal
          window you can't see this token again.
        </div>
      </div>
    </div>
  );
};

export default ShowToken;
