import React from "react";
import style from "./style.module.scss";
import { useAppSelector } from "../../../../../store";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import appConfig from "../../../../../util/appConfig";
import Ebwo from "./ebwo";
import Lra from "./lra";
import Calw from "./calw";
// import History from "./history";

const DetailInfo = () => {
  const sperrmuelItemData = useAppSelector(
    (state) => state.sperrmuell.admin.sperrmuelItemInfo
  );

  const generatePickupPlace = (type: string) => {
    switch (type) {
      case "awl": {
        return sperrmuelItemData?.pickupPlace
          ? sperrmuelItemData.pickupPlace
          : "-";
      }
      case "ebwo": {
        return `${sperrmuelItemData?.pickupStreet}, ${
          sperrmuelItemData?.pickupHomeNumber
        }${
          sperrmuelItemData?.pickupHausNrZusatz
            ? sperrmuelItemData?.pickupHausNrZusatz
            : ""
        }${
          sperrmuelItemData?.pickupHomeNumberTo
            ? ` - ${sperrmuelItemData?.pickupHomeNumberTo}`
            : ""
        }`;
      }
      case "lra": {
        return `${sperrmuelItemData?.pickupStreet} ${
          sperrmuelItemData?.pickupHomeNumber
        } ${
          sperrmuelItemData?.pickupHausNrZusatz
            ? `${sperrmuelItemData?.pickupHausNrZusatz}`
            : ""
        }${
          sperrmuelItemData?.pickupHomeNumberTo
            ? ` - ${sperrmuelItemData?.pickupHomeNumberTo}`
            : ""
        }${`, ${sperrmuelItemData?.postcode}`}${
          sperrmuelItemData?.pickupPlace
            ? `, ${sperrmuelItemData?.pickupPlace}`
            : ""
        }`;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div>
      <div className={style.title}>Benutzer Information</div>
      <div className={style.tableInfoWrapper}>
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Name</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.userName} {sperrmuelItemData?.userSurname}
          </div>
        </div>
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Telefonnummer</div>
          <div className={style.rowInfo}>+{sperrmuelItemData?.phoneNumber}</div>
        </div>
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Mobiltelefon</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.mobilePhoneNumber
              ? `+${sperrmuelItemData.mobilePhoneNumber}`
              : "-"}
          </div>
        </div>
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>E-Mail</div>
          <div className={style.rowInfo}>{sperrmuelItemData?.email}</div>
        </div>
        <div className={style.lastRowWrapper}>
          <div className={style.rowTitle}>Erstellungsdatum</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.createdAt
              ? format(
                  new Date(sperrmuelItemData?.createdAt),
                  "dd. MMMM yyyy, HH:mm:ss",
                  {
                    locale: de,
                  }
                )
              : ""}
          </div>
        </div>
      </div>
      <div className={style.title}>Bestellung Information</div>
      <div className={style.tableInfoWrapper}>
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Addresse</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.street} {sperrmuelItemData?.homeNumber}
            {sperrmuelItemData?.hausNrZusatz
              ? ` ${sperrmuelItemData?.hausNrZusatz}`
              : ""}
            {sperrmuelItemData?.homeNumberTo
              ? ` - ${sperrmuelItemData?.homeNumberTo}`
              : ""}
            {sperrmuelItemData?.postcode
              ? `, ${sperrmuelItemData.postcode}`
              : ""}
            {sperrmuelItemData?.pickupPlace
              ? `, ${sperrmuelItemData?.pickupPlace}`
              : ""}
          </div>
        </div>
        {["calw"].includes(appConfig.logo) ? null : (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Abweichender Abholort</div>
            <div className={style.rowInfo}>
              {generatePickupPlace(appConfig.logo)}
            </div>
          </div>
        )}
        {["calw"].includes(appConfig.logo) ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Bestelformular</div>
            <div className={style.rowInfo}>{sperrmuelItemData?.trashType}</div>
          </div>
        ) : null}
        {["calw"].includes(appConfig.logo) ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Servicetyp</div>
            <div className={style.rowInfo}>
              {sperrmuelItemData?.serviceType}
            </div>
          </div>
        ) : null}
        {["calw"].includes(appConfig.logo) &&
        sperrmuelItemData?.buchungszeichen ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Buchungszeichen</div>
            <div className={style.rowInfo}>
              {sperrmuelItemData?.buchungszeichen}
            </div>
          </div>
        ) : null}
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Leistungen</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.pickUpTrash.map((i, idx) => {
              return (
                <div
                  className={
                    sperrmuelItemData?.pickUpTrash &&
                    sperrmuelItemData?.pickUpTrash.length > 1
                      ? style.trashTypeWrapper
                      : ""
                  }
                  key={idx}
                >
                  <div className={style.trashTypeTitle}>
                    {i.trashType}
                    {i.date === "Abfallberatung"
                      ? " Abfallberatung"
                      : i.date === "ExpressService"
                      ? " Express Service"
                      : i.date
                      ? ` = ${format(new Date(i.date), "dd. MMMM yyyy", {
                          locale: de,
                        })}`
                      : ""}
                  </div>
                  {i.comment ? (
                    <div className={style.trashTypeComment}>Kommentar: {i?.comment}</div>
                  ) : null}
                  {i.pickupPlace ? (
                    <div className={style.trashTypeComment}>Abweichender Abholort: {i?.pickupPlace}</div>
                  ) : null}
                  {i.isBigAmount ? (
                    <div className={style.trashTypeComment}>
                      Haushaltsauflösung
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.lastRowWrapper}>
          <div className={style.rowTitle}>Nachricht</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.message ? sperrmuelItemData.message : ""}
          </div>
        </div>
      </div>
      {/* {sperrmuelItemData?.history?.length ? (
        <History history={sperrmuelItemData?.history} />
      ) : null} */}
      {appConfig.logo === "ebwo" ? <Ebwo /> : null}
      {appConfig.logo === "lra" ? <Lra /> : null}
      {appConfig.logo === "calw" ? <Calw /> : null}
    </div>
  );
};

export default DetailInfo;
