import React, { FC } from "react";
import appConfig from "../../util/appConfig";
import checkMark from "../../assets/images/checkMark.svg";
import style from "./style.module.scss";

interface ICheckboxV2 {
  value: boolean | string;
  text: string;
  onChange: () => void;
  isValid?: boolean;
  isValided?: boolean;
}

const CheckboxV2: FC<ICheckboxV2> = ({
  onChange,
  text,
  value,
  isValid = true,
  isValided = false,
}) => {

  return (
    <div className={style.wrapper}>
      <div
        className={style.checkBox}
        style={{
          backgroundColor: value ? appConfig.mainColor : "#8F9EA9",
          opacity: value ? 1 : 0.2,
          border: isValided && !value ? "2px solid #de3745" : "",
        }}
        onClick={onChange}
      >
        {value ? (
          <img className={style.checkedImg} src={checkMark} alt="check" />
        ) : null}
      </div>
      <div
        onClick={onChange}
        className={style.text}
        style={{
          color: isValided && !value ? "#de3745" : "",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default CheckboxV2;
