import { format } from "date-fns";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Footer, TitleComponent } from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { getArticleById } from "../../store/slices/news";
import parse from "html-react-parser";
import style from "./style.module.scss";

const ActualNewsArticle = () => {
  const dispatch = useAppDispatch();
  const article = useAppSelector((state) => state.news.client.article);

  const { articleId } = useParams();

  useEffect(() => {
    if (articleId) {
      dispatch(getArticleById(articleId));
    }
  }, [articleId]);

  if (!article) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <TitleComponent
        title={article.title}
        marginBottom={"16"}
        titleWidthContainer={"790"}
      />
      <div className={style.helperWrapper}>
        <div className={style.shortDescription}>{article.shortDescription}</div>
        <div className={style.date}>
          {format(new Date(article.date), "dd.MM.yyyy")}
        </div>
        <div>
          <div
            className={style.img}
            style={{
              backgroundImage: `url(${article.imgSrc})`,
            }}
          >
            {/* <img className={style.img} src={article.imgSrc} alt={article._id} /> */}
          </div>
          <div className={style.articleTextWrapper}>{parse(article.text)}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ActualNewsArticle;
