import React, { FC } from "react";
import style from "./style.module.scss";

interface IModalWrapper {
  children: React.ReactNode;
  modalCloseHandler: () => void;
}

export const ModalWrapper: FC<IModalWrapper> = ({
  children,
  modalCloseHandler,
}) => {
  return (
    <div className={style.wrapper} onClick={modalCloseHandler}>
      {children}
    </div>
  );
};
