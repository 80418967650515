import React, { FC } from "react";
import { ICalendarData } from "../../../../../../api/calendar/calendar.api";

interface IItemInfo {
  item: ICalendarData;
}

const ItemInfo: FC<IItemInfo> = ({item}) => {
  return (
    <>
      <div>
        <div>
          Strasse: {item.streetDistrict} {item.streetNum}
        </div>
        <div>Termin: {item.date}</div>
        <div>Farbe: {item.color}</div>
        <div>Platz: {item.place}</div>
      </div>
    </>
  );
};

export default ItemInfo;
