import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  DeleteConfirmationModal,
  Loader,
  ModalWrapper,
} from "../../components";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import Modal from "./components/Modal";
import ContentComponent from "./components/ContentComponent";
import {
  clearStreets,
  getGreyListOfStreets,
  setDataLoading,
  updateGreyListStreets,
} from "../../store/slices/admin";

const GreyListStreets = () => {
  const dispatch = useAppDispatch();
  const { ignoredGreyListOfStreets } = useAppSelector((state) => state.admin);
  const isBlockedStreetsContentLoading = useAppSelector(
    (state) => state.admin.isBlockedStreetsContentLoading
  );
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [isDeleteConfirmationModalActive, setIsDeleteConfirmationModalActive] =
    useState(false);

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = ignoredGreyListOfStreets.map((i) => i.strasseNummer);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: number) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const openDeleteConfirmationModalHandler = () => {
    if (selectedItems.length) {
      dispatch(setIsConfirmationPopupActive(true));
      setIsDeleteConfirmationModalActive(true);
    }
  };

  const clearSelectedItems = () => {
    if (selectedItems.length) {
      const d = selectedItems.map((i) => {
        const blockedNumbers = ignoredGreyListOfStreets.find(
          (item) => item.strasseNummer === i
        )!.greyListHomeNumbers.length
          ? [
              ...ignoredGreyListOfStreets
                .find((j) => j.strasseNummer === +i)!
                .greyListHomeNumbers.map((k) => k._id),
            ]
          : [];
        return {
          id: i.toString(),
          ignore: "active" as "active" | "partially" | "ignore",
          greyListHomeNumbers: blockedNumbers,
        };
      });
      dispatch(updateGreyListStreets(d));
      dispatch(setIsConfirmationPopupActive(false));
      setSelectedItems([]);
      setIsDeleteConfirmationModalActive(false);
    }
  };

  useEffect(() => {
    dispatch(getGreyListOfStreets());
  }, []);

  useEffect(() => {
    if (!ignoredGreyListOfStreets.length) return;
    if (selectedItems.length === ignoredGreyListOfStreets.length) {
      setAllItemsSelected(true);
    } else {
      setAllItemsSelected(false);
    }
  }, [selectedItems.length, ignoredGreyListOfStreets.length]);

  useEffect(() => {
    return () => {
      dispatch(clearStreets());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        clearSelectedItems={openDeleteConfirmationModalHandler}
        hasItemsLength={!!ignoredGreyListOfStreets.length}
        hasSelectedItems={!!selectedItems.length}
        pageTitle={"Sperrliste Straßen"}
        hasBtnGroup={true}
        btnGroupAddItemText={"Straße sperren"}
        btnGroupRemoveItemsText={"Straße entsperren"}
      />
      <AdminContentWrapper>
        {isBlockedStreetsContentLoading ? null : !ignoredGreyListOfStreets.length ? (
          <AdminPlaceholder
            title="No Grey List Streets Yet"
            subTitle="Straße zur grauen Liste hinzufügen"
            hasBtn={true}
            btnText={"Add a street"}
          />
        ) : (
          <ContentComponent
            areAllItemsSelected={allItemsSelected}
            selectAllItemsHandler={allItemsSelectHandler}
            selectedItems={selectedItems}
            selectItemHandler={selectItemHandler}
          />
        )}
      </AdminContentWrapper>
      {isModalActive ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            setIsDeleteConfirmationModalActive(false);
          }}
        >
          {isDeleteConfirmationModalActive ? (
            <DeleteConfirmationModal
              removeHandler={clearSelectedItems}
              title={"Straße entsperren"}
              subTitle={
                "Sind Sie sicher, dass Sie die Straße(n) etsperren möchten?"
              }
            />
          ) : (
            <Modal />
          )}
        </ModalWrapper>
      ) : null}
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default GreyListStreets;
