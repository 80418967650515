import { useField } from "formik";
import React, { FC } from "react";
import style from "./style.module.scss";
import checkMark from "../../assets/images/checkMark.svg";

interface ICheckbox {
  name: string;
  value: string;
  isLastItem?: boolean;
}

export const Checkbox: FC<ICheckbox> = ({ name, value }) => {
  const [field, , helpers] = useField({
    name,
    type: "checkbox",
    multiple: true,
  });

  // const [isFiledValueIncluded, setIsFieldValueIncluded] = useState(false);

  const checkboxHandler = () => {
    helpers.setValue(value);
  };

  return (
    <label className={`${style.checkbox_label}`}>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={field.value === value}
        style={{
          display: "none",
        }}
        onChange={() => {}}
      />
      <div
        className={`${style.checkBoxWrapper} ${
          field.value === value && style.checkBoxWrapper_checked
        }`}
      >
        <div
          onClick={checkboxHandler}
          className={`${style.checkBox} ${
            field.value === value && style.checkBox_checked
          }`}
        >
          {field.value === value && (
            <img className={style.checkedImg} src={checkMark} alt="check" />
          )}
        </div>
      </div>
      <span
        className={`${style.title} ${
          field.value === value && style.checkboxTitle
        }`}
        onClick={checkboxHandler}
      >
        {value}
      </span>
    </label>
  );
};
