import { instance } from "../api";

const getBehaelter = () => {
  return instance
    .get<
      {
        type: string;
        fraction: string;
        volume: string;
        tour: string;
        id: string;
      }[]
    >("/behaelterverwaltung")
    .then((res) => res.data);
};

const addBehaelter = ({
  quantity,
  type,
  volume,
  additionalBin,
  additionalBinVolume,
}: {
  type: string;
  volume: string;
  quantity: number;
  additionalBin?: boolean;
  additionalBinVolume?: string;
}) => {
  return instance
    .post<
      {
        type: string;
        fraction: string;
        volume: string;
        tour: string;
        id: string;
      }[]
    >("/behaelterverwaltung", {
      quantity,
      type,
      volume,
      additionalBin,
      additionalBinVolume,
    })
    .then((res) => res.data);
};

const defectBehaelter = ({ id, reason }: { id: string; reason: string }) => {
  return instance
    .post<{ message: string }>(`/behaelterverwaltung/${id}/defect`, {
      reason,
    })
    .then((res) => res.data);
};

const disappearedBehaelter = ({
  id,
  disappeared,
}: {
  id: string;
  disappeared: boolean;
}) => {
  return instance
    .post<{ message: string }>(`/behaelterverwaltung/${id}/disappeared`, {
      disappeared,
    })
    .then((res) => res.data);
};

const updateBehaelter = ({ id, volume }: { id: string; volume: string }) => {
  return instance
    .put<
      {
        type: string;
        fraction: string;
        volume: string;
        tour: string;
        id: string;
      }[]
    >(`/behaelterverwaltung/${id}`, {
      volume,
    })
    .then((res) => res.data);
};

const removeBehaelter = ({ id }: { id: string }) => {
  return instance
    .delete<
      {
        type: string;
        fraction: string;
        volume: string;
        tour: string;
        id: string;
      }[]
    >(`/behaelterverwaltung/${id}`)
    .then((res) => res.data);
};

const Behaelterverwaltung = {
  get: {
    getBehaelter,
  },
  post: {
    addBehaelter,
    defectBehaelter,
    disappearedBehaelter,
  },
  put: {
    updateBehaelter,
  },
  delete: {
    removeBehaelter,
  },
};

export default Behaelterverwaltung;
