import React, { FC } from "react";
import { Button } from "../Button";
import ButtonGroup from "./components/ButtonGroup";
import style from "./style.module.scss";

interface IAdminHeader {
  pageTitle: string;
  hasItemsLength?: boolean;
  hasSelectedItems?: boolean;
  hasBtnGroup: boolean;
  hasSingleBtn?: boolean;
  singleBtnColor?: string;
  singleBtnText?: string;
  clearSelectedItems?: () => void;
  singleBtnFunc?: () => void | null;
  btnGroupAddItemText?: string;
  btnGroupRemoveItemsText?: string;
  createBtnHandlerFunc?: () => void;
}

export const AdminHeader: FC<IAdminHeader> = ({
  pageTitle,
  hasItemsLength = false,
  hasSelectedItems = false,
  hasBtnGroup,
  clearSelectedItems,
  hasSingleBtn,
  singleBtnColor = "#008BD2",
  singleBtnText,
  singleBtnFunc,
  btnGroupAddItemText,
  btnGroupRemoveItemsText,
  createBtnHandlerFunc,
}) => {

  return (
    <div className={style.headerWrapper}>
      <div className={style.title}>{pageTitle}</div>
      {hasItemsLength && hasBtnGroup ? (
        <ButtonGroup
          areItemsSelected={hasSelectedItems}
          removeItems={clearSelectedItems}
          btnGroupAddItemText={btnGroupAddItemText ?? ""}
          btnGroupRemoveItemsText={btnGroupRemoveItemsText ?? ""}
          createBtnHandlerFunc={createBtnHandlerFunc}
        />
      ) : null}
      {hasSingleBtn ? (
        <Button
          onClick={singleBtnFunc}
          backgroundColor={singleBtnColor}
          text={singleBtnText ?? ""}
          width={"172"}
        />
      ) : null}
    </div>
  );
};
