import axios from "axios";
import { userToken } from "../util/userToken";
import Admin from "./admin/admin.api";
import Appointment from "./appointment/appointment.api";
import Auth from "./auth/auth.api";
import Spermuell from "./spermuell/spermuell.api";
import Calendar from "./calendar/calendar.api";
import News from "./news/news.api";
import Portal from "./portal/portal.api";
import Schadstoffmobil from "./schadstoffmobil/schadstoffmobil.api";
import Token from "./token/token.api";
import Reclamation from "./reclamation/reclamation.api";
import Behaelterverwaltung from "./behaelterverwaltung/behaelterverwaltung.api";
import MailTracking from "./mailTracking/mailTracking.api";
import Behaeltergemeinschaft from "./behaeltergemeinschaft/behaeltergemeinschaft.api";
import Eigenkompostierer from "./eigenkompostierer/eigenkompostierer.api";
import YellowBin from "./yellowBin/yellowBin.api";
import LeerungsdatenEinsicht from "./leerungsdatenEinsicht/leerungsdatenEinsicht";
import Sepa from "./sepa/sepa.api";
import ObjectVerwaltung from "./objectVerwaltung/objectVerwaltung.api";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

/**
 * Interceptor (middleware), will add user token to every server request
 */
instance.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
});

export const adminInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

adminInstance.interceptors.request.use((config) => {
  const token = userToken.getAdminToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
    // cancelToken: cancelToken.token,
  };
});

adminInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response.status === 401) {
      userToken.removeAdminToken();
      window.location.href = "/admin";
    }
    if (axios.isCancel(err)) {
      return Promise.reject("Request canceled");
    }
    return Promise.reject(err);
  }
);

const API = {
  admin: Admin,
  auth: Auth,
  appointment: Appointment,
  spermuell: Spermuell,
  calendar: Calendar,
  schadstoffmobil: Schadstoffmobil,
  news: News,
  portal: Portal,
  token: Token,
  reclamation: Reclamation,
  behaelterverwaltung: Behaelterverwaltung,
  mailTracking: MailTracking,
  behaeltergemeinschaft: Behaeltergemeinschaft,
  eigenkompostierer: Eigenkompostierer,
  yellowBin: YellowBin,
  leerungsdatenEinsicht: LeerungsdatenEinsicht,
  sepa: Sepa,
  objectVerwaltung: ObjectVerwaltung,
};

export default API;
