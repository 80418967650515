import React, { MouseEvent } from "react";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { clearAdminToken } from "../../../../store/slices/token";
import { setIsConfirmationPopupActive } from "../../../../store/slices/app";
import CreateTokenForm from "./components/CreaateTokenForm";
import ShowToken from "./components/ShowToken";
import style from "./style.module.scss";

const Modal = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.token.admin.adminToken);

  const closeModalHandler = () => {
    dispatch(setIsConfirmationPopupActive(false));
    dispatch(clearAdminToken());
  };

  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <div className={style.headerWrapper}>
        <div className={style.title}>API Key hinzufügen</div>
        <img
          src={closeIcon}
          alt="close"
          onClick={closeModalHandler}
          className={style.img}
        />
      </div>
      <div>
        {!token.length ? (
          <CreateTokenForm closeModalHandler={closeModalHandler} />
        ) : (
          <ShowToken />
        )}
      </div>
    </div>
  );
};

export default Modal;
