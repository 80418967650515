import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setAdminLoader } from "./admin";
import API from "../../api/api";

export interface IAppState {
  client: {
    isLoading: boolean;
    isConfirmationPopupActive: boolean;
    isBurgerMenuOpen: boolean;
    newSperrmuellScheduleStatus: boolean;
    newSperrmuellScheduleTitle: string;
    newSperrmuellScheduleSubtitle: string;
    newSperrmuellScheduleURL: string;
    holidays: string[];
    infoText: {
      step: number;
      isWarningMessage?: boolean;
      hints: string[];
    }[];
    cookie: string;
    isMobile: boolean;
  };
  admin: {
    bccEMailAddress: string;
    isSettingsContentLoaded: boolean;
  };
  common: {
    isToasterError: boolean;
    errorMessage?: string;
    isToasterSuccess: boolean;
    successMessage?: string;
  };
}

const initialState: IAppState = {
  client: {
    isLoading: false,
    isConfirmationPopupActive: false,
    isBurgerMenuOpen: false,
    newSperrmuellScheduleStatus: false,
    newSperrmuellScheduleTitle: "",
    newSperrmuellScheduleSubtitle: "",
    newSperrmuellScheduleURL: "",
    holidays: [],
    infoText: [
      {
        step: 2,
        isWarningMessage: false,
        hints: ["lol", "kek", "cheburek"],
      },
    ],
    cookie: "",
    isMobile: false,
  },
  admin: {
    bccEMailAddress: "",
    isSettingsContentLoaded: false,
  },
  common: {
    isToasterError: false,
    errorMessage: "",
    isToasterSuccess: false,
    successMessage: "",
  },
};

export const getPortalData = createAsyncThunk(
  "app/client/getPortalData",
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const data = await API.portal.get.getPortalSettings();

      return data;
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setIsToasterError(true));
    } finally {
      thunkApi.dispatch(setIsLoading(false));
    }
  }
);

export const getPortalSettingsData = createAsyncThunk(
  "app/admin/getPortalSettingsData",
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const data = await API.admin.get.getPortalSettingsData();
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setData(data));

      return data;
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const updateSperrmuellScheduleSettings = createAsyncThunk(
  "app/client/updateSperrmuellScheduleSettings",
  async (
    obj: {
      status: boolean;
      title: string;
      subtitle: string;
      url: string;
      bccEMailAddress: string;
      holidays: string[];
    },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const data = await API.admin.put.updateSperrmuellScheduleSettings(obj);

      thunkApi.dispatch(setAdminLoader(false));
      return data;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
    }
  }
);

export const getHintsText = createAsyncThunk(
  "app/admin/getHintsText",
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const data = await API.portal.get.getHintsText();
      thunkApi.dispatch(setAdminLoader(false));

      return data;
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setAdminLoader(false));
    }
  }
);

export const updateHintsText = createAsyncThunk(
  "app/client/updateHintsText",
  async (
    obj: { step: number; hints: string[]; isWarningMessage: boolean }[],
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const data = await API.portal.post.updateHintsText(obj);
      thunkApi.dispatch(setAdminLoader(false));

      return data;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.client.isLoading = action.payload;
    },
    setIsConfirmationPopupActive: (state, action: PayloadAction<boolean>) => {
      state.client.isConfirmationPopupActive = action.payload;
    },
    setIsToasterError: (state, action: PayloadAction<boolean>) => {
      state.common.isToasterError = action.payload;
    },
    setIsToasterSuccess: (state, action: PayloadAction<boolean>) => {
      state.common.isToasterSuccess = action.payload;
    },
    setIsBurgerMenu: (state, action: PayloadAction<boolean>) => {
      state.client.isBurgerMenuOpen = action.payload;
    },
    setSperrmuellScheduleStatus: (state, action: PayloadAction<boolean>) => {
      state.client.newSperrmuellScheduleStatus = action.payload;
    },
    setSperrmuellScheduleTitle: (state, action: PayloadAction<string>) => {
      state.client.newSperrmuellScheduleTitle = action.payload;
    },
    setSperrmuellScheduleSubtitle: (state, action: PayloadAction<string>) => {
      state.client.newSperrmuellScheduleSubtitle = action.payload;
    },
    setSperrmuellScheduleUrl: (state, action: PayloadAction<string>) => {
      state.client.newSperrmuellScheduleURL = action.payload;
    },
    setData: (
      state,
      action: PayloadAction<{
        status: boolean;
        title: string;
        subtitle: string;
        url: string;
      }>
    ) => {
      state.client.newSperrmuellScheduleStatus = action.payload.status;
      state.client.newSperrmuellScheduleTitle = action.payload.title;
      state.client.newSperrmuellScheduleSubtitle = action.payload.subtitle;
      state.client.newSperrmuellScheduleURL = action.payload.url;
    },
    clearData: (state) => {
      state.client.newSperrmuellScheduleTitle = "";
      state.client.newSperrmuellScheduleSubtitle = "";
      state.client.newSperrmuellScheduleURL = "";
      state.client.newSperrmuellScheduleStatus = false;
      state.admin.isSettingsContentLoaded = false;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.common.errorMessage = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.common.successMessage = action.payload;
    },
    clearErrorMessage: (state) => {
      state.common.errorMessage = "";
    },
    clearSuccessMessage: (state) => {
      state.common.successMessage = "";
    },
    setCookie: (state, action: PayloadAction<string>) => {
      state.client.cookie = action.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.client.isMobile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPortalData.pending, (state) => {
      // state.client.isLoading = true;
    });
    builder.addCase(getPortalData.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.isLoading = false;
        state.client.newSperrmuellScheduleStatus = action.payload.status;
        state.client.newSperrmuellScheduleTitle = action.payload.title;
        state.client.newSperrmuellScheduleSubtitle = action.payload.subtitle;
        state.client.newSperrmuellScheduleURL = action.payload.url;
        state.admin.bccEMailAddress = action.payload.bccEMailAddress;
        state.client.infoText = action.payload.hints;
      }
      // localStorage.setItem("infoText", JSON.stringify(action.payload.hints));
    });
    builder.addCase(getPortalData.rejected, (state) => {
      // state.client.isLoading = false;
      // state.common.isToasterError = true;

      state.client.infoText = [];
      // const data = localStorage.getItem("infoText");

      // if (data) {
      //   state.client.infoText = JSON.parse(data);
      // }
    });
    builder.addCase(getPortalSettingsData.pending, (state) => {
      // state.client.isLoading = true;
    });
    builder.addCase(getPortalSettingsData.fulfilled, (state, action) => {
      // state.client.isLoading = false;
      if (action.payload) {
        state.client.newSperrmuellScheduleStatus = action.payload.status;
        state.client.newSperrmuellScheduleTitle = action.payload.title;
        state.client.newSperrmuellScheduleSubtitle = action.payload.subtitle;
        state.client.newSperrmuellScheduleURL = action.payload.url;
        state.admin.bccEMailAddress = action.payload.bccEMailAddress;
        state.client.holidays = action.payload.holidays;
        state.admin.isSettingsContentLoaded = true;
      }
    });
    builder.addCase(getPortalSettingsData.rejected, (state) => {
      // state.client.isLoading = false;
      state.admin.isSettingsContentLoaded = true;
      state.common.isToasterError = true;
    });
    builder.addCase(
      updateSperrmuellScheduleSettings.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.client.newSperrmuellScheduleStatus = action.payload.status;
          state.client.newSperrmuellScheduleTitle = action.payload.title;
          state.client.newSperrmuellScheduleSubtitle = action.payload.subtitle;
          state.client.newSperrmuellScheduleURL = action.payload.url;
          state.admin.bccEMailAddress = action.payload.bccEMailAddress;
          state.client.holidays = action.payload.holidays;
        }
      }
    );
    builder.addCase(updateSperrmuellScheduleSettings.rejected, (state) => {
      state.common.isToasterError = true;
    });
    builder.addCase(getHintsText.pending, (state) => {});
    builder.addCase(getHintsText.fulfilled, (state, action) => {
      state.admin.isSettingsContentLoaded = true;
      if (action.payload) {
        state.client.infoText = action.payload;
      }
    });
    builder.addCase(getHintsText.rejected, (state) => {
      state.admin.isSettingsContentLoaded = true;
      state.common.isToasterError = true;
    });
    builder.addCase(updateHintsText.pending, (state) => {});
    builder.addCase(updateHintsText.fulfilled, (state, action) => {
      state.admin.isSettingsContentLoaded = true;
      if (action.payload) {
        state.client.infoText = action.payload;
      }
    });
    builder.addCase(updateHintsText.rejected, (state) => {
      state.admin.isSettingsContentLoaded = true;
      state.common.isToasterError = true;
    });
  },
});

export const {
  setIsLoading,
  setIsConfirmationPopupActive,
  setIsToasterError,
  setIsBurgerMenu,
  setSperrmuellScheduleStatus,
  setSperrmuellScheduleTitle,
  setSperrmuellScheduleSubtitle,
  setSperrmuellScheduleUrl,
  setData,
  clearData,
  clearErrorMessage,
  setErrorMessage,
  setCookie,
  setIsMobile,
  setIsToasterSuccess,
  setSuccessMessage,
  clearSuccessMessage,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
