import { adminInstance } from "../api";

const getMails = ({
  direction,
  pageSize,
  page,
  searchValue,
  sort,
  signal,
}: {
  page?: number;
  pageSize?: number;
  direction?: "asc" | "desc";
  sort?: string;
  searchValue?: string;
  signal?: AbortSignal;
}) => {
  return adminInstance
    .get<{
      data: { id: string; email: string; type: string; date: string }[];
      totalCount: number;
    }>(`/admin/mail-tracking`, {
      params: {
        pageSize,
        page,
        searchValue,
        sort,
        direction,
      },
      signal,
    })
    .then((res) => res.data);
};

const MailTracking = {
  get: {
    getMails,
  },
};

export default MailTracking;
