import React from "react";
import { AdminDetailsPageItemName } from "../../../../components";
import DetailInfo from "./DetailInfo";
import appConfig from "../../../../util/appConfig";

const ContentComponent = () => {
  return (
    <div>
      <AdminDetailsPageItemName
        text={
          appConfig.logo === "calw"
            ? "Detailinformationen"
            : "Anmeldeinformationen"
        }
      />
      <DetailInfo />
    </div>
  );
};

export default ContentComponent;
