import React, { FC } from "react";
import { Button, TableGrid } from "../../../../components";
import { useAppSelector } from "../../../../store";
import appConfig from "../../../../util/appConfig";
// import HeaderComponent from "./HeaderComponent";
// import Item from "./Item";

interface IContentComponent {
  loadMoreStreetsHandler: () => void;
}

const ContentComponent: FC<IContentComponent> = ({
  loadMoreStreetsHandler,
}) => {
  const streetData = useAppSelector((state) => state.admin.streetsData);
  const isBtnAvailable = useAppSelector(
    (state) => state.admin.hasStreetsDataNextPage
  );

  return (
    <div>
      {/* <HeaderComponent />
      <div>
        {streetData.map((i, idx) => {
          return <Item key={idx} streetName={i.street} />;
        })}
      </div> */}
      <TableGrid
        data={streetData}
        items={[
          {
            field: "street",
            title: "Straßenname",
          },
        ]}
      />
      {isBtnAvailable ? (
        <Button
          text="Mehr laden..."
          width="300"
          backgroundColor={appConfig.mainColor}
          onClick={loadMoreStreetsHandler}
          marginTop={'20'}
        />
      ) : null}
    </div>
  );
};

export default ContentComponent;
