import React, { FC } from "react";
// import futureTabIcon from "../../../../assets/images/futureTabIcon.svg";
// import allertSettingsIcon from "../../../../assets/images/allertSettingsIcon.svg";
import style from "./style.module.scss";
import Tab from "./content/Tab";
import appConfig from "../../../../util/appConfig";

interface IContentTabs {
  tabIndex: number;
  setTab: (idx: number) => void;
}

const tabs = [
  { title: "Warnmeldung", icon: "allertSettingsIcon", isActive: true },
  { title: "Feiertage", icon: "futureTabIcon", isActive: true },
  { title: "Hinweistexte", icon: "addIcon", isActive: true },
];

const ContentTabs: FC<IContentTabs> = ({ tabIndex, setTab }) => {
  tabs.splice(2, appConfig.isEditHintTextAvailable ? 0 : 1);

  return (
    <div className={style.wrapper}>
      {tabs.map((i, idx) => {
        return (
          <Tab
            key={idx}
            icon={i.icon}
            isActive={i.isActive}
            isTabActive={tabIndex === idx}
            tabIndex={idx}
            onClick={setTab}
            title={i.title}
          />
        );
      })}
    </div>
  );
};

export default ContentTabs;
