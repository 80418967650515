import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";

export interface IMailTracking {
  admin: {
    mails: { id: string; email: string; type: string; date: string }[];
    totalCount: number;
  };
}

const initialState: IMailTracking = {
  admin: {
    mails: [],
    totalCount: 0,
  },
};

export const getMails = createAsyncThunk(
  "mailTracking/admin/getMails",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal
    }: {
      direction?: "asc" | "desc";
      page: number;
      pageSize: number;
      searchValue: string;
      sort: string;
      signal?: AbortSignal;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));

      const mails = await API.mailTracking.get.getMails({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
      });

      return mails;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const mailTrackingSlice = createSlice({
  name: "mailTracking",
  initialState,
  reducers: {
    clearMails: (state) => {
      state.admin.mails = [];
      state.admin.totalCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMails.pending, (state) => {});
    builder.addCase(getMails.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.mails = action.payload.data;
        state.admin.totalCount = action.payload.totalCount;
      }
    });
    builder.addCase(getMails.rejected, (state) => {});
  },
});

export const { clearMails } = mailTrackingSlice.actions;

export const mailTrackingReducer = mailTrackingSlice.reducer;
