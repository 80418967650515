import React, { useEffect, useState } from "react";
import { Button, Footer, Title } from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { clearNewsData, getNews, getNewsData } from "../../store/slices/news";
import appConfig from "../../util/appConfig";
import AllNews from "./components/AllNews";
import FreshNews from "./components/FreshNews";
import style from "./style.module.scss";

const ActualNews = () => {
  const dispatch = useAppDispatch();
  const [height, setHeight] = useState<number>(100);

  const count = useAppSelector((state) => state.news.client.count);
  const freshNews = useAppSelector((state) => state.news.client.freshNews);
  const news = useAppSelector((state) => state.news.client.news);
  const page = useAppSelector((state) => state.news.client.page);
  const isLoading = useAppSelector((state) => state.app.client.isLoading);

  useEffect(() => {
    setHeight(100);
  }, [window.innerHeight]);

  useEffect(() => {
    dispatch(getNewsData());

    return () => {
      dispatch(clearNewsData());
    };
  }, []);

  return (
    <div
      style={{
        minHeight: `calc(${height}vh - 66px)`,
        filter: isLoading ? "blur(2px)" : "",
        // position: "relative",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
      id="helperWrapper"
    >
      <div className={style.innerWrapper}>
        <Title title={"Neuste Meldungen & Nachrichten"} marginBottom={"36"} />
        {freshNews.length ? <FreshNews /> : null}
        {news.length ? (
          <Title title={"Alle Nachrichten"} marginBottom={"48"} />
        ) : null}
        {news.length ? <AllNews /> : null}
        {count > freshNews.length + news.length ? (
          <div className={style.btnWrapper}>
            <Button
              width="222"
              text="Mehr Nachrichten laden"
              backgroundColor={appConfig.mainColor}
              onClick={() => {
                dispatch(getNews(page));
              }}
              hasDownArrow={true}
            />
          </div>
        ) : null}
      </div>
      <div
      // className={style.footerHelperWrapper}
      >
        <Footer />
      </div>
    </div>
  );
};

export default ActualNews;
