import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import { userToken } from "../../util/userToken";

export interface IUser {
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  postCode: string;
  email: string;
  errorMsg: string;
}

export interface IObjectIdUser {
  abrechnungsartnr: number;
  abstimmkonto: string;
  anrede: string;
  branchekennung: string;
  debitornr: number;
  email?: string | null;
  fremdschluesselkennung: string;
  gpnr?: string | null | number;
  grosskunde_jn?: number | null;
  hausnr: string;
  kontengruppe: string;
  la: number;
  lagehinweis?: null | string;
  land: string;
  mahnverfahrenkennung: string;
  nachname: string;
  namezusatz: null | string;
  ort: string;
  partnergesellschaft: null | string;
  plz: string;
  plzpostfach: null | string;
  postfach: null | string;
  sachbearbeiter1kennung: string;
  sachbearbeiter2kennung: string;
  strasse: string;
  telefax: null | string;
  telefon: null | string;
  titel: null | string;
  va: number;
  vorname: string;
  zahlungsartkennung: string;
  zahlungsbedingungnr: number;
}

export interface IObjectUserResponse extends IObjectIdUser {
  token: string;
}

interface IInitState {
  user: IUser;
  objectIdUser: IObjectIdUser;
}

const initialState: IInitState = {
  objectIdUser: {
    abrechnungsartnr: 0,
    abstimmkonto: "",
    anrede: "",
    branchekennung: "",
    debitornr: 0,
    fremdschluesselkennung: "",
    hausnr: "",
    kontengruppe: "",
    la: 0,
    land: "",
    mahnverfahrenkennung: "",
    nachname: "",
    namezusatz: "",
    ort: "",
    partnergesellschaft: "",
    plz: "",
    plzpostfach: "",
    postfach: "",
    sachbearbeiter1kennung: "",
    sachbearbeiter2kennung: "",
    strasse: "",
    telefax: "",
    telefon: "",
    titel: "",
    va: 0,
    vorname: "",
    zahlungsartkennung: "",
    zahlungsbedingungnr: 0,
    email: "",
    gpnr: "",
    grosskunde_jn: 0,
    lagehinweis: "",
  },
  user: {
    city: "",
    email: "",
    errorMsg: "",
    firstName: "",
    lastName: "",
    postCode: "",
    street: "",
  },
  // firstName: "",
  // lastName: "",
  // city: "",
  // street: "",
  // postCode: "",
  // email: "",
  // errorMsg: "",
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (code: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const data = API.auth.post.nrwLogin(code);
      thunkAPI.dispatch(setIsLoading(false));
      return data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);

export const objectIdLogin = createAsyncThunk(
  "user/objectIdLogin",
  async (
    { objectId }: { objectId: string; navigate: () => void },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.post.objectIdLogin({ objectId });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(false));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const getMe = createAsyncThunk(
  "user/getMe",
  async (token: string, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.auth.post.me(token);

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(false));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.message && action.payload.message.length) {
        state.user.errorMsg = action.payload.message;
      } else {
        state.user.street = action.payload?.street!;
        state.user.city = action.payload?.city!;
        state.user.email = action.payload?.email!;
        state.user.firstName = action.payload?.firstName!;
        state.user.lastName = action.payload?.lastName!;
        state.user.postCode = action.payload?.postCode!;
      }
    });
    builder.addCase(objectIdLogin.pending, (state) => {});
    builder.addCase(objectIdLogin.fulfilled, (state, action) => {
      if (action.payload) {
        userToken.set(action.payload.token);
        let data = { ...action.payload };
        //@ts-ignore
        delete data.token;
        state.objectIdUser = data;
        // state.firstName = action.payload.vorname;
        // state.firstName = action.payload.nachname;
        // state.city = action.payload.abstimmkonto;
        // state.street = action.payload.strasse;
        action.meta.arg.navigate();
      }
    });
    builder.addCase(objectIdLogin.rejected, (state) => {});
    builder.addCase(getMe.pending, (state) => {});
    builder.addCase(getMe.fulfilled, (state, action) => {
      if (action.payload) {
        userToken.set(action.payload.token);
        let data = { ...action.payload };
        //@ts-ignore
        delete data.token;
        state.objectIdUser = data;

      }
    });
    builder.addCase(getMe.rejected, (state) => {});
  },
});

export const userReducer = userSlice.reducer;
