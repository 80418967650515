import { useField } from "formik";
import React, { FC } from "react";
import style from "./style.module.scss";
import validationError from "../../assets/images/validationError.svg";

interface ITextArea {
  label?: string;
  name: string;
  type: string;
  placeholder?: string;
}
export const TextArea: FC<ITextArea> = ({ label, name, type, placeholder }) => {
  const [field, meta] = useField({
    name,
    type,
  });
  return (
    <div
      className={`${style.wrapper} ${
        meta.touched && meta.error && style.input_wrapperWithWarning
      }`}
    >
      {label ? <label className={`${style.labelText} ${meta.touched && meta.error && style.labelWithWarning}`}>{label}</label> : null}
      <textarea
        placeholder={placeholder}
        value={field.value}
        onChange={field.onChange}
        name={field.name}
        className={`${style.textArea} ${
          meta.touched &&
          meta.error &&
          style.customInputWithWarning
        }`}
      />
      {meta.touched && meta.error && (
        <div className={style.errorMessage}>{meta.error}</div>
      )}
      {meta.touched && meta.error && (
        <img
          src={validationError}
          alt="err"
          className={`${style.warningImg}`}
        />
      )}
    </div>
  );
};
