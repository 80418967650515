import React, { FC } from "react";
import portalSettingsTabIcon from "../../../../../../assets/images/portalSettingsTabIcon.svg";
import style from "./style.module.scss";

interface ITab {
  title: string;
  icon: string;
  isActive: boolean;
  isTabActive: boolean;
  onClick: (num: number) => void;
  tabIndex: number;
}

interface ISVGIcon {
  isActive: boolean;
}

const AllertSettingsIcon: FC<ISVGIcon> = ({ isActive }) => {
  return (
    <svg
      className={style.img}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8592 12.3146L8.8395 0.673038C8.66209 0.37882 8.34356 0.198914 7.99997 0.198914C7.65637 0.198914 7.33784 0.378788 7.16044 0.673038L0.140811 12.3146C-0.0417516 12.6174 -0.0471578 12.995 0.126717 13.3029C0.300592 13.6107 0.626749 13.8011 0.980311 13.8011H15.0197C15.3732 13.8011 15.6994 13.6107 15.8733 13.3029C16.0471 12.995 16.0417 12.6174 15.8592 12.3146ZM8.00522 4.22585C8.40837 4.22585 8.7495 4.45329 8.7495 4.85641C8.7495 6.08651 8.60478 7.85419 8.60478 9.08429C8.60478 9.40476 8.25331 9.5391 8.00522 9.5391C7.67447 9.5391 7.39534 9.40473 7.39534 9.08429C7.39534 7.85419 7.25066 6.08651 7.25066 4.85641C7.25066 4.45329 7.58141 4.22585 8.00522 4.22585ZM8.01556 11.8443C7.56075 11.8443 7.21959 11.4722 7.21959 11.0484C7.21959 10.6142 7.56072 10.2524 8.01556 10.2524C8.43937 10.2524 8.80119 10.6142 8.80119 11.0484C8.80119 11.4722 8.43937 11.8443 8.01556 11.8443Z"
        fill={isActive ? "#353B3F" : "#8F9EA9"}
      />
    </svg>
  );
};

const CalendarIcon: FC<ISVGIcon> = ({ isActive }) => {
  return (
    <svg
      className={style.img}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={isActive ? "#353B3F" : "#8F9EA9"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_378_1369)">
        <path
          d="M15.9375 18H2.06255C0.924774 18 0 17.0752 0 15.9375V3.56245C0 2.42468 0.924774 1.50005 2.06255 1.50005H15.9375C17.0752 1.50005 18 2.42468 18 3.56245V15.9375C18 17.0752 17.0752 18 15.9375 18ZM2.06255 2.62505C1.54578 2.62505 1.125 3.04568 1.125 3.56245V15.9375C1.125 16.4542 1.54578 16.875 2.06255 16.875H15.9375C16.4542 16.875 16.875 16.4542 16.875 15.9375V3.56245C16.875 3.04568 16.4542 2.62505 15.9375 2.62505H2.06255Z"
          fill={isActive ? "#353B3F" : "#8F9EA9"}
        />
        <path
          d="M17.4375 7.12505H0.5625C0.251999 7.12505 0 6.87305 0 6.56255C0 6.25204 0.251999 6.00005 0.5625 6.00005H17.4375C17.748 6.00005 18 6.25204 18 6.56255C18 6.87305 17.748 7.12505 17.4375 7.12505Z"
          fill={isActive ? "#353B3F" : "#8F9EA9"}
        />
        <path
          d="M4.3125 4.5C4.002 4.5 3.75 4.248 3.75 3.9375V0.5625C3.75 0.251999 4.002 0 4.3125 0C4.623 0 4.875 0.251999 4.875 0.5625V3.9375C4.875 4.248 4.623 4.5 4.3125 4.5Z"
          fill={isActive ? "#353B3F" : "#8F9EA9"}
        />
        <path
          d="M13.6875 4.5C13.377 4.5 13.125 4.248 13.125 3.9375V0.5625C13.125 0.251999 13.377 0 13.6875 0C13.998 0 14.25 0.251999 14.25 0.5625V3.9375C14.25 4.248 13.998 4.5 13.6875 4.5Z"
          fill={isActive ? "#353B3F" : "#8F9EA9"}
        />
      </g>
      <defs>
        <clipPath id="clip0_378_1369">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const AddIcon: FC<ISVGIcon> = ({ isActive }) => {
  return (
    <svg
      className={style.img}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={isActive ? "#353B3F" : "#8F9EA9"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2957 2.07263H1.60853C1.18206 2.07312 0.773189 2.24276 0.471634 2.54433C0.170079 2.84591 0.000463173 3.25478 0 3.68126L0 14.3684C0.000479713 14.7949 0.170103 15.2038 0.471656 15.5053C0.773209 15.8069 1.18207 15.9765 1.60853 15.977H12.2957C12.7222 15.9765 13.1311 15.8069 13.4326 15.5053C13.7342 15.2038 13.9038 14.7949 13.9043 14.3684V3.68126C13.9038 3.25479 13.7342 2.84591 13.4326 2.54434C13.1311 2.24276 12.7222 2.07312 12.2957 2.07263ZM10.2752 9.46435H7.39169V12.3478C7.39169 12.4055 7.38032 12.4626 7.35823 12.516C7.33614 12.5693 7.30376 12.6178 7.26294 12.6586C7.22213 12.6994 7.17367 12.7318 7.12034 12.7539C7.06701 12.776 7.00985 12.7873 6.95213 12.7873C6.8944 12.7873 6.83724 12.776 6.78391 12.7539C6.73058 12.7318 6.68212 12.6994 6.64131 12.6586C6.60049 12.6178 6.56811 12.5693 6.54602 12.516C6.52393 12.4626 6.51256 12.4055 6.51256 12.3478V9.46435H3.62916C3.51258 9.46435 3.40077 9.41804 3.31834 9.33561C3.2359 9.25317 3.18959 9.14137 3.18959 9.02479C3.18959 8.90821 3.2359 8.7964 3.31834 8.71397C3.40077 8.63154 3.51258 8.58523 3.62916 8.58523H6.5125V5.70176C6.5125 5.58518 6.55881 5.47337 6.64124 5.39094C6.72368 5.30851 6.83548 5.2622 6.95206 5.2622C7.06864 5.2622 7.18045 5.30851 7.26288 5.39094C7.34531 5.47337 7.39162 5.58518 7.39162 5.70176V8.58523H10.2751C10.3917 8.58523 10.5035 8.63154 10.5859 8.71397C10.6683 8.7964 10.7147 8.90821 10.7147 9.02479C10.7147 9.14137 10.6683 9.25317 10.5859 9.33561C10.5035 9.41804 10.3917 9.46435 10.2751 9.46435H10.2752ZM16 1.63166V12.3188C15.9995 12.677 15.8796 13.0249 15.6593 13.3075C15.439 13.59 15.1307 13.791 14.7834 13.8788V3.68126C14.7826 3.02177 14.5203 2.38952 14.0539 1.9232C13.5876 1.45687 12.9554 1.19454 12.2959 1.19376H2.15687C2.25257 0.856998 2.45536 0.560576 2.73456 0.349353C3.01375 0.13813 3.35416 0.0236016 3.70425 0.0231018H14.3915C14.8179 0.0235898 15.2268 0.193221 15.5283 0.49478C15.8299 0.796339 15.9995 1.2052 16 1.63166Z"
        fill={isActive ? "#353B3F" : "#8F9EA9"}
      />
    </svg>
  );
};

const Tab: FC<ITab> = ({
  icon,
  isActive,
  isTabActive,
  onClick,
  tabIndex,
  title,
}) => {
  const setActiveTab = () => {
    if (!isActive) {
      return;
    }
    onClick(tabIndex);
  };

  const generateIcon = (iconName: string) => {
    switch (iconName) {
      case "allertSettingsIcon": {
        return <AllertSettingsIcon isActive={isTabActive} />;
      }
      case "futureTabIcon": {
        return <CalendarIcon isActive={isTabActive} />;
      }
      case "addIcon": {
        return <AddIcon isActive={isTabActive} />;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div
      className={style.wrapper}
      onClick={setActiveTab}
      style={{
        cursor: isActive ? "pointer" : "",
      }}
    >
      <div className={style.textWrapper}>
        {/* <img src={} alt="icon" className={style.img} /> */}
        {generateIcon(icon)}
        <div className={isTabActive ? style.activeTab : style.inactiveTab}>
          {title}
        </div>
      </div>
      {isTabActive ? <img src={portalSettingsTabIcon} alt="tab" /> : null}
    </div>
  );
};

export default Tab;
