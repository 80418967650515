import { Form, Formik } from "formik";
import React, { FC } from "react";
import { IArticleBody } from "../../../../../../api/news/news.api";
import {
  Button,
  CustomInput,
  RichTextEditor,
} from "../../../../../../components";
import { useAppDispatch } from "../../../../../../store";
import { updateArticle } from "../../../../../../store/slices/news";
import appConfig from "../../../../../../util/appConfig";

interface IUpdateArticleForm {
  closeModalHandler: () => void;
  article: IArticleBody;
}

const UpdateArticleForm: FC<IUpdateArticleForm> = ({
  closeModalHandler,
  article,
}) => {
  const dispatch = useAppDispatch();
  const initialValues = {
    title: article.title,
    articleText: article.text,
    imgSrc: article.imgSrc,
    shortDescription: article.shortDescription,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        dispatch(
          updateArticle({
            id: article._id,
            body: {
              imgSrc: values.imgSrc,
              shortDescription: values.shortDescription,
              title: values.title,
              text: values.articleText,
            },
          })
        );
        resetForm();
        closeModalHandler();
      }}
    >
      {(props) => {
        return (
          <Form>
            <div>
              <CustomInput
                name={"title"}
                type={"text"}
                label={"Titel"}
                hasFullWidth={true}
              />
              <CustomInput
                name={"imgSrc"}
                type={"text"}
                label={"Image link"}
                hasFullWidth={true}
              />
              <CustomInput
                name={"shortDescription"}
                type={"text"}
                label={"Short description"}
                hasFullWidth={true}
              />
              <RichTextEditor
                name={"articleText"}
                type={"text"}
                label={"Article text"}
              />
            </div>
            <Button
              backgroundColor={appConfig.mainColor}
              text="Submit"
              width="250"
              type="submit"
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateArticleForm;
