import { IObjectUserResponse } from "../../store/slices/user";
import { userToken } from "../../util/userToken";
import { instance } from "../api";

interface IUser {
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  postCode: string;
  email: string;
}

const createUser = (email: string, password: string) => {
  return instance
    .post("/registration", {
      email,
      password,
    })
    .then((res) => res.data);
};

const login = (email: string, password: string) => {
  return instance
    .post<{ token: string }>("/login", {
      email,
      password,
    })
    .then((res) => res.data);
};

const logout = () => {
  return instance.delete("/logout").then(() => {
    userToken.remove();
  });
};

const nrwLogin = (code: string) => {
  return instance
    .post<IUser | { message: string }>("/login/nrw", { code })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const loginViersen = ({
  login,
  password,
}: {
  login: string;
  password: string;
}) => {
  return instance
    .post<{ token: string }>("/login/viersen", {
      login,
      password,
    })
    .then((res) => res.data);
};

const objectIdLogin = (data: { objectId: string }) => {
  return instance.post<IObjectUserResponse>("login/object-id", data).then((res) => res.data);
};
const me = (token: string) => {
  return instance.get<IObjectUserResponse>(`login/me?token=${token}`).then((res) => res.data);
};

const Auth = {
  post: {
    createUser,
    login,
    nrwLogin,
    loginViersen,
    objectIdLogin,
    me,
  },
  delete: {
    logout,
  },
};

export default Auth;
