import { adminInstance, instance } from "../api";

// export interface INews {
//   src: string;
//   title: string;
//   date: string;
//   description: string;
//   id: string;
// }

export interface ICreateArticleBody {
  title: string;
  shortDescription: string;
  text: string;
  imgSrc: string;
}

export interface IArticleBody extends ICreateArticleBody {
  _id: string;
  date: string;
}

const getNewsData = () => {
  return instance
    .get<{ count: number; news: IArticleBody[] }>("/news-data")
    .then((res) => res.data);
};

const getNews = (page: number) => {
  return instance
    .get<{ news: IArticleBody[] }>(`/news?page=${page}`)
    .then((res) => res.data);
};

const getArticleById = (id: string) => {
  return instance.get<IArticleBody>(`/news/${id}`).then((res) => res.data);
};

const deleteArticles = (ids: string[]) => {
  return adminInstance
    .delete(`/admin/articles?ids=${ids}`)
    .then((res) => res.data);
};

const createArticle = (data: ICreateArticleBody) => {
  return adminInstance
    .post<IArticleBody>(`/admin/articles/create`, data)
    .then((res) => res.data);
};
const updateArticle = (id: string, data: ICreateArticleBody) => {
  return adminInstance
    .put<IArticleBody>(`/admin/articles/${id}`, data)
    .then((res) => res.data);
};

const News = {
  get: {
    getNewsData,
    getNews,
    getArticleById,
  },
  post: {
    createArticle,
  },
  put: {
    updateArticle,
  },
  delete: {
    deleteArticles,
  },
};

export default News;
