import React, { FC } from "react";
import appConfig from "../../../../util/appConfig";
import appointmentCheckMark from "../../../../assets/images/appointmentCheckMark.svg";
import style from "./style.module.scss";

interface IStep {
  step: number;
  title: string;
  stepNumber: number;
  width: number;
  isFirstElement: boolean;
  isLastElement: boolean;
  withCheckMark?: boolean;
  withMobileHeaderTitle?: boolean;
  revertStepperColor?: boolean;
}

const Step: FC<IStep> = ({
  step,
  title,
  stepNumber,
  width,
  isFirstElement,
  withCheckMark = false,
  withMobileHeaderTitle = true,
  isLastElement,
  revertStepperColor = false,
}) => {
  return (
    <div
      className={`${style.wrapper} ${
        stepNumber === step ? style.activeMobileStep : ""
      }`}
      style={{
        width: `${width}%`,
      }}
    >
      {withMobileHeaderTitle ? (
        <>
          <div className={style.title_wrapper}>
            <div
              className={`${style.step} ${
                step !== 1 && stepNumber < step && style.step_inactive
              }`}
              style={{
                border: `1px solid ${
                  !revertStepperColor
                    ? appConfig.mainColor
                    : appConfig.secondaryColor
                }`,
                color: !revertStepperColor
                  ? appConfig.mainColor
                  : appConfig.secondaryColor,
              }}
            >
              {withCheckMark && step < stepNumber ? (
                <img src={appointmentCheckMark} alt={step.toString()} />
              ) : (
                step
              )}
            </div>
            <div
              className={`${style.title} ${
                step !== 1 && stepNumber < step && style.title_inactive
              }`}
              style={{
                color: !revertStepperColor
                  ? appConfig.mainColor
                  : appConfig.secondaryColor,
              }}
            >
              {title}
            </div>
          </div>
        </>
      ) : null}
      <div
        className={`${style.border} 
        ${step !== 1 && stepNumber < step && style.border_inactive} 
        ${withMobileHeaderTitle && style.withBorderRadius}
        ${!withMobileHeaderTitle && step === 1 && style.firstElement}  
        ${
          !withMobileHeaderTitle &&
          stepNumber === step &&
          style.lastActiveElement
        } 
        ${
          !withMobileHeaderTitle &&
          stepNumber + 1 === step &&
          style.firstInactiveElement
        }
        ${!withMobileHeaderTitle && isLastElement && style.lastElement}`}
        style={{
          backgroundColor: !revertStepperColor
            ? appConfig.mainColor
            : appConfig.secondaryColor,
        }}
      ></div>
    </div>
  );
};

export default Step;
