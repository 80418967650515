import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { statuses } from "../../../../AdminSperrmuelanmeldungen";
import { multiply, add } from "lodash";
import style from "./style.module.scss";
import { Button } from "../../../../../components";
import {
  clearStucklisten,
  confirmSperrmuell,
  deleteSperrmuellById,
  getStuecklisten,
} from "../../../../../store/slices/sperrmuell";
import appConfig from "../../../../../util/appConfig";

const Lra = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { role } = useAppSelector((state) => state.admin);
  const sperrmuelItemData = useAppSelector(
    (state) => state.sperrmuell.admin.sperrmuelItemInfo
  );

  const { stuecklisten } = useAppSelector((state) => state.sperrmuell.client);

  useEffect(() => {
    dispatch(getStuecklisten({ gemeindeNummer: 1111, leistungNr: 10 }));

    return () => {
      dispatch(clearStucklisten());
    };
  }, []);

  return (
    <>
      <div className={style.title}>Sperrmüll Information</div>
      <div className={style.tableInfoWrapper}>
        {sperrmuelItemData?.status ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Sperrmüllstatus</div>
            <div className={style.rowInfo}>
              {statuses[sperrmuelItemData?.status]}
            </div>
          </div>
        ) : null}
        {sperrmuelItemData?.status === "check" &&
        sperrmuelItemData?.checkReason ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Grund für Prüfing</div>
            <div className={style.rowInfo}>{sperrmuelItemData.checkReason}</div>
          </div>
        ) : null}
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Sperrmülltyp</div>
          <div className={style.rowInfo}>
            <div>
              {sperrmuelItemData?.trashTypeData?.map((i, idx) => {
                return (
                  <div className={style.trashTypeWrapper} key={idx}>
                    <div className={style.trashTypeTitle}>
                      Name: {i.name}; <br />
                      Quantität: {i.value}; <br />
                      Gesamtvolumen:{" "}
                      {multiply(
                        stuecklisten.find((el) => el.abfallnr === i.abfallNr)
                          ?.faktor!,
                        +i.value
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={style.lastRowWrapper}>
          <div className={style.rowTitle}>Gesamt Sperrmüllvolumen</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.trashVolume?.toFixed(2)}
          </div>
        </div>
      </div>
      {sperrmuelItemData?.errorReason ? (
        <>
          <div className={style.title}>Grund des Fehlers</div>
          <div className={style.tableInfoWrapper}>
            <div
              className={style.rowWrapper}
              style={{
                borderBottom: "none",
              }}
            >
              <div className={style.rowTitle}>Fehler</div>
              <div className={style.rowInfo}>
                {sperrmuelItemData?.errorReason}
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: role === "SUPERADMIN" ? "space-between" : "flex-end",
        }}
      >
        {role === "SUPERADMIN" ? (
          <Button
            text="Löschen"
            width="200"
            backgroundColor="white"
            isOutlined
            outlineColor={"red"}
            textColor="red"
            onClick={() => {
              if (itemId) {
                dispatch(
                  deleteSperrmuellById({
                    id: itemId,
                    navigate: () => {
                      navigate("/admin/sperrmuell-anmeldungen");
                    },
                  })
                );
              }
            }}
          />
        ) : null}
        {sperrmuelItemData?.status === "check" && sperrmuelItemData ? (
          <div
            style={{
              // textAlign: "end",
              display: "flex",
            }}
          >
            <Button
              text="Akzeptieren"
              width="200"
              backgroundColor={appConfig.mainColor}
              marginRight="20"
              onClick={() => {
                dispatch(
                  confirmSperrmuell({
                    id: sperrmuelItemData.id,
                    accepted: true,
                  })
                );
              }}
            />
            <Button
              text="Stornieren"
              width="200"
              backgroundColor="white"
              isOutlined
              outlineColor={"red"}
              textColor="red"
              onClick={() => {
                dispatch(
                  confirmSperrmuell({
                    id: sperrmuelItemData.id,
                    accepted: false,
                  })
                );
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Lra;
