import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
} from "../../components";
import style from "./style.module.scss";
import {
  clearAdminBehaeltergemeinschaftItem,
  getAdminBehaeltergemeinschaftItemById,
  updateAdminBehaeltergemeinschaftItemById,
} from "../../store/slices/behaeltergemeinschaft";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import appConfig from "../../util/appConfig";
import History from "../AdminSperrmuelanmeldungInfo/components/ContentComponent/DetailInfo/history";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "In Arbeit",
};

const AdminBehaeltergemeinschaftInfoById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { behaeltergemeinschaftItem, isBehaeltergemeinschaftItemDataLoading } =
    useAppSelector((state) => state.behaeltergemeinschaft.admin);

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminBehaeltergemeinschaftItemById(itemId));
    }
    return () => {
      dispatch(clearAdminBehaeltergemeinschaftItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        {isBehaeltergemeinschaftItemDataLoading ? null : !behaeltergemeinschaftItem ? null : (
          <div>
            <AdminDetailsPageItemName text="Detailinformationen" />
            <div>
              <div>
                <div className={style.title}>Detailinformation</div>
                <div className={style.tableInfoWrapper}>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Status</div>
                    <div className={style.rowInfo}>
                      {statuses[behaeltergemeinschaftItem?.status]}
                    </div>
                  </div>
                  <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Datum (Meldung)</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem.createdAt
                        ? format(
                            new Date(behaeltergemeinschaftItem.createdAt),
                            "dd.MM.yyyy, hh:mm",
                            {
                              locale: de,
                            }
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.title}>
                  Tonnenbesitzer/Rechnugnsempfänger
                </div>
                <div className={style.tableInfoWrapper}>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Name</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.ownerSurname}{" "}
                      {behaeltergemeinschaftItem?.ownerName}
                    </div>
                  </div>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Straße</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.ownerStreet},{" "}
                      {behaeltergemeinschaftItem?.ownerHomeNumber}{" "}
                      {behaeltergemeinschaftItem?.ownerHomeNumberZusatz
                        ? `, ${behaeltergemeinschaftItem?.ownerHomeNumberZusatz}`
                        : ""}
                    </div>
                  </div>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Ort</div>
                    <div className={style.rowInfo}>
                      {/* {behaeltergemeinschaftItem?.ownerPlace},{" "} */}
                      {behaeltergemeinschaftItem?.ownerPlacePart},{" "}
                      {behaeltergemeinschaftItem?.ownerPostcode},{" "}
                    </div>
                  </div>
                  <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Buchungsbezeichen</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.ownerBookingCode}
                    </div>
                  </div>
                  {/* <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Tonnenart</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.ownerTrashBinData.map(
                        (el, idx) => {
                          return (
                            <div
                              key={el.binNumber}
                              style={{
                                marginBottom:
                                  behaeltergemeinschaftItem?.ownerTrashBinData
                                    .length ===
                                  idx - 1
                                    ? ""
                                    : "10px",
                              }}
                            >
                              <div>Tonne: {el.bin}</div>
                              <div>Tonnenummer: {el.binNumber}</div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
              <div>
                <div className={style.title}>Tonnen-Mitnutzer</div>
                <div className={style.tableInfoWrapper}>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Name</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.couserSurname}{" "}
                      {behaeltergemeinschaftItem?.couserName}
                    </div>
                  </div>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Straße</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.couserStreet},{" "}
                      {behaeltergemeinschaftItem?.couserHomeNumber}{" "}
                      {behaeltergemeinschaftItem?.couserHomeNumberZusatz
                        ? `, ${behaeltergemeinschaftItem?.couserHomeNumberZusatz}`
                        : ""}
                    </div>
                  </div>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Ort</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.couserPlacePart}
                      {behaeltergemeinschaftItem?.couserPostcode}
                    </div>
                  </div>
                  <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Buchungsbezeichen</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.couserBookingCode
                        ? behaeltergemeinschaftItem?.couserBookingCode
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.title}>Andere Info</div>
                <div className={style.tableInfoWrapper}>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Typ</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem?.type
                        ? behaeltergemeinschaftItem?.type
                        : ""}
                    </div>
                  </div>
                  <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Tonne</div>
                    <div className={style.rowInfo}>
                      {behaeltergemeinschaftItem.ownerTrashBinData.map((el) => {
                        return (
                          <div>
                            {el.bin}, Nr. {el.binNumber}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {behaeltergemeinschaftItem?.history?.length ? (
                <History history={behaeltergemeinschaftItem?.history} />
              ) : null}
            </div>
            <div
              style={{
                // textAlign: "end",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                text="Offen"
                width="200"
                // backgroundColor={appConfig.secondaryColor}
                isOutlined
                outlineColor={appConfig.secondaryColor || appConfig.mainColor}
                textColor={appConfig.secondaryColor || appConfig.mainColor}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminBehaeltergemeinschaftItemById({
                        id: itemId,
                        status: "inWork",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Akzeptieren"
                width="200"
                backgroundColor={appConfig.mainColor}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminBehaeltergemeinschaftItemById({
                        id: itemId,
                        status: "accepted",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={"red"}
                textColor="red"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminBehaeltergemeinschaftItemById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminBehaeltergemeinschaftInfoById;
