import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import sensisLogo from "../../assets/images/logos/sensisLogo.svg";
import awlLogoNew from "../../assets/images/logos/awlLogoNew.png";
import bkLogo from "../../assets/images/logos/bkLogo.svg";
import { userToken } from "../../util/userToken";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";
import { useAppSelector } from "../../store";

const LogoutSvg = () => {
  return (
    <svg
      className={style.logoutIcon}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1124_15592)">
        <path
          d="M12 0C5.38317 0 0 5.38317 0 12C0 18.6168 5.38317 24 12 24C18.6168 24 24 18.6168 24 12C24 5.38317 18.6168 0 12 0ZM15.8775 16.6425C14.6275 17.9066 12.9603 18.6028 11.183 18.6028C7.54219 18.6028 4.58016 15.6408 4.58016 12C4.58016 8.35922 7.54219 5.39723 11.183 5.39723C12.9603 5.39723 14.6274 6.09337 15.8774 7.35741C16.0958 7.57828 16.0939 7.93444 15.873 8.15287C15.6521 8.37131 15.2959 8.36934 15.0775 8.14842C14.0405 7.09973 12.6574 6.52223 11.183 6.52223C8.16249 6.52223 5.7052 8.97956 5.7052 12C5.7052 15.0204 8.16253 17.4778 11.183 17.4778C12.6574 17.4778 14.0405 16.9002 15.0775 15.8515C15.2959 15.6307 15.6521 15.6286 15.873 15.8471C16.0939 16.0655 16.0959 16.4217 15.8775 16.6425ZM19.255 12.3977L17.283 14.3698C17.1731 14.4796 17.0292 14.5346 16.8852 14.5346C16.7413 14.5346 16.5973 14.4797 16.4875 14.3698C16.2678 14.1502 16.2678 13.794 16.4875 13.5743L17.4993 12.5625H11.183C10.8723 12.5625 10.6205 12.3106 10.6205 12C10.6205 11.6894 10.8723 11.4375 11.183 11.4375H17.4993L16.4875 10.4257C16.2678 10.206 16.2678 9.84989 16.4875 9.63019C16.7072 9.41048 17.0633 9.41048 17.283 9.63019L19.2551 11.6022C19.3605 11.7077 19.4198 11.8508 19.4198 12C19.4198 12.1491 19.3605 12.2923 19.255 12.3977Z"
          fill={appConfig.mainColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1124_15592">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Sidebar = () => {
  const navigate = useNavigate();

  const { role } = useAppSelector((state) => state.admin);
  const linkItems = [
    {
      groupName: "Allgemeine Einstellungen",
      linkNames: [
        {
          title: "Portal Einstellungen",
          link: "/admin/portal-settings",
          isLinkActive: true,
        },
        //TODO: comment email tracking
        {
          title: "EMail Tracking",
          link: "/admin/mail-tracking",
          isLinkActive: !['awl'].includes(appConfig.logo),
        },
      ],
    },
    {
      groupName: "Sperrmüll",
      linkNames: [
        {
          title: "Sperrmüllanmeldungen",
          link: "/admin/sperrmuell-anmeldungen",
          isLinkActive: true,
        },
        {
          title: "Kalender Daten",
          link: "/admin/calendar-data",
          isLinkActive: false,
        },
        {
          title: "Kalender Daten",
          link: "/admin/calendar-data",
          isLinkActive: false,
        },
        {
          title: "Standorte",
          link: "/admin/schadstoffmobil/standorte",
          isLinkActive: false,
        },
        {
          title: "Standorte",
          link: "/admin/schadstoffmobil/standorte",
          isLinkActive: false,
        },
        {
          title: "City parts",
          link: "/admin/city-parts",
          isLinkActive: !["bk", "awl", "ebwo", "lra", "calw"].includes(
            appConfig.logo
          ),
        },
        {
          title: "Articles",
          link: "/admin/articles",
          isLinkActive: appConfig.logo === "sensis" ? true : false,
        },
        {
          title: "Sperrmüll Blackliste",
          link: "/admin/blocked-streets",
          isLinkActive:
            appConfig.logo === "bk" ||
            appConfig.logo === "awl" ||
            appConfig.logo === "sensis" ||
            (appConfig.logo === "ebwo" && role === "SUPERADMIN") ||
            (appConfig.logo === "lra" && role === "SUPERADMIN")
              ? true
              : false,
        },
        {
          title: "Sperrmüll Weiße Liste",
          link: "/admin/white-list-streets",
          isLinkActive:
            ["ebwo", "lra"].includes(appConfig.logo) && role === "SUPERADMIN"
              ? true
              : false,
        },
        {
          title: "Sperrmüll Graue Liste",
          link: "/admin/grey-list-streets",
          isLinkActive:
            ["ebwo", "lra"].includes(appConfig.logo) && role === "SUPERADMIN"
              ? true
              : false,
        },
        {
          title: "Reklamation",
          link: "/admin/reclamation",
          isLinkActive: ["calw"].includes(appConfig.logo),
        },
        {
          title: "Behältergemeinschaft",
          link: "/admin/behaeltergemeinschaft",
          isLinkActive: ["calw"].includes(appConfig.logo),
        },
        {
          title: "Eigenkompostierer",
          link: "/admin/eigenkompostierer",
          isLinkActive: ["calw"].includes(appConfig.logo),
        },
      ],
    },
    {
      groupName: "Technische Einstellungen",
      linkNames: [
        {
          title: "API Token",
          link: "/admin/create-token",
          isLinkActive: true,
        },
      ],
    },
  ];

  const removeToken = () => {
    userToken.removeAdminToken();
    navigate("/admin");
  };

  const genarateLogo = (type: string) => {
    switch (type) {
      case "awl": {
        return awlLogoNew;
      }
      case "bk": {
        return bkLogo;
      }
      default: {
        return sensisLogo;
      }
    }
  };

  return (
    <div className={style.wrapper}>
      <div>
        <div style={{ textAlign: "center" }}>
          <img
            src={genarateLogo(appConfig.logo)}
            alt="logo"
            className={`${style.logo} ${
              appConfig.logo === "awl" ? style.awlLogo : ""
            }`}
          />
        </div>
        <nav className={style.navigation}>
          {linkItems.map((item) => {
            return (
              <div style={{ marginBottom: "54px" }} key={item.groupName}>
                <div className={style.groupTitle}>{item.groupName}</div>
                <div>
                  {item.linkNames
                    .filter((i) => i.isLinkActive)
                    .map((link) => {
                      return (
                        <NavLink
                          to={link.link}
                          className={style.link}
                          style={({ isActive }) => {
                            return {
                              backgroundColor: isActive
                                ? transformHexToRgba(appConfig.mainColor, "0.1")
                                : "",
                              color: isActive ? appConfig.mainColor : "",
                              fontWeight: isActive ? 700 : "",
                            };
                          }}
                          key={link.title}
                        >
                          {link.title}
                        </NavLink>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </nav>
      </div>
      <div className={style.logoutBtn} onClick={removeToken}>
        <LogoutSvg />
        Abmelden
      </div>
    </div>
  );
};

export default Sidebar;
