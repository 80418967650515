
import React, { useEffect, useState } from "react";
import { AdminContentWrapper, AdminHeader, Loader } from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  clearData,
  getPortalSettingsData,
} from "../../store/slices/app";
import ContentComponent from "./components/ContentComponent";
import ContentTabs from "./components/ContentTabs";
import { setDataLoading } from "../../store/slices/admin";

const AdminPortalSettings = () => {
  const dispatch = useAppDispatch();
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isSettingsContentLoaded = useAppSelector(
    (state) => state.app.admin.isSettingsContentLoaded
  );
  const [tabIndex, setTabIndex] = useState(0);

  const setTab = (idx: number) => {
    setTabIndex(idx);
  };

  useEffect(() => {
    dispatch(getPortalSettingsData());

    return () => {
      dispatch(clearData());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader pageTitle={"Portal Einstellungen"} hasBtnGroup={false} />
      <AdminContentWrapper
        style={{
          padding: 0,
          display: "flex",
        }}
      >
        {isSettingsContentLoaded ? (
          <>
            <ContentTabs setTab={setTab} tabIndex={tabIndex} />

            <ContentComponent tabIndex={tabIndex} />
          </>
        ) : null}
        {/* <div className={style.contentWrapper}>
          <div>
            Main status: {newSperrmuellScheduleStatus ? "active" : "inactive"}
          </div>
        </div>
        <div className={style.contentWrapper}>
          <div>Titel: {newSperrmuellScheduleTitle}</div>
        </div>
        <div className={style.contentWrapper}>
          <div>Subtitle: {newSperrmuellScheduleSubtitle}</div>
        </div>
        <div className={style.contentWrapper}>
          <div>URL: {newSperrmuellScheduleURL}</div>
        </div>
        <div className={style.contentWrapper}>
          <div>BCC E-Mail Adresse: {adminBCCEMailAddress}</div>
        </div>
        <div>
          <div>Holidays:</div>
          <li style={{
                    listStyleType: "none",
                  }}>
            {holidays.map((i, idx) => {
              return (
                <ul
                  key={idx}
                  style={{
                    listStyleType: "none",
                    margin: '3px 0',
                    padding: '0 10px'
                  }}
                >
                  - {format(new Date(i), "dd. MMMM yyyy", {
                    locale: de,
                  })}
                </ul>
              );
            })}
          </li>
        </div>
        <Button
          text={"Update"}
          width={"150"}
          backgroundColor={appConfig.mainColor}
          onClick={openModalHandler}
        /> */}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminPortalSettings;
