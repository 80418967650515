import React, { FC, MouseEvent } from "react";
import { ISperrmuel } from "../../../../api/spermuell/spermuell.api";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { Button } from "../../../../components";
import { useAppDispatch } from "../../../../store";
import { setIsConfirmationPopupActive } from "../../../../store/slices/app";
import appConfig from "../../../../util/appConfig";
import style from "./style.module.scss";

interface IModal {
  item: ISperrmuel;
}

const Modal: FC<IModal> = ({ item }) => {
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(setIsConfirmationPopupActive(false));
  };
  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    >
      <div className={style.headerWrapper}>
        <div className={style.title}>Sperrmüll-Anmeldung Information</div>
        <img
          src={closeIcon}
          alt="close"
          onClick={closeModalHandler}
          className={style.img}
        />
      </div>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <div>
          Name: {item.userName} {item.userSurname}
        </div>
        <div>Telefonnummer{item.phoneNumber}</div>
        {item.mobilePhoneNumber ? (
          <div>Mobilenummer{item.mobilePhoneNumber}</div>
        ) : null}
        <div>
          Was zu abholen:
          <ul
            style={{
              marginLeft: "20px",
            }}
          >
            {item.pickUpTrash.map((i) => {
              return (
                <li key={i.trashType}>
                  {i.trashType} = {i.date}
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          Adresse: {item.postcode}, {item.street}
        </div>
        {item.message ? <div>Nachricht: {item.message}</div> : null}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          text="Anmeldung ablehnen"
          textColor="#DE3745"
          isOutlined={true}
          width="172"
          outlineColor="#DE3745"
          onClick={closeModalHandler}
        />
        <Button
          text="Anmeldung ablehnen"
          backgroundColor={appConfig.mainColor}
          width="172"
          onClick={closeModalHandler}
        />
      </div>
    </div>
  );
};

export default Modal;
