import React, { FC } from "react";
import { AdminCheckbox, TableGrid } from "../../../../components";
import { useAppSelector } from "../../../../store";
import SearchInput from "./components/SearchInput";
import {
  GridColumn,
  GridCellProps,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { SortDescriptor } from "@progress/kendo-data-query";

interface IContentComponent {
  areAllItemsSelected: boolean;
  selectAllItemsHandler: () => void;
  selectedItems: string[];
  selectItemHandler: (id: string) => void;
  // direction: null | "down" | "up";
  // directionHandler: () => void;
  sort: SortDescriptor[];
  setSort: any;
  value: string;
  changeHandler: (text: string) => void
}

const ContentComponent: FC<IContentComponent> = ({
  areAllItemsSelected,
  selectAllItemsHandler,
  selectedItems,
  selectItemHandler,
  sort,
  setSort,
  // direction,
  // directionHandler,
  changeHandler, value
}) => {
  
  const tokens = useAppSelector((state) => state.token.admin.tokens);
  
  const FirstItem = (props: GridCellProps) => {
    return (
      <td
        // className="k-command-cell"
        style={
          {
            // backgroundColor: 'white',
          }
        }
      >
        <AdminCheckbox
          checked={selectedItems.includes(props.dataItem.id)}
          changeHandler={() => {
            selectItemHandler(props.dataItem.id);
          }}
        />
      </td>
    );
  };
  const FirstHeaderItem = (props: GridHeaderCellProps) => {
    return (
      <div
        style={{
          margin: "0",
          padding: "0",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          // border: "none",
          // backgroundColor: "rgba(0, 0, 0, 0.02) !important",
        }}
        // className={style.qwe}
      >
        <AdminCheckbox
          checked={areAllItemsSelected}
          changeHandler={selectAllItemsHandler}
        />
      </div>
    );
  };

  return (
    <div>
      <SearchInput value={value} changeHandler={changeHandler} />
      <div>
        <TableGrid
          data={tokens.map((i) => {
            return {
              ...i,
              isIncluded: selectedItems.includes(i.id) || areAllItemsSelected,
            };
          })}
          items={[
            {
              title: "Name",
              field: "name",
              headerCell: (props: GridHeaderCellProps) => (
                <th data-my-attribute="my-value" {...props}>
                  {props.title}
                </th>
              ),
            },
            {
              title: "Benutzer E-Mail",
              field: "email",
              headerCell: (props: GridHeaderCellProps) => (
                <th data-my-attribute="my-value" {...props}>
                  {props.title}
                </th>
              ),
            },
          ]}
          sortable={true}
          sort={sort}
          onSortChange={setSort}
          firstItem={
            <GridColumn
              cell={FirstItem}
              width="40px"
              headerCell={FirstHeaderItem}
            />
          }
        />
        {/* <HeaderTitle
          changeHandler={selectAllItemsHandler}
          checked={areAllItemsSelected}
          direction={direction}
          directionHandler={directionHandler}
        />
        {tokens.map((token) => {
          return (
            <Item
              item={token}
              key={token.id}
              checked={selectedItems.includes(token.id)}
              changeHandler={selectItemHandler}
            />
          );
        })} */}
      </div>
    </div>
  );
};

export default ContentComponent;
