import { adminInstance, instance } from "../api";

const getPortalSettings = () => {
  return instance
    .get<{
      status: boolean;
      title: string;
      subtitle: string;
      url: string;
      bccEMailAddress: string;
      hints: {
        step: number;
        hints: string[];
        isWarningMessage: boolean;
      }[];
    }>(`/portal-settings`)
    .then((res) => res.data);
};

const getHintsText = () => {
  return adminInstance
    .get<{ step: number; hints: string[]; isWarningMessage: boolean }[]>(
      "/admin/hints"
    )
    .then((res) => res.data);
};
const updateHintsText = (data: { step: number; hints: string[] }[]) => {
  return adminInstance
    .post<{ step: number; hints: string[]; isWarningMessage: boolean }[]>(
      "/admin/hints",
      data
    )
    .then((res) => res.data);
};

const Portal = {
  get: {
    getPortalSettings,
    getHintsText,
  },
  post: {
    updateHintsText,
  },
};

export default Portal;
