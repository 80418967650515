import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  DeleteConfirmationModal,
  Loader,
  ModalWrapper,
} from "../../components";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import Modal from "./components/Modal";
import ContentComponent from "./components/ContentComponent";
import {
  clearAdminToken,
  clearTokens,
  deleteAdminToken,
  getAdminTokens,
  getAdminTokensSearch,
} from "../../store/slices/token";
import { SortDescriptor } from "@progress/kendo-data-query";
import { setDataLoading } from "../../store/slices/admin";

const AdminToken = () => {
  const dispatch = useAppDispatch();
  const tokens = useAppSelector((state) => state.token.admin.tokens);
  const adminToken = useAppSelector((state) => state.token.admin.adminToken);
  const isTokenDataLoading = useAppSelector(
    (state) => state.token.admin.isTokenDataLoading
  );
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  // const [direction, setDirection] = useState<null | "up" | "down">(null);
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [isDeleteConfirmationModalActive, setIsDeleteConfirmationModalActive] =
    useState(false);
  const [value, setValue] = useState("");
  const changeHandler = (val: string) => {
    setValue(val);
  };

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = tokens.map((i) => i.id);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const openDeleteConfirmationModalHandler = () => {
    if (selectedItems.length) {
      dispatch(setIsConfirmationPopupActive(true));
      setIsDeleteConfirmationModalActive(true);
    }
  };

  const clearSelectedItems = () => {
    if (selectedItems.length) {
      dispatch(deleteAdminToken(selectedItems));
      dispatch(setIsConfirmationPopupActive(false));
      setSelectedItems([]);
      setIsDeleteConfirmationModalActive(false);
    }
  };

  useEffect(() => {
    dispatch(
      getAdminTokens({
        direction: undefined,
        sort: "",
        searchValue: undefined,
      })
    );
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getAdminTokensSearch({
  //       sort: sort.length && sort[0].field ? sort[0].field : "",
  //       direction: sort.length && sort[0].dir ? sort[0].dir : undefined,
  //       searchValue: value.length ? value : undefined,
  //     })
  //   );
  // }, [sort]);

  useEffect(() => {
    const t = setTimeout(() => {
      dispatch(
        getAdminTokensSearch({
          sort: sort.length && sort[0].field ? sort[0].field : "",
          direction: sort.length && sort[0].dir ? sort[0].dir : undefined,
          searchValue: value.length ? value : undefined,
        })
      );
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [value, sort]);

  useEffect(() => {
    if (!tokens.length) return;
    if (selectedItems.length === tokens.length) {
      setAllItemsSelected(true);
    } else {
      setAllItemsSelected(false);
    }
  }, [selectedItems.length, tokens.length]);

  useEffect(() => {
    return () => {
      dispatch(clearTokens());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        clearSelectedItems={openDeleteConfirmationModalHandler}
        hasItemsLength={!!tokens.length}
        hasSelectedItems={!!selectedItems.length}
        pageTitle={"Admin Token"}
        hasBtnGroup={true}
        btnGroupAddItemText={"API Key hinzufügen"}
        btnGroupRemoveItemsText={"API Key entfernen"}
      />
      <AdminContentWrapper>
        {isTokenDataLoading ? null : !tokens.length ? (
          <AdminPlaceholder
            title="No Admin Token Yet"
            subTitle="Start working with the admin panel now. You can add a token by clicking
          the button below."
            hasBtn={true}
            btnText={"API Key hinzufügen"}
          />
        ) : (
          <ContentComponent
            areAllItemsSelected={allItemsSelected}
            selectAllItemsHandler={allItemsSelectHandler}
            selectedItems={selectedItems}
            selectItemHandler={selectItemHandler}
            sort={sort}
            setSort={setSort}
            // direction={direction}
            // directionHandler={directionHandler}
            value={value}
            changeHandler={changeHandler}
          />
        )}
      </AdminContentWrapper>
      {isModalActive ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            if (adminToken) {
              dispatch(clearAdminToken());
            }
            setIsDeleteConfirmationModalActive(false);
          }}
        >
          {isDeleteConfirmationModalActive ? (
            <DeleteConfirmationModal removeHandler={clearSelectedItems} />
          ) : (
            <Modal />
          )}
        </ModalWrapper>
      ) : null}
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminToken;
