import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setErrorMessage,
  setIsConfirmationPopupActive,
  setIsLoading,
  setIsToasterError,
  setIsToasterSuccess,
  setSuccessMessage,
} from "./app";
import API from "../../api/api";

export interface IBehaelterverwaltungState {
  client: {
    behaelterData: {
      type: string;
      fraction: string;
      volume: string;
      tour: string;
      id: string;
    }[];
  };
}

const initialState: IBehaelterverwaltungState = {
  client: {
    behaelterData: [],
  },
};

export const getBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/getBehaelter",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.get.getBehaelter();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const addBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/addBehaelter",
  async (
    {
      quantity,
      type,
      volume,
      additionalBin,
      additionalBinVolume,
    }: {
      type: string;
      volume: string;
      quantity: number;
      additionalBin?: boolean;
      additionalBinVolume?: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));

      const data = await API.behaelterverwaltung.post.addBehaelter({
        quantity,
        type,
        volume,
        additionalBin,
        additionalBinVolume,
      });

      dispatch(setIsConfirmationPopupActive(false))

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const defectBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/defectBehaelter",
  async (
    {
      id,
      reason,
    }: {
      id: string;
      reason: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.post.defectBehaelter({
        id,
        reason,
      });

      if (data.message) {
        dispatch(setIsToasterSuccess(true));
        dispatch(setSuccessMessage(data.message));
      }
      dispatch(setIsConfirmationPopupActive(false))
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const disappearedBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/disappearedBehaelter",
  async (
    {
      id,
      disappeared,
    }: {
      id: string;
      disappeared: boolean;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.post.disappearedBehaelter({
        id,
        disappeared,
      });

      if (data.message) {
        dispatch(setIsToasterSuccess(true));
        dispatch(setSuccessMessage(data.message));
      }
      dispatch(setIsConfirmationPopupActive(false))
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/updateBehaelter",
  async (
    {
      id,
      volume,
    }: {
      id: string;
      volume: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.put.updateBehaelter({
        id,
        volume,
      });

      dispatch(setIsConfirmationPopupActive(false))

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const removeBehaelter = createAsyncThunk(
  "behaelterverwaltung/client/removeBehaelter",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.behaelterverwaltung.delete.removeBehaelter({ id });

      dispatch(setIsConfirmationPopupActive(false))

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const behaelterverwaltungSlice = createSlice({
  name: "behaelterverwaltung",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBehaelter.pending, (state) => {});
    builder.addCase(getBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
    });
    builder.addCase(getBehaelter.rejected, (state) => {});
    builder.addCase(addBehaelter.pending, (state) => {});
    builder.addCase(addBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
    });
    builder.addCase(addBehaelter.rejected, (state) => {});
    builder.addCase(updateBehaelter.pending, (state) => {});
    builder.addCase(updateBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
    });
    builder.addCase(updateBehaelter.rejected, (state) => {});
    builder.addCase(removeBehaelter.pending, (state) => {});
    builder.addCase(removeBehaelter.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.behaelterData = [...action.payload];
      }
    });
    builder.addCase(removeBehaelter.rejected, (state) => {});
  },
});

export const behaelterverwaltungReducer = behaelterverwaltungSlice.reducer;
