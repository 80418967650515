import React, { useEffect, useState } from "react";
import { ISchadstoffmobilData } from "../../api/schadstoffmobil/schadstoffmobil.api";
import {
  AdminCheckbox,
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  Loader,
  ModalWrapper,
  TableGrid,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import {
  clearSchadstoffmobilAdminData,
  deleteSchadstoffmobilPlacesDataByID,
  getSchadstoffmobilData,
} from "../../store/slices/schadstoffmobil";
import Modal from "./components/Modal";
import { GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import exploreArrow from "../../assets/images/exploreArrow.svg";
import { setDataLoading } from "../../store/slices/admin";

const AdminSchadstoffmobilStandorte = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useAppSelector(
    (state) => state.schadstoffmobil.admin.schadstoffmobilData
  );
  const isSchadstoffmobilDataLoading = useAppSelector((state) => state.schadstoffmobil.admin.isSchadstoffmobilDataLoading);
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<null | ISchadstoffmobilData>(
    null
  );

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  // const setSelectedItemHandler = (item: ISchadstoffmobilData) => {
  //   setSelectedItem(item);
  //   dispatch(setIsConfirmationPopupActive(true));
  // };

  const clearSelectedItems = () => {
    dispatch(deleteSchadstoffmobilPlacesDataByID(selectedItems));
    setSelectedItems([]);
  };

  useEffect(() => {
    dispatch(getSchadstoffmobilData());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearSchadstoffmobilAdminData());
      dispatch(setDataLoading(false));
    };
  }, []);

  const FirstItem = (props: GridCellProps) => {
    return (
      <td className="k-command-cell">
        <AdminCheckbox
          checked={selectedItems.includes(props.dataItem._id)}
          changeHandler={() => {
            selectItemHandler(props.dataItem._id);
          }}
        />
      </td>
    );
  };

  const LastItem = (props: GridCellProps) => {
    return (
      <td
        className="k-table-td"
        style={{
          border: "none",
        }}
      >
        <div
          style={{
            width: "28px",
            height: "28px",
            background: " #8F9EA9",
            border: "1px solid #8F9EA9",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "40px",
          }}
          onClick={() => {
            navigate(props.dataItem._id);
          }}
        >
          <img src={exploreArrow} alt="info" />
        </div>
      </td>
    );
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        pageTitle={"Schadstoffmobil Daten"}
        hasBtnGroup={true}
        clearSelectedItems={clearSelectedItems}
        hasItemsLength={!!data.length}
        hasSelectedItems={!!selectedItems.length}
        btnGroupAddItemText={"Add item"}
        btnGroupRemoveItemsText={"Remove item"}
        createBtnHandlerFunc={() => {}}
      />
      <AdminContentWrapper>
        {isSchadstoffmobilDataLoading ? null : !data.length ? (
          <AdminPlaceholder
            title="No Schadstoffmobil Data Yet"
            subTitle="Lorem ipsum dolor."
            btnText="Create Data"
            hasBtn={true}
          />
        ) : (
          <div>
            <TableGrid
              data={data}
              items={[
                {
                  field: "city",
                  title: "Ort",
                },
                {
                  field: "place",
                  title: "Standort",
                },
                {
                  field: "time",
                  title: "Uhrzeit",
                },
                {
                  field: "tour",
                  title: "Tour",
                },
                {
                  field: "lat",
                  title: "Lat",
                },
                {
                  field: "lon",
                  title: "Long",
                },
              ]}
              firstItem={<GridColumn cell={FirstItem} width="40px" />}
              lastItem={<GridColumn cell={LastItem} width="90px" />}
            />
            {/* <ContentComponent
              items={data}
              selectItemHandler={selectItemHandler}
              selectedItems={selectedItems}
              setSelectedItemHandler={setSelectedItemHandler}
            /> */}
          </div>
        )}
      </AdminContentWrapper>
      {isModalActive && !data.length ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
          }}
        >
          <Modal />
        </ModalWrapper>
      ) : null}
      {isModalActive && selectedItem ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            setSelectedItem(null);
          }}
        >
          <div>qwe</div>
        </ModalWrapper>
      ) : null}
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSchadstoffmobilStandorte;
