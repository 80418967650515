import React, { FC } from "react";
import { CustomInput } from "../../../../../../../../components";
import removeIcon from "../../../../../../../../assets/images/removeIcon.svg";
import style from "./style.module.scss";

interface IRow {
  deleteHandler: () => void;
  idx: number;
  setter: (data: any) => void;
}

const Row: FC<IRow> = ({ idx, deleteHandler }) => {
  return (
    <div className={style.wrapper}>
      <CustomInput
        name={`homeNumbers[${idx}].from`}
        type={"text"}
        label={"from"}
        width={"45%"}
      />
      <CustomInput
        name={`homeNumbers[${idx}].to`}
        type={"text"}
        label={"to"}
        width={"45%"}
      />
      <img
        src={removeIcon}
        className={style.img}
        onClick={deleteHandler}
        alt="removeIcon"
      />
    </div>
  );
};

export default Row;
