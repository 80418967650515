import React, { FC } from "react";
import { Link } from "react-router-dom";
import appConfig from "../../util/appConfig";
import style from "./style.module.scss";

interface IBtnLink {
  link: string;
  color?: string;
  title: string;
  isOutlined?: boolean;
  width?: string;
  fontSize?: string;
  isHashLink?: boolean;
  marginRight?: string;
  marginLeft?: string;
  marginBottom?: string;
  isMobile?: boolean;
  isBackgroundColored?: boolean;
}

export const BtnLink: FC<IBtnLink> = ({
  link,
  color = "#008BD2",
  title,
  isOutlined = false,
  width,
  fontSize,
  isHashLink = false,
  marginRight,
  marginLeft,
  marginBottom,
  isMobile = false,
  isBackgroundColored = false,
}) => {
  const generateBgcColor = () => {
    switch (true) {
      case !isOutlined: {
        return color;
      }
      case isOutlined && isBackgroundColored: {
        return appConfig.colorForBtnBackground;
      }
      default: {
        return "#fff";
      }
    }
  };

  const linkStyle = {
    backgroundColor: generateBgcColor(),
    border: isOutlined ? `1px solid ${color}` : "none",
    color: isOutlined ? color : "",
    width: width && !isMobile ? `${width}px` : "",
    maxWidth: width && isMobile ? `${width}px` : "",
    fontSize: fontSize?.length && `${fontSize}px`,
  };

  return (
    <div
      className={style.btn_link}
      style={{
        marginBottom: marginBottom?.length && `${marginBottom}px`,
        marginRight: marginRight?.length && `${marginRight}px`,
        marginLeft: marginLeft?.length && `${marginLeft}px`,
      }}
    >
      {!isHashLink ? (
        <Link style={linkStyle} to={link} >
          {title}
        </Link>
      ) : (
        <a href={link} style={linkStyle} className={style.btn_link}>
          {title}
        </a>
      )}
    </div>
  );
};
