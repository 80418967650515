import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../api/api";
import { ISchadstoffmobilData } from "../../api/schadstoffmobil/schadstoffmobil.api";
import calculateDistance from "../../util/calculateDistance";
import { setAdminLoader } from "./admin";
import { setIsLoading, setIsToasterError } from "./app";

export interface ISchadstoffmobilDataItem extends ISchadstoffmobilData {
  distance: null | number;
}

interface ISchadstoffmobilState {
  client: {
    data: ISchadstoffmobilDataItem[];
    userLat: number | null;
    userLon: number | null;
  };
  admin: {
    schadstoffmobilData: ISchadstoffmobilData[];
    isSchadstoffmobilDataLoading: boolean;
  };
}

const initialState: ISchadstoffmobilState = {
  client: { data: [], userLat: null, userLon: null },
  admin: {
    schadstoffmobilData: [],
    isSchadstoffmobilDataLoading: false,
  },
};

export const getSchadstoffmobilPlacesData = createAsyncThunk(
  "schadstoffmobil/client/getSchadstoffmobilPlacesData",
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const data = await API.schadstoffmobil.get.getSchadstoffmobilData();
      thunkApi.dispatch(setIsLoading(false));

      return data;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getSchadstoffmobilData = createAsyncThunk(
  "schadstoffmobil/admin/getSchadstoffmobilData",
  async (_, thunkApi) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));
      const data = await API.schadstoffmobil.get.getAdminSchadstoffmobilData();
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      return data;
    } catch (error) {
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const deleteSchadstoffmobilPlacesDataByID = createAsyncThunk(
  "schadstoffmobil/admin/deleteSchadstoffmobilPlacesDataByID",
  async (ids: string[], thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      await API.schadstoffmobil.delete.deleteSchadstoffmobilPlacesDataByID(ids);
      thunkApi.dispatch(setAdminLoader(false));
      return ids;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const createSchadstoffmobilPlacesData = createAsyncThunk(
  "schadstoffmobil/admin/createSchadstoffmobilPlacesData",
  //TODO: fix type ISchadstoffmobilData
  async (data: any, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const item =
        await API.schadstoffmobil.post.createSchadstoffmobilPlacesData(data);
      thunkApi.dispatch(setAdminLoader(false));
      return item;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

const schadstoffmobilSlice = createSlice({
  name: "schadstoffmobi",
  initialState,
  reducers: {
    setUserLatLon: (
      state,
      action: PayloadAction<{ lan: number; lon: number }>
    ) => {
      state.client.userLat = action.payload.lan;
      state.client.userLon = action.payload.lon;
    },
    calculateDataDistance: (state) => {
      if (
        state.client.data.length &&
        state.client.userLat !== null &&
        state.client.userLon !== null
      ) {
        state.client.data = [
          ...state.client.data.map((i) => {
            return {
              ...i,
              distance: calculateDistance(
                [state.client.userLat!, state.client.userLon!],
                [+i.lat, +i.lon]
              ),
            };
          }),
        ];
      }
    },
    setFilter: (state, action: PayloadAction<"date" | "distance">) => {
      if (action.payload === "date") {
        state.client.data = [...state.client.data.map((i) => i)];
      }
      if (action.payload === "distance") {
        state.client.data = [
          ...state.client.data.sort((a, b) => {
            if (a.distance && b.distance) {
              if (a.distance < b.distance) return -1;
              if (b.distance < a.distance) return 1;
            }
            return 0;
          }),
        ];
      }
    },
    clearSchadstoffmobilData: (state) => {
      state.client.data = [];
    },
    clearSchadstoffmobilAdminData: (state) => {
      state.admin.schadstoffmobilData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchadstoffmobilPlacesData.fulfilled, (state, action) => {
      if (action.payload && action.payload.length) {
        if (state.client.userLat && state.client.userLon) {
          state.client.data = [
            ...state.client.data.map((i) => {
              return {
                ...i,
                distance: calculateDistance(
                  [state.client.userLat!, state.client.userLon!],
                  [+i.lat, +i.lon]
                ),
              };
            }),
          ];
          return;
        }
        state.client.data = [
          ...action.payload.map((i: any) => {
            return { ...i, distance: null } as ISchadstoffmobilDataItem;
          }),
        ];
      }
    });
    builder.addCase(
      deleteSchadstoffmobilPlacesDataByID.fulfilled,
      (state, action) => {
        state.admin.schadstoffmobilData =
          state.admin.schadstoffmobilData.filter(
            (i) => !action.payload?.includes(i._id) && i
          );
      }
    );
    builder.addCase(getSchadstoffmobilData.pending, (state) => {
      state.admin.isSchadstoffmobilDataLoading = true;
    });
    builder.addCase(getSchadstoffmobilData.fulfilled, (state, action) => {
      if (action.payload && action.payload.length) {
        state.admin.schadstoffmobilData = [...action.payload];
      }
      state.admin.isSchadstoffmobilDataLoading = false;
    });
    builder.addCase(getSchadstoffmobilData.rejected, (state) => {
      state.admin.isSchadstoffmobilDataLoading = false;
    });
    builder.addCase(
      createSchadstoffmobilPlacesData.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.schadstoffmobilData = [
            ...state.admin.schadstoffmobilData,
            { ...action.payload },
          ];
        }
      }
    );
  },
});

export const {
  setUserLatLon,
  calculateDataDistance,
  setFilter,
  clearSchadstoffmobilData,
  clearSchadstoffmobilAdminData,
} = schadstoffmobilSlice.actions;

export const schadstoffmobilReducer = schadstoffmobilSlice.reducer;
