import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { AdminDetailsPageItemName, Button } from "../../../../../../components";
import { Form, Formik } from "formik";
import Calendar from "./components/Calendar";
import { addMonths } from "date-fns";
import { IDisplayedMonth } from "../../../../../Calendar/components/Calendar/components/CalendarComponent";
import getCurrentCalendarMonthDaysToShow from "../../../../../../util/getCurrentCalendarMonthDaysToShow";
import { updateSperrmuellScheduleSettings } from "../../../../../../store/slices/app";

const Holidays = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleStatus
  );
  const title = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleTitle
  );
  const subtitle = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleSubtitle
  );
  const url = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleURL
  );
  const bccEMailAddress = useAppSelector(
    (state) => state.app.admin.bccEMailAddress
  );
  const holidays = useAppSelector((state) => state.app.client.holidays);
  const [date, setDate] = useState(new Date().toDateString());

  const nextMonthHandler = () => {
    setDate(new Date(addMonths(new Date(date), 1)).toDateString());
  };
  const prevMonthHandler = () => {
    setDate(new Date(addMonths(new Date(date), -1)).toDateString());
  };

  let calendarData: IDisplayedMonth[][] = [];

  for (let i = 0; i < getCurrentCalendarMonthDaysToShow(date).length / 7; i++) {
    calendarData.push([
      ...getCurrentCalendarMonthDaysToShow(date).slice(i * 7, (i + 1) * 7),
    ]);
  }
  const initialValues = {
    status,
    title,
    subtitle,
    url,
    bccEMailAddress,
    holidays,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        dispatch(updateSperrmuellScheduleSettings(values));
      }}
      enableReinitialize
    >
      {(props) => {
        return (
          <>
            <AdminDetailsPageItemName text="Feiertage" />
            <Form style={{ paddingBottom: "20px" }}>
              <Calendar
                date={date}
                holidays={props.values.holidays}
                nextMonthHandler={nextMonthHandler}
                prevMonthHandler={prevMonthHandler}
                setFieldValue={props.setFieldValue}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  width="200"
                  textColor="#fff"
                  backgroundColor="#008BD2"
                  text="Speichern"
                  type="submit"
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default Holidays;
