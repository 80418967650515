import { add, endOfMonth, getDaysInMonth, startOfMonth } from "date-fns";
import {
  IDisplayedMonth,
  IMonth,
} from "../pages/Calendar/components/Calendar/components/CalendarComponent";

const createMonthArray = (daysInMonth: number, monthType: IMonth) => {
  return Array(daysInMonth)
    .fill(undefined)
    .map((_, index) => {
      return {
        day: (index + 1).toString(),
        month: monthType,
      };
    }) as IDisplayedMonth[];
};

const daysInMonth = (date: string, monthToAdd: number = 0) => {
  return getDaysInMonth(
    add(new Date(date), {
      months: monthToAdd,
    })
  );
};

const getCurrentCalendarMonthDaysToShow = (date: string) => {
  const d = new Date(date).toDateString();
  const firstDayOfCurrentMonth = startOfMonth(new Date(d));
  const lastDayOfCurrentMont = endOfMonth(new Date(d));
  const daysInCurrentMonth = daysInMonth(d);

  const currentMonthArr: IDisplayedMonth[] = createMonthArray(
    daysInCurrentMonth,
    "current"
  );

  const daysInPrevMonth = daysInMonth(d, -1);

  const prevMonthArray: IDisplayedMonth[] = createMonthArray(
    daysInPrevMonth,
    "prev"
  );

  const daysInNextMonth = daysInMonth(d, 1);

  const nextMonthArray: IDisplayedMonth[] = createMonthArray(
    daysInNextMonth,
    "next"
  );

  const startArr =
    new Date(firstDayOfCurrentMonth).getDay() === 1
      ? []
      : [
          ...prevMonthArray.slice(
            1 -
              (new Date(firstDayOfCurrentMonth).getDay() === 0
                ? 7
                : new Date(firstDayOfCurrentMonth).getDay())
          ),
        ];
  const endArr =
    new Date(lastDayOfCurrentMont).getDay() === 0
      ? []
      : [
          ...nextMonthArray.slice(
            0,
            7 - new Date(lastDayOfCurrentMont).getDay()
          ),
        ];

  const finalArr: IDisplayedMonth[] = [
    ...startArr,
    ...currentMonthArr,
    ...endArr,
  ];

  return finalArr;
};

export default getCurrentCalendarMonthDaysToShow;
