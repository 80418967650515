import { instance } from "../api";

const getCalwYellowBinDoc = () => {
  return instance
    .get("/yellowbin/calw/docs", {
      responseType: "blob",
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `AWB_Gebuehren_und_Mengenbegrenzungen.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const YellowBin = {
  get: {
    getCalwYellowBinDoc,
  },
};

export default YellowBin;
