import React, { FC } from "react";
import { CustomInput } from "../../../../../../../../components";
import removeIcon from "../../../../../../../../assets/images/removeIcon.svg";
import style from "./style.module.scss";

interface IRow {
  deleteHandler: () => void;
  idx: number;
  onChange?: (val: string, field: string) => void;
}

const Row: FC<IRow> = ({ idx, deleteHandler, onChange }) => {
  return (
    <div className={style.wrapper}>
      <CustomInput
        name={`homeNumbers[${idx}].from`}
        type={"text"}
        label={"from"}
        width={"45%"}
        onChange={(val: string) => {
          if (onChange) onChange(val, "from");
        }}
      />
      <CustomInput
        name={`homeNumbers[${idx}].to`}
        type={"text"}
        label={"to"}
        width={"45%"}
        onChange={(val: string) => {
          if (onChange) onChange(val, "to");
        }}
      />
      <img
        src={removeIcon}
        className={style.img}
        onClick={deleteHandler}
        alt="removeIcon"
      />
    </div>
  );
};

export default Row;
