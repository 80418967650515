import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { Form, Formik } from "formik";
import {
  AdminDetailsPageItemName,
  Button,
  TextArea,
} from "../../../../../../components";
import appConfig from "../../../../../../util/appConfig";
import removeIcon from "../../../../../../assets/images/removeIcon.svg";
import {
  updateHintsText,
  getHintsText,
} from "../../../../../../store/slices/app";
import checkMark from "../../../../../../assets/images/checkMark.svg";

const steps = [
  {
    env: "awl",
    steps: [1, 2],
  },
  {
    env: "ebwo",
    steps: [1, 2, 3],
  },
];

const InfoText = () => {
  const dispatch = useAppDispatch();
  const { infoText } = useAppSelector((state) => state.app.client);
  const [state, setState] = useState<any>({});
  const initialValues = {
    steps: state,
  };

  useEffect(() => {
    let obj: any = {};

    steps
      .find((el) => el.env === appConfig.logo)
      ?.steps.forEach((el) => {
        obj[el] = [];
      });

    for (const key in obj) {
      const item = infoText.find((el) => el.step === +key);
      if (item) {
        obj[key] = {
          steps: [...item.hints],
          isWarningMessage: item?.isWarningMessage || false,
        };
      }
    }
    setState(obj);
  }, [infoText]);

  useEffect(() => {
    dispatch(getHintsText());
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        let cleanedObj: {
          step: number;
          hints: string[];
          isWarningMessage: boolean;
        }[] = [];
        for (const key in values.steps) {
          cleanedObj.push({
            step: parseInt(key),
            hints: values.steps[key].steps.filter((el: string) => el !== ""),
            isWarningMessage: values.steps[key].isWarningMessage,
          });
        }
        dispatch(updateHintsText(cleanedObj));
      }}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        return (
          <>
            <AdminDetailsPageItemName text="Sperrmüllanmeldung Hinweisstexte" />
            <Form>
              <div
                style={{
                  marginBottom: "100px",
                }}
              >
                {Object.keys(values.steps).map((step, idx) => {
                  return (
                    <div key={`${step}-${idx}`}>
                      <div className={style.label}>Schritt {idx + 1}</div>
                      <div className={style.checkboxContentWrapper}>
                        <input
                          type="checkbox"
                          name={`steps[${step}].isWarningMessage`}
                          value={`steps[${step}].isWarningMessage`}
                          checked={values.steps[step].isWarningMessage}
                          style={{
                            display: "none",
                          }}
                          onChange={() => {}}
                        />
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            onClick={() => {
                              setFieldValue(
                                `steps[${step}].isWarningMessage`,
                                !values.steps[step].isWarningMessage
                              );
                            }}
                            className={`${style.checkBox} ${
                              values.steps[step].isWarningMessage
                                ? style.checkBox_checked
                                : ""
                            }`}
                          >
                            {values.steps[step].isWarningMessage && (
                              <img
                                className={style.checkedImg}
                                src={checkMark}
                                alt="check"
                              />
                            )}
                          </div>
                          <span
                            className={`${style.title} ${
                              values.steps[step].isWarningMessage
                                ? style.checkboxTitle
                                : ""
                            }`}
                            onClick={() => {
                              setFieldValue(
                                `steps[${step}].isWarningMessage`,
                                !values.steps[step].isWarningMessage
                              );
                            }}
                          >
                            Mit wichtigen Mitteilungen (wird rot geschrieben)
                          </span>
                        </div>
                      </div>
                      {values.steps[step].steps.length ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {values.steps[step].steps.map(
                            (el: string, id: number) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  key={id}
                                >
                                  <TextArea
                                    name={`steps[${step}].steps.[${id}]`}
                                    type="text"
                                  />
                                  <img
                                    src={removeIcon}
                                    style={{
                                      marginLeft: "20px",
                                    }}
                                    onClick={() => {
                                      values.steps[step].steps.splice(id, 1);
                                      setFieldValue(`steps[${step}].steps`, [
                                        ...values.steps[step].steps,
                                      ]);
                                    }}
                                    alt="removeIcon"
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : null}
                      <div
                        className={style.addHint}
                        onClick={() => {
                          setFieldValue(`steps[${step}].steps`, [
                            ...values.steps[step].steps,
                            "",
                          ]);
                        }}
                      >
                        + Add house numbers to white list
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  width="200"
                  textColor="#fff"
                  backgroundColor="#008BD2"
                  text="Speichern"
                  type="submit"
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default InfoText;
