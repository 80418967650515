import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  AdminDetailPageHeader,
  AdminPlaceholder,
  Loader,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  clearSperrmuelItemInfo,
  getSperrmuelDataById,
} from "../../store/slices/sperrmuell";
import ContentComponent from "./components/ContentComponent";
import { setDataLoading } from "../../store/slices/admin";

const AdminSperrmuelanmeldungInfo = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();
  const isSperrmuelDataLoading = useAppSelector((state) => state.sperrmuell.admin.isSperrmuelDataLoading);
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const sperrmuelItemData = useAppSelector(
    (state) => state.sperrmuell.admin.sperrmuelItemInfo
  );

  useEffect(() => {
    if (itemId) {
      dispatch(getSperrmuelDataById(itemId));
    }
    return () => {
      dispatch(clearSperrmuelItemInfo());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        {isSperrmuelDataLoading ? null : !sperrmuelItemData ? (
          <AdminPlaceholder
            title="No info"
            subTitle="Some description placeholder"
            hasBtn={false}
          />
        ) : (
          <ContentComponent />
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSperrmuelanmeldungInfo;
