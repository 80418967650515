import React, { FC } from "react";
import { Link } from "react-router-dom";
import blueArrow from "../../../../../../assets/images/blueArrow.svg";
import style from "./style.module.scss";

interface INews {
  position: number;
  src: string;
  title: string;
  date: string;
  description: string;
  id: string;
}

const News: FC<INews> = ({ position, src, date, description, title, id }) => {
  const generateWrapperStyle = (pos: number) => {
    switch (pos) {
      case 1: {
        return style.firstElement;
      }
      case 2: {
        return style.secondElement;
      }
      case 3: {
        return style.thirdElement;
      }
      default: {
        return "";
      }
    }
  };

  const generateImgStyle = (pos: number) => {
    switch (pos) {
      case 1: {
        return style.firstPosImg;
      }
      case 2: {
        return style.nextPosImg;
      }
      case 3: {
        return style.nextPosImg;
      }
      default: {
        return "";
      }
    }
  };

  const generateTextWrapperStyle = (pos: number) => {
    switch (pos) {
      case 1: {
        return style.firstPosTextWrapper;
      }
      case 2: {
        return style.nextPosTextWrapper;
      }
      case 3: {
        return style.nextPosTextWrapper;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div className={`${style.wrapper} ${generateWrapperStyle(position)}`}>
      <div
        className={`${generateImgStyle(position)}`}
        style={{
          backgroundImage: `url(${src})`,
        }}
      ></div>
      <div className={`${generateTextWrapperStyle(position)}`}>
        <div>
          <div className={style.title}>{title}</div>
          <div className={style.dateText}>{date}</div>
          <div className={style.description}>{description}</div>
        </div>
        <Link to={`${id}`} className={style.readMoreWrapper}>
          <div className={style.readMoreText}>Mehr lessen</div>
          <img src={blueArrow} alt="read" />
        </Link>
      </div>
    </div>
  );
};

export default News;
