import { instance } from "../api";

const getObjectVerwaltungData = (objectId: string) => {
  return instance
    .get(`/object-verwaltung/data?objectId=${objectId}`)
    .then((res) => res.data);
};

const ObjectVerwaltung = {
  get: {
    getObjectVerwaltungData,
  },
};

export default ObjectVerwaltung;
