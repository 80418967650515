import { format } from "date-fns";
import de from "date-fns/esm/locale/de/index.js";
import React, { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { IArticleBody } from "../../../../../../api/news/news.api";
import { AdminCheckbox } from "../../../../../../components";
import style from "./style.module.scss";

interface IItem {
  checked: boolean;
  changeHandler: (id: string) => void;
  item: IArticleBody;
  selectItemForUpdating: (item: IArticleBody) => void;
}

const Item: FC<IItem> = ({
  changeHandler,
  checked,
  item,
  selectItemForUpdating,
}) => {
  const navigate = useNavigate();
  return (
    <div className={`${style.wrapper} ${checked && style.selected}`}>
      <AdminCheckbox
        changeHandler={() => changeHandler(item._id)}
        checked={checked}
      />
      <div className={`${style.nameTextWrapper} ${style.dateText}`}>
        {format(new Date(item.date), "dd. LLLL yyyy", {
          locale: de,
        })}
      </div>
      <div className={`${style.nameTextWrapper} ${style.title}`}>{item.title}</div>
      <img
        src="https://img.icons8.com/ios-glyphs/512/pencil.png"
        alt="edit"
        className={style.img}
        onClick={(e: MouseEvent<HTMLImageElement>) => {
          e.stopPropagation();
          // selectItemForUpdating(item);
          navigate(`/admin/articles/update/${item._id}`)
        }}
      />
    </div>
  );
};

export default Item;
