import {
  IBehaeltergemeinschaftBase,
  IBehaeltergemeinschaftResponseBody,
} from "../../store/slices/behaeltergemeinschaft";
import { adminInstance, instance } from "../api";

const create = (data: IBehaeltergemeinschaftBase) => {
  return instance.post("/behaeltergemeinschaft", data).then((res) => res.data);
};

const getAdminBehaeltergemeinschaftData = ({
  direction,
  page,
  pageSize,
  searchValue,
  sort,
  signal,
  startDate,
  endDate,
  status,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
}) => {
  return adminInstance
    .get<{
      totalCount: number;
      data: IBehaeltergemeinschaftResponseBody[];
    }>("/admin/behaeltergemeinschaft", {
      params: {
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        startDate,
        endDate,
        status,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminBehaeltergemeinschaftItemById = (id: string) => {
  return adminInstance
    .get<IBehaeltergemeinschaftResponseBody>(
      `/admin/behaeltergemeinschaft/${id}`
    )
    .then((res) => res.data);
};

const updateAdminBehaeltergemeinschaftItemById = ({
  id,
  status,
}: {
  id: string;
  status: string;
}) => {
  return adminInstance
    .put<IBehaeltergemeinschaftResponseBody>(
      `/admin/behaeltergemeinschaft/${id}/status`,
      { status }
    )
    .then((res) => res.data);
};

const changeItemsStatuses = ({
  ids,
  status,
}: {
  ids: string[];
  status: string;
}) => {
  return adminInstance
    .put<IBehaeltergemeinschaftResponseBody[]>(
      `/admin/behaeltergemeinschaft/status`,
      {
        status,
        ids,
      }
    )
    .then((res) => res.data);
};

const getBehaeltergemeinschaftDataCalw = () => {
  return instance
    .get<{
      cityParts: {
        id: string;
        orteilBezeichnung: string;
        ortsteilNummer: number;
        ortsteilBezirk: string;
        gemeindeNummer: string;
      }[];
      streets: {
        id: string;
        gemeindeNummer: number;
        strasseBezeichnung: string;
        ortsteilNummer: number;
      }[];
    }>("/behaeltergemeinschaft/data/calw")
    .then((res) => res.data);
};

const getCalwBehaeltergemeinschaftDoc = () => {
  return instance
    .get("/behaeltergemeinschaft/calw/docs", {
      responseType: "blob",
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Bestaetigung_Behaeltergemeinschaft.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const Behaeltergemeinschaft = {
  get: {
    getAdminBehaeltergemeinschaftData,
    getAdminBehaeltergemeinschaftItemById,
    getBehaeltergemeinschaftDataCalw,
    getCalwBehaeltergemeinschaftDoc,
  },
  post: {
    create,
  },
  put: {
    updateAdminBehaeltergemeinschaftItemById,
    changeItemsStatuses,
  },
};

export default Behaeltergemeinschaft;
