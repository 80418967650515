import React, { ChangeEvent, FC } from "react";
import searchIcon from "../../../../../../assets/images/searchIcon.svg";
import style from "./style.module.scss";

interface ISearchInput {
  value: string;
  changeHandler: (str: string) => void;
}

const SearchInput: FC<ISearchInput> = ({ value, changeHandler }) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeHandler(event.currentTarget.value);
  };
  return (
    <div className={style.wrapper}>
      <input onChange={onChange} value={value} className={style.input} placeholder="Suchen..."/>
      <img src={searchIcon} alt="searchIcon" className={style.icon} />
    </div>
  );
};

export default SearchInput;
