import React, { FC } from "react";
import { AdminCheckbox } from "../../../../../../components";
import style from "./style.module.scss";

interface IItem {
  checked: boolean;
  changeHandler: (id: number) => void;
  item: {
    strasseBezeichnung: string;
    strasseNummer: number;
    greyListIgnore: "active" | "partially" | "ignore";
    greyListHomeNumbers: { from: string; to: string; _id: string }[];
  };
}

const Item: FC<IItem> = ({ changeHandler, checked, item }) => {
  return (
    <div className={`${style.wrapper} ${checked && style.selected}`}>
      <div className={style.qwe}>
        <AdminCheckbox
          changeHandler={() => changeHandler(item.strasseNummer)}
          checked={checked}
        />
        <div className={style.nameTextWrapper}>{item.strasseBezeichnung}</div>
      </div>
      {item.greyListHomeNumbers.length ? (
        <div className={style.itemsWrapper}>
          <div className={style.titleWrapper}>
            <div className={style.titleFromTo}>Hausnummer von</div>
            <div className={style.titleFromTo}>Hausnummer bis</div>
          </div>
          <div >
            {item.greyListHomeNumbers.map((i) => {
              return (
                <div key={i._id} className={style.houseNumberWrapper}>
                  <div className={style.houseNumber}>{i.from}</div>
                  <div className={style.houseNumber}>{i.to}</div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Item;
