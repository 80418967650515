import React, { FC } from "react";
import styles from "./style.module.scss";

interface IAdminContentWrapper {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const AdminContentWrapper: FC<IAdminContentWrapper> = ({
  children,
  style = {},
}) => {
  return (
    <div className={styles.contentWrapper} style={style}>
      {children}
    </div>
  );
};
