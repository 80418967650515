import React, { useEffect, useState } from "react";
import API from "../../api/api";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
} from "../../components";
import { useAppDispatch } from "../../store";
import { clearStreetsData, getStreetData } from "../../store/slices/admin";
import appConfig from "../../util/appConfig";
import ContentComponent from "./components/ContentComponent";

const AdminCityParts = () => {
  const dispatch = useAppDispatch();
  const [synchronizingStatus, setSynchronizingStatus] = useState(true);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);

  const updateHandler = () => {
    API.admin.get.synchronizeCityParts();
    setSynchronizingStatus(true);
    setPage(0);
    dispatch(clearStreetsData());
  };

  const loadMoreStreetsHandler = () => {
    dispatch(getStreetData(page));
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    let startSynchronization: NodeJS.Timeout;
    if (synchronizingStatus) {
      startSynchronization = setInterval(() => {
        API.admin.get.getCityPartsStatus("street").then((res) => {
          setSynchronizingStatus(res.status);
          setProgress(res.progress);
          if (!res.status) {
            clearInterval(startSynchronization);
            setProgress(0);
            dispatch(getStreetData(0));
            setPage(1);
          }
        });
      }, 2000);
    }
    return () => {
      clearInterval(startSynchronization);
    };
  }, [synchronizingStatus]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        pageTitle={"City parts"}
        hasBtnGroup={false}
        hasSingleBtn={true}
        singleBtnColor={appConfig.mainColor}
        singleBtnText={
          !synchronizingStatus ? "Synchronize data" : `Loading... ${progress}%`
        }
        singleBtnFunc={!synchronizingStatus ? updateHandler : () => {}}
      />
      <AdminContentWrapper>
        {synchronizingStatus ? (
          <Loader />
        ) : (
          <ContentComponent loadMoreStreetsHandler={loadMoreStreetsHandler} />
        )}
      </AdminContentWrapper>
    </div>
  );
};

export default AdminCityParts;
