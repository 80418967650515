import {
  ILeerung,
  ILeerungsdatenEinsichtResponseBody,
} from "../../store/slices/leerungsdatenEinsicht";
import { instance } from "../api";

const getLeerungsDaten = () => {
  return instance
    .get<ILeerungsdatenEinsichtResponseBody[]>("/leerungsdaten-einsicht")
    .then((res) => res.data);
};
const getLeerungsDatenInfo = ({
  id,
  endDate,
  startDate,
}: {
  id: string;
  startDate?: string;
  endDate?: string;
}) => {
  return instance
    .get<ILeerung[]>(`/leerungsdaten-einsicht/${id}/info`, {
      params: {
        endDate,
        startDate,
      },
    })
    .then((res) => res.data);
};

const LeerungsdatenEinsicht = {
  get: {
    getLeerungsDaten,
    getLeerungsDatenInfo,
  },
};

export default LeerungsdatenEinsicht;
