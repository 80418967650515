export const userToken = {
  set: (token: string) => {
    localStorage.setItem("token", token);
  },
  setAdminToken: (token: string) => {
    localStorage.setItem("adminToken", token);
  },
  get: () => {
    return localStorage.getItem("token");
  },
  getAdminToken: () => {
    return localStorage.getItem("adminToken");
  },
  remove: () => {
    localStorage.removeItem("token");
  },
  removeAdminToken: () => {
    localStorage.removeItem("adminToken");
  },
};
