import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import AdminMain from "../../pages/Admin/AdminMain";
import AdminLogin from "../../pages/AdminLogin";
import { useAppDispatch, useAppSelector } from "../../store";
import { checkAdminToken } from "../../store/slices/admin";
import { userToken } from "../../util/userToken";

const AdminContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const isTokenValid = useAppSelector((state) => state.admin.isTokenValid);
  const loading = useAppSelector((state) => state.app.client.isLoading);
  const [isLoading, setIsLoading] = useState(true);

  const token = userToken.getAdminToken();

  useEffect(() => {
    if (!token) {
      navigate("/admin");
      setIsLoading(false);
    }
    if (token && token.length) {
      dispatch(
        checkAdminToken({
          token,
          navigate,
          path:
            location.pathname === "/admin"
              ? "/admin/sperrmuell-anmeldungen"
              : location.pathname,
        })
      );
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isTokenValid === false) {
      navigate("/admin");
      setIsLoading(false);
    }
  }, [isTokenValid]);

  if (isLoading && loading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="admin" element={<AdminLogin />} />
        <Route path="admin/*" element={<AdminMain />} />
      </Routes>
    </Suspense>
  );
};

export default AdminContent;
