import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  AdminDetailPageHeader,
  AdminPlaceholder,
  Loader,
} from "../../components";
import ContentComponent from "./components/ContentComponent";
import {
  clearCalendarDataItem,
  getCalendarDataById,
  updateCalendarDataById,
} from "../../store/slices/calendar";
import { ICalendarDataInfo } from "../../api/calendar/calendar.api";
import { Formik } from "formik";
import { setDataLoading } from "../../store/slices/admin";

const AdminCalendarDataItemInfo = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();
  const isCalendarDataLoading = useAppSelector(
    (state) => state.calendar.admin.isCalendarDataLoading
  );
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const calendarDataItem = useAppSelector(
    (state) => state.calendar.admin.calendarDataItem
  );

  const initialValues: ICalendarDataInfo = {
    color: calendarDataItem?.color
      ? `${calendarDataItem?.color
          .charAt(0)
          .toUpperCase()}${calendarDataItem?.color.slice(1)}`
      : "",
    communityDistrict: calendarDataItem?.communityDistrict || "",
    communityNum: calendarDataItem?.communityNum || "",
    containerType: calendarDataItem?.containerType || "",
    date: calendarDataItem?.date || "",
    place: calendarDataItem?.place || "",
    streetDistrict: calendarDataItem?.streetDistrict || "",
    streetNum: calendarDataItem?.streetNum || "",
    tour: calendarDataItem?.tour || "",
    homeNum1: calendarDataItem?.homeNum1 || "",
    homeNum2: calendarDataItem?.homeNum2 || "",
    rhythm: calendarDataItem?.rhythm || "",
  };

  useEffect(() => {
    if (itemId) {
      dispatch(getCalendarDataById(itemId));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearCalendarDataItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          values.color = values.color.toLowerCase();
          if (itemId) {
            dispatch(
              updateCalendarDataById({
                id: itemId,
                data: values,
              })
            );
          }
        }}
        enableReinitialize
      >
        {({ values, handleSubmit }) => {
          return (
            <>
              <AdminDetailPageHeader
                onClick={handleSubmit}
                btnText="Submit"
                width="200"
              />
              <AdminContentWrapper>
                {isCalendarDataLoading ? null : !calendarDataItem ? (
                  <AdminPlaceholder
                    title="No info"
                    subTitle="Some description placeholder"
                    hasBtn={false}
                  />
                ) : (
                  <ContentComponent values={values} />
                )}
              </AdminContentWrapper>
            </>
          );
        }}
      </Formik>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminCalendarDataItemInfo;
