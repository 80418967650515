import { Form, Formik } from "formik";
import React, { FC } from "react";
import { Button, CustomInput } from "../../../../../../components";
import { useAppDispatch } from "../../../../../../store";
import { createToken } from "../../../../../../store/slices/token";
import transformHexToRgba from "../../../../../../util/transformHexToRgba";

interface ICreateTokenForm {
  closeModalHandler: () => void;
}

const CreateTokenForm: FC<ICreateTokenForm> = ({ closeModalHandler }) => {
  const dispatch = useAppDispatch();
  const initialValues = {
    name: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        dispatch(createToken(values.name));
        resetForm();
      }}
    >
      {(props) => {
        return (
          <Form>
            <CustomInput
              type="text"
              hasFullWidth={true}
              name="name"
              label="Name*"
              placeholder={"Bitte Namen eingeben"}
              marginBottom={"30"}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text="Schließen"
                width="298"
                onClick={closeModalHandler}
                isOutlined={true}
                outlineColor={'#0087ce'}
                textColor={'#0087ce'}
              />
              <Button
                width="298"
                text="Speichern"
                backgroundColor={
                  props.values.name.length
                    ? '#0087ce'
                    : transformHexToRgba('#0087ce', "0.4")
                }
                type="submit"
                disabled={!props.values.name.length}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateTokenForm;
