import { format, isValid } from "date-fns";
import { de } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { ISperrmuel } from "../../api/spermuell/spermuell.api";
import {
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  Loader,
  ModalWrapper,
  TableGrid,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import {
  changeSperrmuellItemsStatuses,
  clearSperrmuelData,
  getSperrmuelDataSearch,
} from "../../store/slices/sperrmuell";
import Modal from "./components/Modal";
import { GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import exploreArrow from "../../assets/images/exploreArrow.svg";
import { setDataLoading } from "../../store/slices/admin";
import style from "./style.module.scss";
import { SortDescriptor } from "@progress/kendo-data-query";
import appConfig from "../../util/appConfig";

export const statuses: { [key: string]: string } = {
  check: "Prüfen",
  accepted: "Fertig",
  declined: "Storniert",
  inWork: appConfig.logo === "calw" ? "Offen" : "In Arbeit",
  error: "Fehler",
};

const AdminSperrmuelanmeldungen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sperrmuelData = useAppSelector(
    (state) => state.sperrmuell.admin.sperrmuelData
  );
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );

  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const { sperrmuelDataTotalCount, isSperrmuelDataLoading } = useAppSelector(
    (state) => state.sperrmuell.admin
  );
  const [selectedItem, setSelectedItem] = useState<null | ISperrmuel>(null);
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [prevValue, setPrevValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [status, setStatus] = useState<string[]>(
    !["calw"].includes(appConfig.logo) ? ["check"] : []
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [serviceType, setServiceType] = useState<string[]>([]);
  const [leistungsartType, setLeistungsartType] = useState<string[]>([]);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  // const selectItemHandler = (id: string) => {
  //   const item = sperrmuelData.find((i) => i.id === id);
  //   setSelectedItem(item!);
  //   dispatch(setIsConfirmationPopupActive(true));
  // };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };
  const changeSetServiceType = (st: string[]) => {
    setServiceType([...st]);
  };
  const changeSetLeistungsartType = (st: string[]) => {
    setLeistungsartType([...st]);
  };
  const abortController = new window.AbortController();
  const signal = abortController.signal;
  useEffect(() => {
    dispatch(
      getSperrmuelDataSearch({
        sort: sort.length && sort[0].field ? sort[0].field : "",
        direction: sort.length && sort[0].dir ? sort[0].dir : "",
        searchValue: value.length ? value : undefined,
        page: page,
        limit: pageSize,
        status: ["ebwo", "lra", "calw"].includes(appConfig.logo)
          ? status.length
            ? [...status]
            : ["check", "accepted", "declined", "inWork"]
          : undefined,
        startDate,
        endDate,
        signal,
        serviceType,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, value, page, pageSize, status, datesChanged, serviceType, leistungsartType]);

  const generateSelectOptions = (type: string) => {
    switch (type) {
      case "lra": {
        return [
          { label: statuses.check, value: "check" },
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.declined, value: "declined" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.error, value: "error" },
        ];
      }
      case "calw": {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.declined, value: "declined" },
        ];
      }
      default: {
        return [
          { label: statuses.check, value: "check" },
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.declined, value: "declined" },
          { label: statuses.inWork, value: "inWork" },
        ];
      }
    }
  };

  const generateServiceType = (type: string) => {
    switch (type) {
      case "calw": {
        return [
          { label: "Expressservice", value: "Expressservice" },
          { label: "Standardservice", value: "Standardservice" },
          { label: "Selbstanlieferung", value: "Selbstanlieferung" },
        ];
      }
      default: {
        return [];
      }
    }
  };
  const generateLesitungsartType = (type: string) => {
    switch (type) {
      case "calw": {
        return [
          { label: "Sperrmül", value: "Sperrmül" },
          { label: "Altholz", value: "Altholz" },
        ];
      }
      default: {
        return [];
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearSperrmuelData());
      dispatch(setDataLoading(false));
    };
  }, []);

  const LastItem = (props: GridCellProps) => {
    return (
      <td
        className="k-table-td"
        style={{
          border: "none",
        }}
        key={Math.floor(Math.random() * 1000000000000)}
      >
        <div
          className={style.detailBtn}
          onClick={() => {
            navigate(props.dataItem.id);
          }}
        >
          <img src={exploreArrow} alt="info" />
        </div>
      </td>
    );
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const setAcceptedStatus = () => {
    if (["calw"].includes(appConfig.logo)) {
      dispatch(
        changeSperrmuellItemsStatuses({
          ids: sperrmuelData.map((el) => el.id),
          status: "accepted",
        })
      );
      return;
    }
  };

  let items: any[] = [];

  if (["ebwo", "lra"].includes(appConfig.logo)) {
    items = [
      {
        field: "createdAt",
        title: "Datum (Meldung)",
      },
      {
        field: "name",
        title: appConfig.logo === "calw" ? "Nachname, Vorname" : "Vorname Name",
      },
      {
        field: "streetData",
        title: "Strasse Hausnummer",
        width: "200px",
      },
      { field: "postcode", title: "PLZ" },
      {
        field: appConfig.logo === "calw" ? "serviceType" : "trashType",
        title: "Leistung",
        cell: (props: GridCellProps) => {
          if (appConfig.logo === "calw") {
            return (
              <td
                style={{
                  border: "none",
                  fontFamily: "Nunito",
                  fontWeight: "inherit",
                  fontSize: "16px",
                }}
                key={Math.floor(Math.random() * 1000000000000)}
              >
                {props.dataItem.serviceType}
              </td>
            );
          }
          return (
            <td
              style={{
                border: "none",
                fontFamily: "Nunito",
                fontWeight: "inherit",
                fontSize: "16px",
              }}
              key={Math.floor(Math.random() * 1000000000000)}
            >
              {props.dataItem.trashType.split(",").map((i: string) => {
                return (
                  <div key={Math.floor(Math.random() * 1000000000000)}>{i}</div>
                );
              })}
            </td>
          );
        },
      },
      {
        field: "trashTypeDates",
        title: "Leistungsdatum",
        cell: (props: GridCellProps) => {
          return (
            <td
              style={{
                border: "none",
                fontFamily: "Nunito",
                fontWeight: "inherit",
                fontSize: "16px",
              }}
              key={Math.floor(Math.random() * 1000000000000)}
            >
              {props.dataItem.trashTypeDates.split(",").map((i: string) => {
                return (
                  <div key={Math.floor(Math.random() * 1000000000000)}>{i}</div>
                );
              })}
            </td>
          );
        },
      },
      {
        field: "status",
        title: "Status",
      },
    ];
  } else if (["calw"].includes(appConfig.logo)) {
    items = [
      {
        field: "status",
        title: "Status",
      },
      {
        field: "createdAt",
        title: "Datum (Meldung)",
      },
      {
        field: "name",
        title: "Nachname, Vorname",
      },
      {
        field: "streetData",
        title: "Strasse",
        width: "200px",
      },
      { field: "postcode", title: "PLZ" },
      { field: "trashTypeInfo", title: "Leistungsart" },
      {
        field: "serviceType",
        title: "Leistung",
        cell: (props: GridCellProps) => {
          if (appConfig.logo === "calw") {
            return (
              <td
                style={{
                  border: "none",
                  fontFamily: "Nunito",
                  fontWeight: "inherit",
                  fontSize: "16px",
                }}
                key={Math.floor(Math.random() * 1000000000000)}
              >
                {props.dataItem.serviceType}
              </td>
            );
          }
          return (
            <td
              style={{
                border: "none",
                fontFamily: "Nunito",
                fontWeight: "inherit",
                fontSize: "16px",
              }}
              key={Math.floor(Math.random() * 1000000000000)}
            >
              {props.dataItem.trashType.split(",").map((i: string) => {
                return (
                  <div key={Math.floor(Math.random() * 1000000000000)}>{i}</div>
                );
              })}
            </td>
          );
        },
      },
      {
        field: "trashTypeDates",
        title: "Leistungsdatum",
        cell: (props: GridCellProps) => {
          return (
            <td
              style={{
                border: "none",
                fontFamily: "Nunito",
                fontWeight: "inherit",
                fontSize: "16px",
              }}
              key={Math.floor(Math.random() * 1000000000000)}
            >
              {props.dataItem.trashTypeDates.split(",").map((i: string) => {
                return (
                  <div key={Math.floor(Math.random() * 1000000000000)}>{i}</div>
                );
              })}
            </td>
          );
        },
      },
    ];
  } else {
    items = [
      {
        field: "createdAt",
        title: "Termin",
      },
      {
        field: "name",
        title: "Vorname Name",
      },
      {
        field: "streetData",
        title: "Strasse Hausnummer",
        width: "200px",
      },
      { field: "postcode", title: "PLZ" },
      {
        field: "trashType",
        title: "Leistung",
        cell: (props: GridCellProps) => {
          return (
            <td
              style={{
                border: "none",
                fontFamily: "Nunito",
                fontWeight: "inherit",
                fontSize: "16px",
              }}
              key={Math.floor(Math.random() * 1000000000000)}
            >
              {props.dataItem.trashType.split(",").map((i: string) => {
                return (
                  <div key={Math.floor(Math.random() * 1000000000000)}>{i}</div>
                );
              })}
            </td>
          );
        },
      },
      {
        field: "trashTypeDates",
        title: "Leistungsdatum",
        cell: (props: GridCellProps) => {
          return (
            <td
              style={{
                border: "none",
                fontFamily: "Nunito",
                fontWeight: "inherit",
                fontSize: "16px",
              }}
              key={Math.floor(Math.random() * 1000000000000)}
            >
              {props.dataItem.trashTypeDates.split(",").map((i: string) => {
                return (
                  <div key={Math.floor(Math.random() * 1000000000000)}>{i}</div>
                );
              })}
            </td>
          );
        },
      },
      {
        field: "isSynchronizedWithTicketSystem",
        title: "Synchronisiert",
      },
    ];
  }

  // if (["calw"].includes(appConfig.logo)) {
  //   items.push({
  //     field: "trashTypeInfo",
  //     title: "Leistungsart",
  //   });
  // }

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader pageTitle={"Sperrmüllanmeldung"} hasBtnGroup={false} />
      <AdminContentWrapper>
        {isSperrmuelDataLoading ? null : !sperrmuelData.length &&
          !["ebwo", "lra", "calw"].includes(appConfig.logo) ? (
          <AdminPlaceholder
            title="No Bulky Wastes Yet"
            subTitle="Some description placeholder"
            hasBtn={false}
          />
        ) : (
          <TableGrid
            data={sperrmuelData.map((i) => {
              return {
                ...i,
                name:
                  appConfig.logo === "calw"
                    ? `${i.userSurname}, ${i.userName}`
                    : `${i.userName} ${i.userSurname}`,
                createdAt: i.createdAt
                  ? format(
                      new Date(i.createdAt),
                      appConfig.logo === "calw"
                        ? "dd. LLLL yyyy HH:mm"
                        : "dd. LLLL yyyy HH:mm:ss",
                      {
                        locale: de,
                      }
                    )
                  : "",
                trashType: i.pickUpTrash.reduce(
                  (prevItem: any, currItem: any) => {
                    prevItem = prevItem + `${currItem.trashType}, `;
                    return prevItem;
                  },
                  ""
                ),
                trashTypeDates: i.pickUpTrash.reduce(
                  (prevItem: any, currItem: any) => {
                    let value = "";
                    if (currItem.date === "Abfallberatung") {
                      value = "Abfallberatung";
                    }

                    if (currItem.date === "ExpressService") {
                      value = "Express Service";
                    }

                    if (currItem.date && isValid(new Date(currItem.date))) {
                      value = format(new Date(currItem.date), "dd. LLLL yyyy", {
                        locale: de,
                      });
                    }
                    // const qwe =
                    //   currItem.date === "Abfallberatung"
                    //     ? "Abfallberatung"
                    //     : currItem.date === "ExpressService"
                    //     ? "Express Service"
                    //     : currItem.date
                    //     ? isValid(new Date(currItem.date))
                    //       ? format(new Date(currItem.date), "dd. LLLL yyyy", {
                    //           locale: de,
                    //         })
                    //       : ""
                    //     : "TBD";
                    prevItem = prevItem + `${value}, `;
                    return prevItem;
                  },
                  ""
                ),
                streetData: `${i.street}, ${i.homeNumber ? i.homeNumber : ""}${
                  i.hausNrZusatz ? " " + i.hausNrZusatz : ""
                }${i.homeNumberTo ? ` - ${i.homeNumberTo}` : ""}`,
                status: i?.status ? statuses[i?.status] : statuses.check,
                trashTypeInfo: i?.trashType,
              };
            })}
            sort={sort}
            sortable={true}
            onSortChange={setSort}
            withExcelExport={true}
            // withPdfExport={true}
            items={items}
            lastItem={<GridColumn cell={LastItem} width="50px" />}
            searchInputValue={value}
            onSearchInputValueChange={changeHandler}
            withPager={true}
            page={page}
            pageSize={pageSize}
            pageChangeHandler={pageChangeHandler}
            pageSizeHandler={pageSizeHandler}
            itemsCount={sperrmuelDataTotalCount}
            withSelect={
              ["ebwo", "lra", "calw"].includes(appConfig.logo) ? true : false
            }
            selectDefaultValue={{ label: "Prüfen", value: status[0] }}
            selectOptions={generateSelectOptions(appConfig.logo).filter(
              (el) => !status.includes(el.value)
            )}
            setValue={changeStatuses}
            value={status.map((el) => {
              return {
                value: el,
                label: statuses[el],
              };
            })}
            onExcelExportClickHandler={setAcceptedStatus}
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
            withDatePicker={["calw"].includes(appConfig.logo)}
            withServiceTypeSelect={["calw"].includes(appConfig.logo)}
            withLeistungsType={["calw"].includes(appConfig.logo)}
            selectServiceTypeOptions={generateServiceType(
              appConfig.logo
            ).filter((el) => !serviceType.includes(el.value))}
            selectLestungsartTypeOptions={generateLesitungsartType(
              appConfig.logo
            ).filter((el) => !serviceType.includes(el.value))}
            setServiceTypeValue={changeSetServiceType}
            setLeistungsartValue={changeSetLeistungsartType}
            serviceTypeSelectValue={serviceType.map((el) => {
              return {
                value: el,
                label: el,
              };
            })}
            leistungsartTypeSelectValue={leistungsartType.map((el) => {
              return {
                value: el,
                label: el,
              };
            })}
          />
          // <ContentComponent
          //   items={sperrmuelData}
          //   selectItemHandler={selectItemHandler}
          //   selectedItemId={selectedItem?.id || null}
          // />
        )}
      </AdminContentWrapper>
      {isModalActive && selectedItem ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            setSelectedItem(null);
          }}
        >
          <Modal item={selectedItem} />
        </ModalWrapper>
      ) : null}
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSperrmuelanmeldungen;
