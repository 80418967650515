import React, { FC, useState } from "react";
import { useField } from "formik";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import style from "./style.module.scss";
import { convertToRaw } from "draft-js";

interface IRichTextEditor {
  name: string;
  type: string;
  label?: string;
}

export const RichTextEditor: FC<IRichTextEditor> = ({ name, type, label }) => {
  const [field, , { setValue }] = useField({
    name,
    type,
  });

  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    field.value ? prepareDraft(field.value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setValue(forFormik);
    setEditorState(editorState);
  };

  return (
    <div className={style.wrapper}>
      {label ? <label className={style.label}>{label}</label> : null}
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorStyle={{
          border: "1px solid #F1F1F1",
          padding: "5px 10px",
          minHeight: "calc(100vh - 555px)",
          overflow: "auto",
          backgroundColor: 'white'
        }}
      />
    </div>
  );
};
