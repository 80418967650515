import React, { FC, MouseEvent } from "react";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { ICalendarData } from "../../../../api/calendar/calendar.api";
import style from "./style.module.scss";
import ItemInfo from "./components/ItemInfo";
import UpdateItemForm from "./components/UpdateItemForm";

interface IModal {
  item: ICalendarData;
  isEditable: boolean;
  closeModalHandler: () => void;
}

const Modal: FC<IModal> = ({ item, isEditable, closeModalHandler }) => {
  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      style={{
        width: isEditable ? "800px" : "",
      }}
    >
      <div className={style.headerWrapper}>
        <div className={style.title}>Sperrmülabholung Information</div>
        <img
          src={closeIcon}
          alt="close"
          onClick={closeModalHandler}
          className={style.img}
        />
      </div>
      {!isEditable ? (
        <ItemInfo item={item} />
      ) : (
        <UpdateItemForm item={item} closeModalHandler={closeModalHandler} />
      )}
    </div>
  );
};

export default Modal;
