import React, { CSSProperties, FC } from "react";
import style from "./style.module.scss";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../store";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { userToken } from "../../util/userToken";

interface IHeaderSelect {
  customStyle?: CSSProperties;
}

export const HeaderSelect: FC<IHeaderSelect> = ({ customStyle }) => {
  const { vorname, nachname, plz, ort, strasse, hausnr } = useAppSelector(
    (state) => state.user.objectIdUser
  );
  return (
    <div>
      <Popup
        trigger={
          <button
            type="button"
            style={{
              border: "none",
              display: "flex",
            }}
          >
            <div className={style.valueContainer}>
              <div className={style.name}>
                {vorname}, {nachname}
              </div>
              <div className={style.address}>
                {strasse}, {hausnr}
              </div>
              <div className={style.address}>
                {plz}, {ort}
              </div>
            </div>
          </button>
        }
        position={"bottom center"}
        on={["hover", "focus"]}
        arrow={false}
        className={style.ewq}
      >
        <>
          <Link to={"/kassenzeichen-verwaltung"} className={style.link}>
            Kassenzeichen Verwaltung
          </Link>
          <div
            className={style.footerDivider}
            style={{
              margin: "10px 0",
            }}
          />
          <div
            className={style.link}
            onClick={() => {
              userToken.remove();
            }}
          >
            Ausloggen
          </div>
        </>
      </Popup>
    </div>
  );
};
