import React, { FC } from "react";
import Step from "./components/Step";
import appointmentCheckMark from "../../assets/images/appointmentCheckMark.svg";
import style from "./style.module.scss";
import appConfig from "../../util/appConfig";
import { useAppSelector } from "../../store";

interface IStepper {
  stepNumber: number;
  steps: {
    step: number;
    title: string;
  }[];
  withCheckMark?: boolean;
  withMobileHeaderTitle?: boolean;
  revertStepperColor?: boolean;
}

export const Stepper: FC<IStepper> = ({
  stepNumber,
  steps,
  withCheckMark = false,
  withMobileHeaderTitle = true,
  revertStepperColor = false,
}) => {
  const { isMobile } = useAppSelector((state) => state.app.client);

  return (
    <div className={style.wrapper}>
      {isMobile && !withMobileHeaderTitle ? (
        <div className={style.headerStepperWrapper}>
          {steps.map((item) => {
            return (
              <div className={style.stepperNameWrapper} key={item.step}>
                <div
                  style={{
                    border: `1px solid ${
                      !revertStepperColor
                        ? appConfig.mainColor
                        : appConfig.secondaryColor
                    }`,
                    color: !revertStepperColor
                      ? appConfig.mainColor
                      : appConfig.secondaryColor,
                  }}
                  className={`${style.stepNumberWrapper} ${
                    item.step !== 1 &&
                    stepNumber < item.step &&
                    style.stepNumberWrapperInactive
                  }`}
                >
                  {withCheckMark && item.step < stepNumber ? (
                    <img
                      src={appointmentCheckMark}
                      alt={item.step.toString()}
                    />
                  ) : (
                    item.step
                  )}
                </div>
                {item.step === stepNumber ? (
                  <div
                    className={style.title}
                    style={{
                      color: !revertStepperColor
                      ? appConfig.mainColor
                      : appConfig.secondaryColor
                    }}
                  >
                    {item.title}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
      <div className={style.stepperWrapper}>
        {steps.map((step, idx) => {
          return (
            <Step
              isFirstElement={idx === 0}
              isLastElement={steps.length === idx + 1}
              step={step.step}
              title={step.title}
              key={step.step}
              stepNumber={stepNumber}
              width={100 / steps.length}
              withCheckMark={withCheckMark}
              withMobileHeaderTitle={withMobileHeaderTitle}
              revertStepperColor={revertStepperColor}
            />
          );
        })}
      </div>
    </div>
  );
};
