const appConfig = {
  mainColor: process.env.REACT_APP_MAIN_COLOR || "#0087CE",
  colorForBtnBackground:
    process.env.REACT_APP_MAIN_COLOR_FOR_BTN_BACKGROUND || "#fff",
  // isBulkyTrashActive: process.env.REACT_APP_IS_BULKY_TRASH_ACTIVE || "true",
  bulkyTrashColor: process.env.REACT_APP_BULKY_TRASH_COLOR || "#84C3E4",
  // isBioBinActive: process.env.REACT_APP_IS_BIO_BIN_ACTIVE || "true",
  bioBinColor: process.env.REACT_APP_IS_BIO_BIN_COLOR || "#F2C27A",
  // isDefectBinActive: process.env.REACT_APP_IS_DEFECT_BIN_ACTIVE || "true",
  defectBinColor: process.env.REACT_APP_IS_DEFECT_BIN_COLOR || "#6FBABE",
  // isOtherTrashActive: process.env.REACT_APP_IS_OTHER_TRASH_ACTIVE || "true",
  otherTrashColor: process.env.REACT_APP_IS_OTHER_TRASH_COLOR || "#86A5ED",
  // isCalendarActive: process.env.REACT_APP_IS_CALENDAR_ACTIVE || "false",
  calendarColor: process.env.REACT_APP_IS_CALENDAR_COLOR || "#FFB68D",
  logo: process.env.REACT_APP_LOGO || "",
  title: process.env.REACT_APP_TITLE || "",
  titleTextColor: process.env.REACT_APP_TITLE_TEXT_COLOR || "",
  // isAllOnlineServicesAvailable:
  //   process.env.REACT_APP_IS_ALL_ONLINE_SERVICES_AVAILABLE || "true",
  // isBulkyTrashServiceAvailable:
  //   process.env.REACT_APP_IS_BULKY_TRASH_SERVICES_AVAILABLE || "true",
  // isBioBinServiceAvailable:
  //   process.env.REACT_APP_IS_BIO_BIN_SERVICES_AVAILABLE || "true",
  // isDefectBinServiceAvailable:
  //   process.env.REACT_APP_IS_DEFECT_BIN_SERVICES_AVAILABLE || "true",
  // isOtherTrashServiceAvailable:
  //   process.env.REACT_APP_IS_OTHER_TRASH_SERVICES_AVAILABLE || "true",
  // isCalendarServiceAvailable:
  //   process.env.REACT_APP_IS_CALENDAR_SERVICES_AVAILABLE || "true",
  // isNrwServiceAvailable:
  //   process.env.REACT_APP_IS_NRW_SERVICES_AVAILABLE || "true",
  // isWertstofftermineAvailable:
  //   process.env.REACT_APP_IS_WERTSTOFFTERMINE_AVAILABLE || "true",
  // isActualAvailable: process.env.REACT_APP_IS_ACTUAL_AVAILABLE || "true",
  nrwLoginURl: process.env.REACT_APP_NRW_LOGIN_URL || "",
  nrwSignupUrl: process.env.REACT_APP_NRW_SIGNUP_URL || "",
  // isBookingAppointmentAvailable:
  //   process.env.REACT_APP_IS_BOOKING_APPOINTMENT_AVAILABLE || "false",
  bookingAppointmentColor:
    process.env.REACT_APP_BOOKING_APPOINTMENT_COLOR || "",
  // isLeerungsdatenServiceAvailable:
  //   process.env.REACT_APP_IS_LEERUNGSDATEN_AVAILABLE || "false",
  // isProfileAvailable: process.env.REACT_APP_IS_PROFILE_AVAILABLE || "false",
  secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || "",
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY || "",
  siteKey: process.env.REACT_APP_SITE_KEY || "",
  isEditHintTextAvailable:
    process.env.REACT_APP_IS_EDIT_HINT_TEXT_AVAILABLE === "true" ? true : false,
  envName: process.env.REACT_APP_ENV_NAME || "dev",
};

export default appConfig;
