import React, { FC, useState } from "react";
import { useField } from "formik";
import Select from "react-select";
import style from "./style.module.scss";
import arrowDownGrey from "../../assets/images/arrowDownGrey.svg";
import arrowUpBlue from "../../assets/images/arrowUpBlue.svg";
import validationError from "../../assets/images/validationError.svg";
import { SmallLoader } from "../Loader";

interface IInputWithSelect {
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  hasFullWidth?: boolean;
  options: { value: string; label: string }[];
  width?: string;
  marginRight?: string;
  marginBottom?: string;
  withoutMargins?: boolean;
  backgroundColor?: string;
  isRequired?: boolean;
  arrowTopLocation?: string;
  withErrorMessage?: boolean;
  cityHandler?: (city: string) => void;
  height?: string;
  isSmallLoaderActive?: boolean;
  isSmallLoaderAvailable?: boolean;
  isDisabled?: boolean;
  changeHandler?: (str: string) => void;
  defaultValue?: string;
  customIsTouched?: boolean;
  customError?: string;
}

export const InputWithSelect: FC<IInputWithSelect> = ({
  name,
  type,
  label,
  placeholder,
  hasFullWidth,
  options,
  width,
  marginRight,
  withoutMargins = false,
  backgroundColor = "#f6f8fe",
  isRequired = true,
  arrowTopLocation,
  withErrorMessage = true,
  cityHandler,
  height,
  isSmallLoaderActive = false,
  isSmallLoaderAvailable = false,
  isDisabled = false,
  changeHandler,
  marginBottom,
  defaultValue,
  customError = "",
  customIsTouched = false,
}) => {
  const [isFocused, setIsFocused] = useState<undefined | boolean>(undefined);
  const [field, meta, helpers] = useField({
    name,
    type,
  });

  return (
    <div
      style={{
        width: width?.includes("%")
          ? `${width}`
          : width && !width?.includes("%")
          ? `${width}px`
          : "",
        margin: withoutMargins ? "0px" : "",
        marginRight: marginRight ? `${marginRight}px` : "",
        marginBottom: marginBottom ? `${marginBottom}px` : "",
        height: height ? `${height}px` : "",
        opacity: isDisabled ? 0.5 : 1,
      }}
      className={`${style.input_wrapper} ${
        hasFullWidth && style.fullWidthInput
      } ${
        (meta.touched || customIsTouched) &&
        (meta.error || customError) &&
        !field.value.length &&
        style.input_wrapperWithWarning
      }`}
    >
      {label ? (
        <label
          className={`${style.label} ${
            (meta.touched || customIsTouched) &&
            (meta.error || customError) &&
            !field.value.length &&
            !isDisabled &&
            style.labelWithWarning
          }`}
          style={{
            display: "flex",
          }}
        >
          {label}
          {isSmallLoaderAvailable && isSmallLoaderActive ? (
            <SmallLoader loaderDotSize="8" />
          ) : null}
        </label>
      ) : null}
      <Select
        menuPlacement={"auto"}
        isDisabled={!options.length || isDisabled}
        defaultValue={defaultValue}
        styles={{
          dropdownIndicator: (provided) => {
            return { ...provided, display: "none" };
          },
          indicatorsContainer: (provided) => {
            return { ...provided, display: "none" };
          },
          control: () => {
            let border;

            switch (true) {
              case isFocused === true: {
                border = "1px solid #008bd2";
                break;
              }
              case (!isFocused || isFocused === true) &&
                (meta.touched || customIsTouched) &&
                !field.value.length &&
                isRequired: {
                if (
                  (meta.error || customError) &&
                  (meta.error?.length || customError?.length) &&
                  !field.value.length
                ) {
                  border = "1px solid #de3745";
                  break;
                }
                break;
              }

              default: {
                border = "1px solid #dbe0f0";
                break;
              }
            }

            return {
              height: height ? `${height}px` : "44px",
              backgroundColor:
                (meta.touched || customIsTouched) &&
                (meta.error || customError)?.length &&
                !field.value.length &&
                !isDisabled
                  ? "rgba(222, 55, 69, 0.05)"
                  : backgroundColor,
              border: border,
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              fontFamily: "Nunito",
              fontWeght: 500,
              fontSize: "14px",
              boxShadow:
                (meta.touched || customIsTouched) &&
                (meta.error || customError)?.length &&
                !field.value.length
                  ? "1px 1px 12px rgba(222, 55, 69, 0.08)"
                  : "",
            };
          },
          menu: (provided) => {
            return {
              ...provided,
              marginTop: "0",
              backgroundColor: "#ffffff",
              boxShadow: "1px 1px 12px rgba(40, 54, 64, 0.08)",
              zIndex: 13,
            };
          },
          menuList: (provided) => {
            return {
              ...provided,
              fontFamily: "Nunito",
              fontWeght: 500,
              fontSize: "14px",
              color: "#353B3F",
            };
          },
          option: (provided, state) => {
            return {
              ...provided,
              ":hover": {
                backgroundColor: state.isSelected
                  ? "rgba(143, 158, 169, 0.1)"
                  : "rgba(143, 158, 169, 0.1)",
              },
              backgroundColor: state.isSelected
                ? "rgba(143, 158, 169, 0.1)"
                : "#ffffff",
              color: state.isSelected ? "#353B3F" : "#353B3F",
            };
          },
          placeholder: (provided) => {
            return { ...provided, color: "rgba(143, 158, 169, 0.6)" };
          },
        }}
        onChange={(e) => {
          if (cityHandler) {
            cityHandler(e?.value);
            // return;
          }
          if (changeHandler) {
            changeHandler(e?.value);
          }
          helpers.setValue(e?.value);
          setIsFocused(false);
        }}
        onBlur={(e) => {
          if (!cityHandler) {
            field.onBlur(e);
            helpers.setTouched(true);
            setIsFocused(false);
          }
        }}
        options={options}
        isSearchable
        name={name}
        placeholder={placeholder}
        value={field.value && { value: field.value, label: field.value }}
        onFocus={() => setIsFocused(true)}
      />
      {!isFocused ? (
        <img
          src={arrowDownGrey}
          alt="down"
          className={style.arrowImg}
          style={{
            top: arrowTopLocation ? `${arrowTopLocation}` : "",
            transform: arrowTopLocation?.includes("%")
              ? "translateY(-50%)"
              : "",
          }}
        />
      ) : (
        <img
          src={arrowUpBlue}
          alt={"up"}
          className={style.arrowImg}
          style={{
            top: arrowTopLocation ? `${arrowTopLocation}` : "",
            transform: arrowTopLocation?.includes("%")
              ? "translateY(-50%)"
              : "",
          }}
        />
      )}
      {(meta.touched || customIsTouched) &&
        (meta.error || customError) &&
        !field.value.length &&
        withErrorMessage &&
        !isDisabled && (
          <div className={style.errorMessage}>{meta.error || customError}</div>
        )}
      {(meta.touched || customIsTouched) &&
        (meta.error || customError) &&
        !field.value.length &&
        !isDisabled && (
          <img
            src={validationError}
            alt="err"
            className={`${style.warningImg} ${style.warningImgWithSelect}`}
          />
        )}
    </div>
  );
};
