import React from "react";
import style from "./style.module.scss";

const HeaderComponent = () => {
  return (
    <div className={style.wrapper}>
      <div className={`${style.nameTextWrapper} ${style.dateText}`}>Date</div>
      <div className={style.nameTextWrapper}>Article name</div>
    </div>
  );
};

export default HeaderComponent;
