import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
} from "../../components";
import style from "./style.module.scss";
import {
  clearAdminReclamationItem,
  getAdminReclamationItemById,
  updateStatusById,
} from "../../store/slices/reclamation";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import transformHexToRgba from "../../util/transformHexToRgba";

const AdminReclamationById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isReclamationItemDataLoading, reclamationItem } = useAppSelector(
    (state) => state.reclamation.admin
  );

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminReclamationItemById(itemId));
    }
    return () => {
      dispatch(clearAdminReclamationItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isReclamationItemDataLoading ? null : !reclamationItem ? null : (
          <div>
            <div>
              <div className={style.title}>Detailinformation</div>
              <div className={style.tableInfoWrapper}>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Status</div>
                  <div className={style.rowInfo}>
                    {statuses[reclamationItem?.status!]}
                  </div>
                </div>
                <div className={style.lastRowWrapper}>
                  <div className={style.rowTitle}>Erstelungsdatum</div>
                  <div className={style.rowInfo}>
                    <div>
                      {format(
                        new Date(reclamationItem?.createdAt),
                        "dd.MM.yyyy",
                        {
                          locale: de,
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={style.title}>Kontaktdaten</div>
              <div className={style.tableInfoWrapper}>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Name</div>
                  <div className={style.rowInfo}>
                    {reclamationItem?.userSurname}, {reclamationItem?.userName}
                  </div>
                </div>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Straße</div>
                  <div className={style.rowInfo}>
                    {reclamationItem?.street}
                    {reclamationItem?.homeNumber}
                    {reclamationItem?.hausNrZusatz
                      ? `, ${reclamationItem?.hausNrZusatz}`
                      : ""}
                  </div>
                </div>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Ort</div>
                  <div className={style.rowInfo}>{reclamationItem?.place}</div>
                </div>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Telefonnummer</div>
                  <div className={style.rowInfo}>
                    {reclamationItem?.phoneNumber
                      ? reclamationItem?.phoneNumber
                      : "-"}
                  </div>
                </div>
                <div className={style.lastRowWrapper}>
                  <div className={style.rowTitle}>Email Adresse</div>
                  <div className={style.rowInfo}>{reclamationItem?.email}</div>
                </div>
              </div>
            </div>
            <div>
              <div className={style.title}>Reclamation</div>
              <div className={style.tableInfoWrapper}>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Abfallart</div>
                  <div className={style.rowInfo}>{reclamationItem.type}</div>
                </div>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Reklamationsgrund</div>
                  <div className={style.rowInfo}>
                    {reclamationItem?.reclamationReason}
                  </div>
                </div>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>
                    Ursprüngliches Leerungdatum
                  </div>
                  <div className={style.rowInfo}>
                    <div>
                      {format(new Date(reclamationItem?.date), "dd.MM.yyyy", {
                        locale: de,
                      })}
                    </div>
                  </div>
                </div>
                <div className={style.rowWrapper}>
                  <div className={style.rowTitle}>Erstelungsdatum</div>
                  <div className={style.rowInfo}>
                    <div>
                      {format(
                        new Date(reclamationItem?.createdAt),
                        "dd.MM.yyyy",
                        {
                          locale: de,
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className={style.lastRowWrapper}>
                  <div className={style.rowTitle}>Status</div>
                  <div className={style.rowInfo}>
                    {statuses[reclamationItem?.status!]}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                text="Offen"
                width="200"
                isOutlined
                outlineColor={
                  reclamationItem?.status === "inWork"
                    ? transformHexToRgba(appConfig.secondaryColor, "0.4")
                    : appConfig.secondaryColor
                }
                textColor={
                  reclamationItem?.status === "inWork"
                    ? transformHexToRgba(appConfig.secondaryColor, "0.4")
                    : appConfig.secondaryColor
                }
                disabled={reclamationItem?.status === "inWork"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateStatusById({
                        id: itemId,
                        status: "inWork",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={
                  reclamationItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                textColor={
                  reclamationItem?.status === "declined"
                    ? transformHexToRgba("#ff0000", "0.4")
                    : "red"
                }
                disabled={reclamationItem?.status === "declined"}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateStatusById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminReclamationById;
