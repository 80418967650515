import React, { FC } from "react";
import appConfig from "../../util/appConfig";
import style from "./style.module.scss";

interface ISmallLoader {
  loaderDotSize?: string;
}

export const Loader = () => {
  return (
    <div
      className={style.wrapper}
      style={{
        top: `${window.pageYOffset}px`,
      }}
    >
      <div className={style.spinner}>
        <div
          className={style.spinner__item1}
          style={{
            background: appConfig.secondaryColor ? appConfig.secondaryColor : appConfig.mainColor,
          }}
        ></div>
        <div
          className={style.spinner__item2}
          style={{
            background: appConfig.secondaryColor ? appConfig.secondaryColor : appConfig.mainColor,
          }}
        ></div>
        <div
          className={style.spinner__item3}
          style={{
            background: appConfig.secondaryColor ? appConfig.secondaryColor : appConfig.mainColor,
          }}
        ></div>
        <div
          className={style.spinner__item4}
          style={{
            background: appConfig.secondaryColor ? appConfig.secondaryColor : appConfig.mainColor,
          }}
        ></div>
      </div>
    </div>
  );
};

export const SmallLoader: FC<ISmallLoader> = ({ loaderDotSize }) => {
  const sizes = {
    width: loaderDotSize ? `${loaderDotSize}px` : "",
    height: loaderDotSize ? `${loaderDotSize}px` : "",
  };

  return (
    <div>
      <div className={style.spinner}>
        <div
          className={style.spinner__item1}
          style={{
            background: appConfig.mainColor,
            ...sizes,
          }}
        ></div>
        <div
          className={style.spinner__item2}
          style={{
            background: appConfig.mainColor,
            ...sizes,
          }}
        ></div>
        <div
          className={style.spinner__item3}
          style={{
            background: appConfig.mainColor,
            ...sizes,
          }}
        ></div>
        <div
          className={style.spinner__item4}
          style={{
            background: appConfig.mainColor,
            ...sizes,
          }}
        ></div>
      </div>
    </div>
  );
};
