import {
  IEigenkompostiererBase,
  IEigenkompostiererResponseBody,
} from "../../store/slices/eigenkompostierer";
import { adminInstance, instance } from "../api";

const create = (data: IEigenkompostiererBase) => {
  return instance.post("/eigenkompostierer", data).then((res) => res.data);
};

const getAdminEigenkompostiererData = ({
  direction,
  page,
  pageSize,
  searchValue,
  sort,
  signal,
  startDate,
  endDate,
  status,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
}) => {
  return adminInstance
    .get<{
      totalCount: number;
      data: IEigenkompostiererResponseBody[];
    }>("/admin/eigenkompostierer", {
      params: {
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        startDate,
        endDate,
        status,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminEigenkompostiererItemById = (id: string) => {
  return adminInstance
    .get<IEigenkompostiererResponseBody>(`/admin/eigenkompostierer/${id}`)
    .then((res) => res.data);
};

const updateAdminEigenkompostiererItemById = ({
  id,
  status,
}: {
  id: string;
  status: string;
}) => {
  return adminInstance
    .put<IEigenkompostiererResponseBody>(
      `/admin/eigenkompostierer/${id}/status`,
      {
        status,
      }
    )
    .then((res) => res.data);
};

const changeItemsStatuses = ({
  ids,
  status,
}: {
  ids: string[];
  status: string;
}) => {
  return adminInstance
    .put<IEigenkompostiererResponseBody[]>(`/admin/eigenkompostierer/status`, {
      status,
      ids,
    })
    .then((res) => res.data);
};

const getEigenkompostiererDataCalw = () => {
  return instance
    .get<{
      cityParts: {
        id: string;
        orteilBezeichnung: string;
        ortsteilNummer: number;
        ortsteilBezirk: string;
        gemeindeNummer: string;
      }[];
      streets: {
        id: string;
        gemeindeNummer: number;
        strasseBezeichnung: string;
        ortsteilNummer: number;
      }[];
    }>("/eigenkompostierer/data/calw")
    .then((res) => res.data);
};

const Eigenkompostierer = {
  get: {
    getAdminEigenkompostiererData,
    getAdminEigenkompostiererItemById,
    getEigenkompostiererDataCalw,
  },
  post: {
    create,
  },
  put: {
    updateAdminEigenkompostiererItemById,
    changeItemsStatuses,
  },
};

export default Eigenkompostierer;
