import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { setDataLoading } from "../../store/slices/admin";
import {
  AdminContentWrapper,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
} from "../../components";
import style from "./style.module.scss";
import {
  clearAdminEigenkompostiererItem,
  getAdminEigenkompostiererItemById,
  updateAdminEigenkompostiererItemById,
} from "../../store/slices/eigenkompostierer";
import appConfig from "../../util/appConfig";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import History from "../AdminSperrmuelanmeldungInfo/components/ContentComponent/DetailInfo/history";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "In Arbeit",
};

const AdminEigenkompostiererInfoById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { eigenkompostiererItem, isEigenkompostiererItemDataLoading } =
    useAppSelector((state) => state.eigenkompostierer.admin);

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminEigenkompostiererItemById(itemId));
    }
    return () => {
      dispatch(clearAdminEigenkompostiererItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        {isEigenkompostiererItemDataLoading ? null : !eigenkompostiererItem ? null : (
          <div>
            <AdminDetailsPageItemName text="Detailinformationen" />
            <div>
              <div>
                <div className={style.title}>Benutzer Info</div>
                <div className={style.tableInfoWrapper}>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Name</div>
                    <div className={style.rowInfo}>
                      {eigenkompostiererItem?.userSurname}{" "}
                      {eigenkompostiererItem?.userName}
                    </div>
                  </div>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Adresse</div>
                    <div className={style.rowInfo}>
                      {/* {eigenkompostiererItem?.place},{" "} */}
                      {eigenkompostiererItem?.placePart
                        ? eigenkompostiererItem?.placePart + ", "
                        : ""}
                      {eigenkompostiererItem?.postCode},{" "}
                      {eigenkompostiererItem?.street}{" "}
                      {eigenkompostiererItem?.homeNumber}
                      {eigenkompostiererItem?.hausNrZusatz
                        ? ` ${eigenkompostiererItem?.hausNrZusatz}`
                        : ""}
                    </div>
                  </div>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>
                      Buchungsbezeichen Abfallgebühren
                    </div>
                    <div className={style.rowInfo}>
                      {eigenkompostiererItem.bookingCode}
                    </div>
                  </div>
                  <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Nachricht</div>
                    <div className={style.rowInfo}>
                      {eigenkompostiererItem.message}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.title}>Andere Info</div>
                <div className={style.tableInfoWrapper}>
                  <div className={style.rowWrapper}>
                    <div className={style.rowTitle}>Erstellungsdatum</div>
                    <div className={style.rowInfo}>
                      {eigenkompostiererItem.createdAt
                        ? format(
                            new Date(eigenkompostiererItem.createdAt),
                            "dd.MM.yyyy, hh:mm",
                            {
                              locale: de,
                            }
                          )
                        : ""}
                    </div>
                  </div>
                  <div className={style.lastRowWrapper}>
                    <div className={style.rowTitle}>Status</div>
                    <div className={style.rowInfo}>
                      {statuses[eigenkompostiererItem.status]}
                    </div>
                  </div>
                </div>
              </div>
              {eigenkompostiererItem?.history?.length ? (
                <History history={eigenkompostiererItem?.history} />
              ) : null}
            </div>
            <div
              style={{
                // textAlign: "end",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                text="Offen"
                width="200"
                // backgroundColor={appConfig.secondaryColor}
                isOutlined
                outlineColor={appConfig.secondaryColor || appConfig.mainColor}
                textColor={appConfig.secondaryColor || appConfig.mainColor}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminEigenkompostiererItemById({
                        id: itemId,
                        status: "inWork",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Akzeptieren"
                width="200"
                backgroundColor={appConfig.mainColor}
                marginRight="20"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminEigenkompostiererItemById({
                        id: itemId,
                        status: "accepted",
                      })
                    );
                  }
                }}
              />
              <Button
                text="Stornieren"
                width="200"
                backgroundColor="white"
                isOutlined
                outlineColor={"red"}
                textColor="red"
                onClick={() => {
                  if (itemId) {
                    dispatch(
                      updateAdminEigenkompostiererItemById({
                        id: itemId,
                        status: "declined",
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminEigenkompostiererInfoById;
