import React, { FC } from "react";
import style from "./style.module.scss";
import AlertSettings from "./components/AlertSettings";
import Holidays from "./components/Hollidays";
import InfoText from "./components/InfoText";

interface IContentComponent {
  tabIndex: number;
}
const ContentComponent: FC<IContentComponent> = ({ tabIndex }) => {
  const generateContent = (idx: number) => {
    switch (idx) {
      case 0: {
        return <AlertSettings />;
      }
      case 1: {
        return <Holidays />;
      }
      case 2: {
        return <InfoText />
      }
      default: {
        return <></>;
      }
    }
  };

  return <div className={style.wrapper}>{generateContent(tabIndex)}</div>;
};

export default ContentComponent;
