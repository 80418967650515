import React, { MouseEvent } from "react";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { useAppDispatch } from "../../../../store";
import { setIsConfirmationPopupActive } from "../../../../store/slices/app";
import BlockLisForm from "./components/BlockLisForm";
import style from "./style.module.scss";

const Modal = () => {
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(setIsConfirmationPopupActive(false));
  };

  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <div className={style.headerWrapper}>
        <div className={style.title}>Straße zur Sperrliste hinzufügen</div>
        <img
          src={closeIcon}
          alt="close"
          onClick={closeModalHandler}
          className={style.img}
        />
      </div>
      <div>
        <BlockLisForm closeModalHandler={closeModalHandler} />
      </div>
    </div>
  );
};

export default Modal;
