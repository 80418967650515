import { Form, Formik } from "formik";
import React, { MouseEvent } from "react";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import { Button, InputWithSelect } from "../../../../components";
import { useAppDispatch } from "../../../../store";
import { setIsConfirmationPopupActive } from "../../../../store/slices/app";
import { createSchadstoffmobilPlacesData } from "../../../../store/slices/schadstoffmobil";
import appConfig from "../../../../util/appConfig";
import style from "./style.module.scss";

const mockedCoorinates = [
  { city: "Abtweiler", lat: "49.7833", lon: "7.66764" },
  { city: "Allenfeld", lat: "49.8681", lon: "7.66764" },
  { city: "Altenbamberg", lat: "49.7833", lon: "7.66764" },
];

const Modal = () => {
  const dispatch = useAppDispatch();
  const initialValues = {
    city: "",
    lat: "",
    lon: "",
    place: "",
    time: "",
    tour: "",
  };

  const closeModalHandler = () => {
    dispatch(setIsConfirmationPopupActive(false));
  };

  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLInputElement>) => e.stopPropagation()}
    >
      <div className={style.headerWrapper}>
        <div className={style.title}>API Key hinzufügen</div>
        <img
          src={closeIcon}
          alt="close"
          onClick={closeModalHandler}
          className={style.img}
        />
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const data = mockedCoorinates.find((i) => i.city === values.city);
          dispatch(
            createSchadstoffmobilPlacesData({
              city: values.city,
              lat: data?.lat!,
              lon: data?.lon!,
              place: values.place,
              time: values.time,
              tour: values.tour,
            })
          );
          resetForm();
        }}
      >
        {(props) => {
          return (
            <Form>
              <InputWithSelect
                hasFullWidth={true}
                label="Ort"
                name="city"
                type="text"
                placeholder="Bitte wählen"
                options={[
                  { label: "Abtweiler", value: "Abtweiler" },
                  { label: "Allenfeld", value: "Allenfeld" },
                  { label: "Altenbamberg", value: "Altenbamberg" },
                ]}
              />
              <InputWithSelect
                hasFullWidth={true}
                label="Standort"
                name="place"
                type="text"
                placeholder="Bitte wählen"
                options={[
                  { label: "Am Gemeindenhaus", value: "Am Gemeindenhaus" },
                  {
                    label: "Parkplatz Windhof Brücke",
                    value: "Parkplatz Windhof Brücke",
                  },
                ]}
              />
              <InputWithSelect
                hasFullWidth={true}
                label="Tour"
                name="tour"
                type="text"
                placeholder="Bitte wählen"
                options={[
                  { label: "9", value: "9" },
                  { label: "12", value: "12" },
                  { label: "16", value: "16" },
                ]}
              />
              <InputWithSelect
                hasFullWidth={true}
                label="Uhrzerit"
                name="time"
                type="text"
                placeholder="Bitte wählen"
                options={[
                  { label: "11:30 - 12.00", value: "11:30 - 12.00" },
                  { label: "13:00 - 13.30", value: "13:00 - 13.30" },
                  { label: "14:00 - 14.30", value: "14:00 - 14.30" },
                ]}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  text="Schließen"
                  width="298"
                  onClick={closeModalHandler}
                  isOutlined={true}
                  outlineColor={appConfig.mainColor}
                  textColor={appConfig.mainColor}
                />
                <Button
                  width="298"
                  text="Speichern"
                  backgroundColor={
                    appConfig.mainColor
                    // props.values.name.length
                    // ? appConfig.mainColor
                    // : transformHexToRgba(appConfig.mainColor!, "0.4")
                  }
                  type="submit"
                  disabled={false}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Modal;
