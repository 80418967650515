import { useField } from "formik";
import React, { FC } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import style from "./style.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { de } from "date-fns/locale";
import validationError from "../../assets/images/validationError.svg";

registerLocale("de", de);

interface ICustomDatePicker {
  name: string;
  type: string;
  label: string;
  placeholder?: string;
  hasFullWidth?: boolean;
  marginBottom?: string;
  width?: string;
  onClick?: (data: any) => void;
  withPreselectedDate?: boolean;
  withDateRange?: boolean;
}

export const CustomDatePicker: FC<ICustomDatePicker> = ({
  label,
  name,
  type,
  hasFullWidth,
  marginBottom,
  placeholder,
  width,
  onClick,
  withPreselectedDate = true,
  withDateRange = false,
}) => {
  const [field, meta, helpers] = useField({
    name,
    type,
  });
  return (
    <div
      className={`${style.input_wrapper} ${
        hasFullWidth && style.fullWidthInput
      } ${meta.touched && meta.error && style.input_wrapperWithWarning}`}
      style={{
        marginBottom: marginBottom ? `${marginBottom}px` : "",
        width: width?.includes("%")
          ? `${width}`
          : width && !width?.includes("%")
          ? `${width}px`
          : "",
      }}
    >
      <label
        className={`${style.label} ${
          meta.touched && meta.error ? style.labelWithWarning : ""
        }`}
      >
        {label}
      </label>
      <DatePicker
        // showIcon={true}
        selected={
          field.value
            ? new Date(field.value)
            : withPreselectedDate
            ? new Date()
            : null
        }
        onChange={(date) => {
          if (date) {
            if (onClick) {
              onClick(
                withDateRange ? date : new Date(date as Date).toISOString()
              );
            }
            helpers.setValue(new Date(date as Date).toISOString());
          }
        }}
        selectsRange={withDateRange}
        className={`${style.customInput} ${
          meta.touched && meta.error ? style.customInputWithWarning : ""
        }`}
        locale={"de"}
        dateFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        
      />
      {meta.touched && meta.error && !field.value.length && (
        <div className={style.errorMessage}>{meta.error}</div>
      )}
      {meta.touched && meta.error && !field.value.length && (
        <img
          src={validationError}
          alt="err"
          className={`${style.warningImg} ${style.warningImgWithSelect}`}
        />
      )}
    </div>
  );
};
