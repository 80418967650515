import React, { FC, MouseEvent } from "react";
import { useAppDispatch } from "../../store";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import style from "./style.module.scss";
import { Button } from "..";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import closeIcon from "../../assets/images/closeIcon.svg";

interface IDeleteConfirmationModal {
  removeHandler: () => void;
  title?: string;
  subTitle?: string;
}

export const DeleteConfirmationModal: FC<IDeleteConfirmationModal> = ({
  removeHandler,
  title,
  subTitle,
}) => {
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(setIsConfirmationPopupActive(false));
  };

  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <img
        className={style.closeIcon}
        src={closeIcon}
        alt="close"
        onClick={closeModalHandler}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div className={style.imgWrapper}>
          <img className={style.img} src={deleteIcon} alt="delete" />
        </div>
      </div>
      <div className={style.headerWrapper}>
        <div className={style.title}>{title ? title : "Delete token(s)"}</div>
      </div>
      <div>
        <div className={style.confirmationText}>
          {subTitle
            ? subTitle
            : "Are you sure, that you want to delete item(s)?"}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            backgroundColor={"white"}
            text={"No"}
            width={"240"}
            onClick={closeModalHandler}
            textColor="#8F9EA9"
            isOutlined={true}
            outlineColor="#8F9EA9"
          />
          <Button
            backgroundColor={"#de3745"}
            text={"Delete"}
            width={"240"}
            onClick={removeHandler}
          />
        </div>
      </div>
    </div>
  );
};
