import React, { FC } from "react";
import addIcon from "../../../../assets/images/addIcon.svg";
import removeIcon from "../../../../assets/images/removeIcon.svg";
import removeIconInactive from "../../../../assets/images/removeIconInactive.svg";
import { useAppDispatch } from "../../../../store";
import { setIsConfirmationPopupActive } from "../../../../store/slices/app";
import style from "./style.module.scss";

interface IButtonGroup {
  areItemsSelected: boolean;
  removeItems?: () => void;
  btnGroupAddItemText: string;
  btnGroupRemoveItemsText: string;
  createBtnHandlerFunc?: () => void;
}

const ButtonGroup: FC<IButtonGroup> = ({
  areItemsSelected,
  removeItems,
  btnGroupAddItemText,
  btnGroupRemoveItemsText,
  createBtnHandlerFunc
}) => {
  const dispatch = useAppDispatch();

  const openModalHandler = () => {
    if(createBtnHandlerFunc) {
      createBtnHandlerFunc()
      return
    }
    dispatch(setIsConfirmationPopupActive(true));
  };

  const removeItemsHandler = () => {
    if (removeItems) {
      removeItems();
    }
  };

  return (
    <div className={style.btnWrapper}>
      <button className={style.addApiKeyBtn} onClick={openModalHandler}>
        <img src={addIcon} alt="addIcon" className={style.icon} />{" "}
        {btnGroupAddItemText}
      </button>
      <button
        onClick={removeItemsHandler}
        className={
          areItemsSelected ? style.removeBtnActive : style.removeBtnInactive
        }
      >
        <img
          src={areItemsSelected ? removeIcon : removeIconInactive}
          alt="addIcon"
          className={style.icon}
        />{" "}
        {btnGroupRemoveItemsText}
      </button>
    </div>
  );
};

export default ButtonGroup;
