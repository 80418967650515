import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "../../api/api";
import {
  ICalendarData,
  ICalendarDataInfo,
} from "../../api/calendar/calendar.api";
import { setAdminLoader } from "./admin";
import { setIsLoading, setIsToasterError } from "./app";
export interface ICalendarState {
  client: {
    hasInitialData: boolean;
    calendarData: {
      [key: string]: {
        [key: string]: string[];
      };
    };
    streets: {
      strasseBezeichnung: string;
      strasseNummer: number;
      blockedHomeNumbers?: { from: string; to: string }[];
    }[];
    selectedStreet: string;
    selectedStreetNum: number | null;
    selectedHomeNumber?: string;
  };
  admin: {
    calendarData: ICalendarData[];
    calendarDataItem: ICalendarData | null;
    isCalendarDataLoading: boolean;
  };
}

const initialState: ICalendarState = {
  client: {
    hasInitialData: false,
    calendarData: {},
    streets: [],
    selectedStreet: "",
    selectedStreetNum: null,
    selectedHomeNumber: ""
  },
  admin: {
    calendarData: [],
    calendarDataItem: null,
    isCalendarDataLoading: false,
  },
};

export const getCalendarStreets = createAsyncThunk(
  "calendar/client/setStreets",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const streets = await API.calendar.get.getStreets();
      thunkAPI.dispatch(setIsLoading(false));
      return streets;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);
export const getTownAreaStreets = createAsyncThunk(
  "calendar/client/getTownAreaStreets",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const streets = await API.calendar.get.getTownAreaStreets();
      thunkAPI.dispatch(setIsLoading(false));
      return streets;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);

export const getCalendarData = createAsyncThunk(
  "calendar/client/setData",
  async (
    obj: {
      date: string;
      streetNum: number;
      isTreeMonthRange?: boolean;
      isYear?: boolean;
      homeNumber?: string;
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const data = await API.calendar.get.calendarData(
        obj.date,
        obj.streetNum,
        obj.isTreeMonthRange,
        obj.isYear,
        obj.homeNumber,
      );
      thunkAPI.dispatch(setIsLoading(false));
      return data;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);

export const getAdminCalendarData = createAsyncThunk(
  "calendar/admin/getCalendarData",
  async (page: number, thunkApi) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));
      const calendarData = await API.calendar.get.getCalendarData(page);
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      return calendarData;
    } catch (error) {
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);
export const updateCalendarDataById = createAsyncThunk(
  "calendar/admin/updateCalendarDataById",
  async (obj: { id: string; data: ICalendarDataInfo }, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      const calendarData = await API.calendar.put.updateCalendarDataById(
        obj.id,
        obj.data
      );
      thunkApi.dispatch(setAdminLoader(false));
      return calendarData;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);
export const getCalendarDataById = createAsyncThunk(
  "calendar/admin/getCalendarDataById",
  async (id: string, thunkApi) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));
      const calendarData = await API.calendar.get.getCalendarDataById(id);
      thunkApi.dispatch(setAdminLoader(false));
      // thunkApi.dispatch(setDataLoading(false));

      return calendarData;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setStreet: (state, action: PayloadAction<string>) => {
      state.client.selectedStreet = action.payload;
    },
    setSelectedStreetNumber: (state, action: PayloadAction<number>) => {
      state.client.selectedStreetNum = action.payload;
    },
    clearCalendarData: (state) => {
      state.admin.calendarData = [];
    },
    clearCalendarDataItem: (state) => {
      state.admin.calendarDataItem = null;
    },
    setHomeNumber: (state, action: PayloadAction<string | undefined>) => {
      state.client.selectedHomeNumber = action.payload;
    },
    resetCalendarData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendarData.pending, (state, action) => {
      state.client.calendarData = {};
    });
    builder.addCase(getCalendarData.fulfilled, (state, action) => {
      state.client.hasInitialData = true;
      state.client.calendarData = { ...action.payload };
    });
    // builder.addCase(getCalendarStreets.fulfilled, (state, action) => {
    //   state.streets = action.payload?.streets || [];
    // });
    builder.addCase(getTownAreaStreets.fulfilled, (state, action) => {
      if (action.payload?.length) {
        state.client.streets = [...action.payload];
      }
    });
    builder.addCase(getAdminCalendarData.pending, (state) => {
      state.admin.isCalendarDataLoading = true;
    });
    builder.addCase(getAdminCalendarData.fulfilled, (state, action) => {
      if (action.payload && action.payload.length) {
        state.admin.calendarData = [
          ...state.admin.calendarData,
          ...action.payload,
        ];
      }
      state.admin.isCalendarDataLoading = false;
    });
    builder.addCase(getAdminCalendarData.rejected, (state) => {
      state.admin.isCalendarDataLoading = false;
    });
    builder.addCase(updateCalendarDataById.fulfilled, (state, action) => {
      state.admin.calendarData = [
        ...state.admin.calendarData.map((i) => {
          if (action.payload && i.id === action.payload.id) {
            return action.payload;
          }
          return i;
        }),
      ];
    });
    builder.addCase(getCalendarDataById.pending, (state) => {
      state.admin.isCalendarDataLoading = true;
    });
    builder.addCase(getCalendarDataById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.calendarDataItem = action.payload;
      }
      state.admin.isCalendarDataLoading = false;
    });
    builder.addCase(getCalendarDataById.rejected, (state) => {
      state.admin.isCalendarDataLoading = false;
    });
  },
});

export const {
  setStreet,
  resetCalendarData,
  setSelectedStreetNumber,
  clearCalendarData,
  clearCalendarDataItem,
  setHomeNumber,
} = calendarSlice.actions;

export const calendarReducer = calendarSlice.reducer;
