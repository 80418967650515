import React, { FC } from "react";
import { useField } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import style from "./style.module.scss";
import validationError from "../../assets/images/validationError.svg";

interface IPhoneInput {
  name: string;
  type: string;
  label: string;
  isDisabled?: boolean;
}

export const CustomPhoneInput: FC<IPhoneInput> = ({
  name,
  type,
  label,
  isDisabled = false,
}: IPhoneInput) => {
  const [field, meta, helpers] = useField({
    name,
    type,
  });

  return (
    <div
      className={`${style.input_wrapper}
        ${meta.touched && meta.error ? style.input_wrapperWithWarning : ''}
        `}
      style={{
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <label
        className={`${style.label} ${
          meta.touched && meta.error && !isDisabled && style.labelWithWarning
        }`}
      >
        {label}
      </label>
      <PhoneInput
        country={"de"}
        value={!field.value ? "" : field.value}
        disabled={isDisabled}
        onChange={(value) => {
          if (isDisabled) {
            return;
          }
          helpers.setValue(value);
        }}
        inputClass={`${style.phoneInput_wrapper}  ${
          isDisabled ? style.disabledCustomInput : ""
        } ${
          meta.touched &&
          meta.error &&
          !isDisabled &&
          style.customInputWithWarning
        }`}
        containerClass={style.customContainer}
        buttonClass={`${style.customBtn} ${
          meta.touched && meta.error && style.customBtnWithError
        }`}
        dropdownClass={style.dropdown}
        onBlur={field.onBlur}
        inputProps={{
          name: name,
          type: type,
        }}
      />
      {meta.touched && meta.error && !isDisabled && (
        <div className={style.errorMessage}>{meta.error}</div>
      )}
      {meta.touched && meta.error && !isDisabled && (
        <img
          src={validationError}
          alt="err"
          className={`${style.warningImg}`}
        />
      )}
    </div>
  );
};
