import React, { FC, MouseEvent } from "react";
import CreateArticleForm from "./components/CreateArticle";
import closeIcon from "../../../../assets/images/closeIcon.svg";
import style from "./style.module.scss";
import { IArticleBody } from "../../../../api/news/news.api";
import UpdateArticleForm from "./components/UpdateArticle";

interface IModal {
  closeModalHandler: () => void;
  article: IArticleBody | null;
}

const Modal: FC<IModal> = ({ closeModalHandler, article }) => {
  return (
    <div
      className={style.wrapper}
      onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <div className={style.headerWrapper}>
        <div className={style.title}>Create article</div>
        <img
          src={closeIcon}
          alt="close"
          onClick={closeModalHandler}
          className={style.img}
        />
      </div>
      {article ? (
        <UpdateArticleForm
          closeModalHandler={closeModalHandler}
          article={article}
        />
      ) : (
        <CreateArticleForm closeModalHandler={closeModalHandler} />
      )}
    </div>
  );
};

export default Modal;
