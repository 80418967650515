import { adminInstance, instance } from "../api";

export interface ISchadstoffmobil {
  time: string;
  city: string;
  place: string;
  lat: string;
  lon: string;
  _id: string;
}

export interface ISchadstoffmobilItem {
  city: string;
  place: string;
  time: string;
  tour: string;
  lat: string;
  lon: string;
}

export interface ISchadstoffmobilData extends ISchadstoffmobilItem {
  _id: string;
}

const getSchadstoffmobilData = () => {
  return instance
    .get<ISchadstoffmobil[]>("/schadstoffmobil/places")
    .then((res) => res.data);
};

const getAdminSchadstoffmobilData = () => {
  return adminInstance
    .get<ISchadstoffmobilData[]>("/schadstoffmobil/places")
    .then((res) => res.data);
};

const createSchadstoffmobilPlacesData = (data: ISchadstoffmobilItem) => {
  return adminInstance
    .post<ISchadstoffmobilData>("/schadstoffmobil/places", data)
    .then((res) => res.data);
};

const deleteSchadstoffmobilPlacesDataByID = (ids: string[]) => {
  return adminInstance
    .delete(`/schadstoffmobil/places?ids=${ids}`)
    .then((res) => res.data);
};

const Schadstoffmobil = {
  get: {
    getSchadstoffmobilData,
    getAdminSchadstoffmobilData
  },
  post: {
    createSchadstoffmobilPlacesData
  },
  delete: {
    deleteSchadstoffmobilPlacesDataByID
  }
};

export default Schadstoffmobil;
