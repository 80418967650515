import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IArticleBody } from "../../api/news/news.api";
import {
  AdminContentWrapper,
  AdminHeader,
  AdminPlaceholder,
  DeleteConfirmationModal,
  Loader,
  ModalWrapper,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { setIsConfirmationPopupActive } from "../../store/slices/app";
import { deleteArticles, getArticles } from "../../store/slices/news";
import ContentComponent from "./components/ContentComponent";
import Modal from "./components/Modal";

const AdminArticles = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdminLoaderActive = useAppSelector(
    (state) => state.admin.isAdminLoaderActive
  );
  const isDataLoading = useAppSelector((state) => state.admin.isDataLoading);
  const isModalActive = useAppSelector(
    (state) => state.app.client.isConfirmationPopupActive
  );
  const articles = useAppSelector((state) => state.news.admin.articles);

  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<null | IArticleBody>(
    null
  );
  const [isDeleteConfirmationModalActive, setIsDeleteConfirmationModalActive] =
    useState(false);

  const selectItemForUpdateHandler = (item: IArticleBody) => {
    setSelectedArticle(item);
    dispatch(setIsConfirmationPopupActive(true));
  };

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const openDeleteConfirmationModalHandler = () => {
    if (selectedItems.length) {
      dispatch(setIsConfirmationPopupActive(true));
      setIsDeleteConfirmationModalActive(true);
    }
  };

  const clearSelectedItems = () => {
    if (selectedItems.length) {
      dispatch(deleteArticles(selectedItems));
      dispatch(setIsConfirmationPopupActive(false));
      setSelectedItems([]);
      setIsDeleteConfirmationModalActive(false);
    }
  };

  useEffect(() => {
    dispatch(getArticles(page));
    setPage((prev) => prev + 1);
  }, []);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminHeader
        clearSelectedItems={openDeleteConfirmationModalHandler}
        hasItemsLength={!!articles.length}
        hasSelectedItems={!!selectedItems.length}
        pageTitle={"Articles"}
        hasBtnGroup={true}
        btnGroupAddItemText={"Add article"}
        btnGroupRemoveItemsText={"Remove articles"}
        createBtnHandlerFunc={() => {
          navigate("/admin/articles/create");
        }}
      />
      <AdminContentWrapper>
        {isDataLoading ? null : !articles.length ? (
          <AdminPlaceholder
            title="No Articles tet Yet"
            subTitle="Start working with the admin panel now. You can add a article by clicking
          the button below."
            hasBtn={true}
            btnText={"Add article"}
            btnHandler={() => {
              navigate("/admin/articles/create");
            }}
          />
        ) : (
          <ContentComponent
            articles={articles}
            selectItemHandler={selectItemHandler}
            selectedItems={selectedItems}
            selectItemForUpdating={selectItemForUpdateHandler}
          />
        )}
      </AdminContentWrapper>
      {isModalActive ? (
        <ModalWrapper
          modalCloseHandler={() => {
            dispatch(setIsConfirmationPopupActive(false));
            setSelectedArticle(null);
            setIsDeleteConfirmationModalActive(false);
          }}
        >
          {isDeleteConfirmationModalActive ? (
            <DeleteConfirmationModal removeHandler={clearSelectedItems} />
          ) : (
            <Modal
              closeModalHandler={() => {
                dispatch(setIsConfirmationPopupActive(false));
                setSelectedArticle(null);
              }}
              article={selectedArticle}
            />
          )}
        </ModalWrapper>
      ) : null}
      {isAdminLoaderActive ? <Loader /> : null}
    </div>
  );
};

export default AdminArticles;
