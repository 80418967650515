import React, { FC } from "react";
import { IArticleBody } from "../../../../api/news/news.api";
import HeaderComponent from "./components/HeaderComponent";
import Item from "./components/Item";

interface IContentComponent {
  articles: IArticleBody[];
  selectItemHandler: (id: string) => void;
  selectedItems: string[];
  selectItemForUpdating: (item: IArticleBody) => void;
}

const ContentComponent: FC<IContentComponent> = ({
  articles,
  selectItemHandler,
  selectedItems,
  selectItemForUpdating
}) => {
  return (
    <div>
      <HeaderComponent />
      {articles.map((i) => {
        return (
          <Item
            key={i._id}
            item={i}
            checked={selectedItems.includes(i._id)}
            changeHandler={selectItemHandler}
            selectItemForUpdating={selectItemForUpdating}
          />
        );
      })}
    </div>
  );
};

export default ContentComponent;
