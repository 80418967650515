import React, { FC } from "react";
import style from "./style.module.scss";

interface IStatusBar {
  status: boolean;
  setFieldValue: (type: string, value: any) => void;
}

const StatusBar: FC<IStatusBar> = ({ status, setFieldValue }) => {
  return (
    <div className={style.status}>
      <label className={style.label}>Warnmeldung auf Startseite anzeigen?</label>
      <div className={style.statusWrapper}>
        <div
          className={status ? style.activeTab : style.tabText}
          onClick={() => {
            setFieldValue("status", true);
          }}
        >
          Ja, anzeigen
        </div>
        <div
          className={!status ? style.inactiveTab : style.tabText}
          onClick={() => {
            setFieldValue("status", false);
          }}
        >
          Nein, nicht anzeigen
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
