import { Form, Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  Button,
  CustomInput,
  RichTextEditor,
  TitleComponent,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { createArticle, updateArticle } from "../../store/slices/news";
import appConfig from "../../util/appConfig";

const AdminCreateUpdateArticle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const article = useAppSelector((state) => state.news.admin.articles).find(
    (i) => i._id === id
  );
  const initialValues = {
    title: article?.title ?? "",
    articleText: article?.text ?? "",
    imgSrc: article?.imgSrc ?? "",
    shortDescription: article?.shortDescription ?? "",
  };
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <TitleComponent title={id ? "Update article" : "Create article"} />
      <AdminContentWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            id
              ? dispatch(
                  updateArticle({
                    id: id,
                    body: {
                      imgSrc: values.imgSrc,
                      shortDescription: values.shortDescription,
                      title: values.title,
                      text: values.articleText,
                    },
                  })
                )
              : dispatch(
                  createArticle({
                    imgSrc: values.imgSrc,
                    shortDescription: values.shortDescription,
                    title: values.title,
                    text: values.articleText,
                  })
                );
            resetForm();
            navigate("/admin/articles");
          }}
        >
          {(props) => {
            return (
              <Form>
                <div>
                  <CustomInput
                    name={"title"}
                    type={"text"}
                    label={"Titel"}
                    hasFullWidth={true}
                  />
                  <CustomInput
                    name={"imgSrc"}
                    type={"text"}
                    label={"Image link"}
                    hasFullWidth={true}
                  />
                  <CustomInput
                    name={"shortDescription"}
                    type={"text"}
                    label={"Short description"}
                    hasFullWidth={true}
                  />
                  <RichTextEditor
                    name={"articleText"}
                    type={"text"}
                    label={"Article text"}
                  />
                </div>
                <Button
                  backgroundColor={appConfig.mainColor}
                  text="Submit"
                  width="250"
                  type="submit"
                  btnStyle={{
                    position: "absolute",
                    top: "0",
                    right: "0px",
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </AdminContentWrapper>
    </div>
  );
};

export default AdminCreateUpdateArticle;
