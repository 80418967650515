import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import { Form, Formik } from "formik";
import { updateSperrmuellScheduleSettings } from "../../../../../../store/slices/app";
import {
  AdminDetailsPageItemName,
  Button,
  CustomInput,
} from "../../../../../../components";
import style from "./style.module.scss";
import getCurrentCalendarMonthDaysToShow from "../../../../../../util/getCurrentCalendarMonthDaysToShow";
import { IDisplayedMonth } from "../../../../../Calendar/components/Calendar/components/CalendarComponent";
import StatusBar from "./components/StatusBar";

const AlertSettings = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleStatus
  );
  const title = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleTitle
  );
  const subtitle = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleSubtitle
  );
  const url = useAppSelector(
    (state) => state.app.client.newSperrmuellScheduleURL
  );
  const bccEMailAddress = useAppSelector(
    (state) => state.app.admin.bccEMailAddress
  );
  const holidays = useAppSelector((state) => state.app.client.holidays);

  const [date, setDate] = useState(new Date().toDateString());

  // const nextMonthHandler = () => {
  //   setDate(new Date(addMonths(new Date(date), 1)).toDateString());
  // };
  // const prevMonthHandler = () => {
  //   setDate(new Date(addMonths(new Date(date), -1)).toDateString());
  // };

  let calendarData: IDisplayedMonth[][] = [];

  for (let i = 0; i < getCurrentCalendarMonthDaysToShow(date).length / 7; i++) {
    calendarData.push([
      ...getCurrentCalendarMonthDaysToShow(date).slice(i * 7, (i + 1) * 7),
    ]);
  }

  const initialValues = {
    status,
    title,
    subtitle,
    url,
    bccEMailAddress,
    holidays,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        dispatch(updateSperrmuellScheduleSettings(values));
      }}
      enableReinitialize
    >
      {(props) => {
        return (
          <>
            <AdminDetailsPageItemName text="Warnmeldung - Portal Startseite" />
            <Form style={{ paddingBottom: "20px" }}>
              <StatusBar
                status={props.values.status}
                setFieldValue={props.setFieldValue}
              />
              <div className={style.inputsWrapper}>
                <CustomInput
                  label="Titel bzw. Überschrift"
                  name="title"
                  type="string"
                  width="49%"
                />
                <CustomInput
                  label="URL für eine Verlinkung der Warnmeldung"
                  name="url"
                  type="string"
                  width="49%"
                />
                <CustomInput
                  label="Hinweis oder Warninformation"
                  name="subtitle"
                  type="string"
                  width="49%"
                />
                <CustomInput
                  label="E-Mail Adresse"
                  name="bccEMailAddress"
                  type="string"
                  width="49%"
                />
              </div>
              {/* <Calendar
                date={date}
                holidays={props.values.holidays}
                nextMonthHandler={nextMonthHandler}
                prevMonthHandler={prevMonthHandler}
                setFieldValue={props.setFieldValue}
              /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  width="200"
                  textColor="#fff"
                  backgroundColor="#008BD2"
                  text="Speichern"
                  type="submit"
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AlertSettings;
